define("apollo/pods/task/model", ["exports", "ember-data", "apollo/mixins/model-validator", "ember-data/model"], function (_exports, _emberData, _modelValidator, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = _model.default.extend(_modelValidator.default, {
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    languageService: Ember.inject.service('language-service'),
    workflow: belongsTo('workflow', {
      async: true,
      inverse: 'tasks'
    }),
    enabled: attr('boolean'),
    alias: attr('string'),
    groupAlias: attr('string'),
    canBeConfirmedByGuard: attr('boolean'),
    taskGroupIdentifier: attr('number'),
    idxInGroup: attr('number'),
    tracked: attr('boolean'),
    markIfWasDelayed: attr('boolean'),
    stepType: attr('string'),
    checkpointName: attr('string'),
    checkpointNameEn: attr('string'),
    stageName: attr('string'),
    stageNameEn: attr('string'),
    altCheckpointName: attr('string'),
    altCheckpointNameEn: attr('string'),
    color: attr('string'),
    altCheckpointColor: attr('string'),
    canBeRevoked: attr('boolean'),
    canBeRejected: attr('boolean'),
    confirmCheckpointAfterCarrierSelect: attr('boolean'),
    confirmCheckpointAfterCourierPickup: attr('boolean'),
    confirmCheckpointAfterCourierDelivered: attr('boolean'),
    moveTransportToArchiveIfDelayed: attr('boolean'),
    shouldBeApprovedWithAdvice: attr('boolean'),
    shouldBeApprovedWithCFTypeEmailAdvice: attr('boolean'),
    shouldBeApprovedWithTimeWindow: attr('boolean'),
    weighingIncluded: attr('boolean'),
    artrIncluded: attr('boolean'),
    shouldBeSavedAsApproved: attr('boolean'),
    canBeConfirmAfterAddingDocumentsForTransport: attr('boolean'),
    shouldCreateClassificationResource: attr('boolean'),
    canConfirmPrevious: attr('boolean'),
    expectedDateInterval: attr('string'),
    expectedDateReference: attr('string'),
    association: attr('string'),
    shouldGenerateDocument: attr('boolean'),
    sendDocumentDelayedEmail: attr('boolean'),
    showPunctualityAlertAfterConfirmation: attr('boolean'),
    visibleInDeliveryDocument: attr('boolean'),
    sendApiMessage: attr('boolean'),
    repeatSendApiMessage: attr('boolean'),
    requiresWarehouseLocation: attr('boolean'),
    shouldFindFreeSpotAfterDelay: attr('boolean'),
    canBeBlocked: attr('boolean'),
    isDriverCheckInTask: attr('boolean'),
    customFieldOption: belongsTo('customFieldOption', {
      async: true
    }),
    stayAtTheFacility: attr('boolean'),
    canBeConfirmedWithSms: attr('boolean'),
    masterTask: belongsTo('task', {
      async: true,
      inverse: 'subTasks',
      deepEmbedded: false
    }),
    subTasks: hasMany('task', {
      async: true,
      inverse: 'masterTask'
    }),
    openCustomModal: attr('boolean'),
    recreateMasterTaskSubTasks: attr('boolean'),
    customModalComponentName: attr('string'),
    timeWindowCssClassName: attr('string'),
    shouldConfirmRelatedCheckpoint: attr('boolean'),
    shouldBeConfirmedByRelatedCheckpoint: attr('boolean'),
    shouldSendLpToSapMessages: attr('boolean'),
    shouldSendLpToMSHMessages: attr('boolean'),
    userHasToAddCheckpointConfirmationDate: attr('boolean'),
    checkpointSortId: attr('number'),
    validations: {
      stepType: {
        custom: [{
          validation: function (key, value, model) {
            return !(model.get('taskGroupIdentifier') === 1 && !value);
          },
          message: 'blank'
        }]
      },
      checkpointName: {
        presence: {
          message: 'blank'
        }
      },
      checkpointNameEn: {
        presence: {
          message: 'blank'
        }
      },
      stageName: {
        presence: {
          message: 'blank'
        }
      },
      stageNameEn: {
        presence: {
          message: 'blank'
        }
      },
      association: {
        custom: [{
          validation: function (key, value, model) {
            if (!model.get('isInStepsGroup')) {
              return true;
            }

            return !!value;
          },
          message: 'blankSelect'
        }]
      },
      color: {
        custom: [{
          validation: function (key, value) {
            const style = new Option().style;
            style.color = value;
            return style.color !== '';
          },
          message: 'blank'
        }]
      },
      expectedDateReference: {
        presence: {
          message: 'blank'
        }
      }
    },
    DEFAULT_CUSTOM_MODAL_COMPONENT_NAME: Ember.computed('', function () {
      return 'default-modal';
    }).readOnly(),
    canBeBlockedByRole: Ember.computed('sessionAccount.currentUser.roleNames.length', function () {
      const hasRole = this.actionCanBePerformed('ROLE_CAN_BLOCK_TASK') && this.actionCanBePerformed('ROLE_CAN_BLOCK_TASK').length > 0;
      return this.get('canBeBlocked') && hasRole && !this.get('checkpoint.completed');
    }),
    canBeRevokedByRole: Ember.computed('sessionAccount.currentUser.roleNames.length', function () {
      return this.get('canBeRevoked') && this.actionCanBePerformed('ROLE_CAN_REVOKE_TASK');
    }),
    canBeRejectedByRole: Ember.computed('sessionAccount.currentUser.roleNames.length', function () {
      return this.get('canBeRejected') && this.actionCanBePerformed('ROLE_CAN_REJECT_TASK');
    }),
    canBeConfirmedByRole: Ember.computed('sessionAccount.currentUser.roleNames.length', function () {
      return this.actionCanBePerformed('ROLE_CAN_CONFIRM_TASK');
    }),
    canBeSeenByRole: Ember.computed('sessionAccount.currentUser.roleNames.length', function () {
      return this.actionCanBePerformed('ROLE_CAN_ACCESS_TASK');
    }),
    canAddConfirmationDateByRole: Ember.computed('sessionAccount.currentUser.roleNames.length', function () {
      return this.actionCanBePerformed('ROLE_CAN_ADD_CONFIRMATION_DATE_TASK').length > 0;
    }),
    actionCanBePerformed: function (actionType) {
      return this.findPositiveAuthorityRestrictionsForTask(actionType);
    },
    findPositiveAuthorityRestrictionsForTask: function (actionType) {
      const currentPricingPlan = this.get('sessionAccount.currentUser.company.pricingPlan');
      return this.get('sessionAccount.authorityRestrictions').filter(ar => {
        if (ar.get('task.id') !== this.get('id')) {
          return false;
        }

        if (ar.get('pricingPlan') && ar.get('pricingPlan') !== currentPricingPlan) {
          return false;
        }

        const authority = this.get('sessionAccount.currentUser.roles').find(a => a.get('name') === actionType);
        return authority && ar.get('authority.id') === authority.get('id');
      });
    },
    expectedDateIntervalSign: Ember.computed('expectedDateInterval', function () {
      const interval = this.get('expectedDateInterval');

      if (!interval) {
        return null;
      }

      return interval[0] === '-' ? 'before' : 'after';
    }),
    expectedDateIntervalUnit: Ember.computed('expectedDateInterval', function () {
      const interval = this.get('expectedDateInterval');

      if (!interval) {
        return null;
      }

      return interval[interval.length - 1];
    }),
    expectedDateIntervalModulo: Ember.computed('expectedDateInterval', function () {
      const interval = this.get('expectedDateInterval');

      if (!interval) {
        return null;
      }

      const unit = this.get('expectedDateIntervalUnit');
      return parseInt(interval.replace('-', '').replace(unit, ''));
    }),
    // eslint-disable-next-line ember/no-observers
    expectedDateIntervalUnitChanged: Ember.observer('expectedDateIntervalUnit', 'expectedDateIntervalSign', 'expectedDateIntervalModulo', function () {
      const unit = this.get('expectedDateIntervalUnit');
      const sign = this.get('expectedDateIntervalSign') === 'before' ? '-' : '';
      const number = this.get('expectedDateIntervalModulo');
      const newInterval = sign + number + unit;
      this.set('expectedDateInterval', newInterval);
    }),
    defaultStageName: Ember.computed('stageName', 'checkpointName', function () {
      return this.get('checkpointName') ? this.get('stageName') || this.get('intl').t('task.defaultStagePrefix') + ' ' + this.get('checkpointName') : this.get('intl').t('task.stageName');
    }),
    checkpointNameInAppropriateLanguage: Ember.computed('checkpointName', 'checkpointNameEn', function () {
      const propertyName = this.get('languageService').findProperLanguageVersion('checkpointName', 'checkpointNameEn');
      return this.get(propertyName);
    }),
    stageNameInAppropriateLanguage: Ember.computed('stageName', 'stageNameEn', function () {
      const propertyName = this.get('languageService').findProperLanguageVersion('stageName', 'stageNameEn');
      return this.get(propertyName);
    }),
    isLastWeighingTask: Ember.computed('weighingIncluded', 'workflow.sortedTasks', function () {
      const workflowId = this.get('workflow.id');
      const weighingTasks = this.get('store').peekAll('task').filter(task => {
        return task.get('weighingIncluded') && task.get('workflow.id') === workflowId;
      }).sortBy('taskGroupIdentifier', 'idxInGroup');
      return weighingTasks && weighingTasks.get('length') > 0 ? weighingTasks.get('lastObject.id') === this.get('id') : false;
    }),
    legendString: Ember.computed('stageNameInAppropriateLanguage', 'workflow.transportType.name', function () {
      return `${this.get('stageNameInAppropriateLanguage')} (${this.get('workflow.transportType.nameInAppropriateLanguage')})`;
    }),
    isInStepsGroup: Ember.computed('id', function () {
      return this.get('taskGroupIdentifier') === 1;
    })
  });

  _exports.default = _default;
});