define("apollo/pods/components/trucks/truck-type-helper-text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SHGrs0dz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"data-field-name\",\"truck-type-helper\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"truckType\",\"id\"]]],null,{\"statements\":[[0,\"        \"],[4,\"if\",[[23,[\"maxSegmentLdm\"]]],null,{\"statements\":[[0,\" \"],[1,[27,\"t\",[\"transport.maxSegmentLdm\"],null],false],[0,\" \"],[1,[21,\"maxSegmentLdm\"],false],[0,\" [ldm]\"]],\"parameters\":[]},null],[7,\"br\"],[9],[10],[0,\"\\n        \"],[4,\"if\",[[23,[\"maxSegmentWeight\"]]],null,{\"statements\":[[0,\" \"],[1,[27,\"t\",[\"transport.maxSegmentWeight\"],null],false],[1,[21,\"maxSegmentWeight\"],false],[0,\" [kg]\"]],\"parameters\":[]},null],[7,\"br\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[4,\"if\",[[23,[\"model\",\"id\"]]],null,{\"statements\":[[1,[21,\"truckHelperText\"],false]],\"parameters\":[]},null],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/trucks/truck-type-helper-text/template.hbs"
    }
  });

  _exports.default = _default;
});