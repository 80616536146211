define("apollo/translations/es", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "CARRIER": {
      "label": "Empresa transportista",
      "panel": {
        "label": "Tarjeta de empresa transportista"
      }
    },
    "CLIENT": {
      "panel": {
        "label": "Tarjeta de cliente"
      }
    },
    "COMPANY": {
      "panel": {
        "label": "Tarjeta de empresa"
      }
    },
    "OPERATOR": {
      "label": "Operador",
      "panel": {
        "label": "Tarjeta de operador"
      }
    },
    "SERVICE": {
      "panel": {
        "label": "Tarjeta de servicio"
      }
    },
    "SERVICEMAN": {
      "panel": {
        "label": "Tarjeta de encargado de mantenimiento"
      }
    },
    "SUPPLIER": {
      "label": "Proveedor",
      "panel": {
        "label": "Tarjeta de proveedor"
      }
    },
    "acción de sección": {
      "noCommentError": "Introduzca los motivos del rechazo.",
      "rejectionComment": "Indique el motivo por el cual'rechaza esta sección"
    },
    "address": {
      "addNewAddress": "Agregar una dirección nueva",
      "addressUnloadingTime": {
        "daysToUnload": "Días para descargar",
        "friday": "Viernes",
        "label": "Hora de descarga",
        "monday": "Lunes",
        "saturday": "Sábado",
        "sunday": "Domingo",
        "thursday": "Jueves",
        "tuesday": "Martes",
        "wednesday": "Miércoles"
      },
      "autoCompletePlaceholder": "Comience a escribir para buscar una ciudad, una calle, etc.",
      "chooseCompanyFirst": "Elija una empresa primero",
      "city": "Ciudad",
      "country": "País",
      "deleteAddress": "¿Está seguro de que desea eliminar esta dirección?",
      "errors": {},
      "label": "Dirección",
      "newAddress": "Dirección nueva",
      "postal": "Código postal",
      "province": "Provincia",
      "recipient": "Destinatario",
      "removeAddress": "Eliminar una dirección",
      "street": "Calle",
      "voivodeship": "Voivodato"
    },
    "administration": {
      "newPrincipal": {
        "aliasHint": "El alias debe ser una palabra (preferentemente, el nombre'del cliente) en minúscula (por ej., logintegra)",
        "baseData": "Datos básicos de la empresa",
        "companyAddress": "Sede central",
        "create": "Crear nueva empresa principal",
        "creation": "Creando nueva empresa principal",
        "successMsg": "La nueva empresa principal se guardó correctamente. Ahora puede iniciar sesión con la cuenta: {username}",
        "userData": "Datos del usuario (SUPER_ADMIN)"
      }
    },
    "advice": {
      "add": "Agregar un aviso de envío",
      "carRegistrationNumber.placeholder": "Por ej., GDA12345",
      "carRegistrationNumberLabel": {
        "TRUCK": "Número de registro de camión",
        "VEHICLE": "Número de registro de vehículo"
      },
      "carRegistrationNumberSimple": "Número de registro",
      "creator": "El creador de un aviso de envío",
      "dateCreated": "Creado el",
      "documentNumber": "Número de documento'del conductor",
      "documentNumber.placeholder": "por ej., 78646003",
      "documentType": {
        "ID_CARD": "Número de tarjeta de identificación",
        "PASSPORT": "Pasaporte"
      },
      "driverId": "Número de identificación'del conductor",
      "driverLanguage": "Idioma'del conductor",
      "driverMobilePhoneNumber": "Número de teléfono móvil",
      "driverMobilePhonePrefix": "Prefijo'de número de teléfono móvil del conductor",
      "driverName": "Nombre y apellido de un conductor",
      "driverName.placeholder": "Por ej., Jan Kowalski",
      "errors": {
        "carRegistrationNumber": {
          "regex": "El número de registro debe tener entre 4 y 50 caracteres, y solo puede contener letras, números y los caracteres . , ; / \\\\ & _"
        },
        "driverMobilePhoneNumber": {
          "regex": "El número de teléfono de México debe constar de diez dígitos"
        },
        "rodoAccepted": {
          "notSelected": "Los datos deben procesarse de manera legal"
        },
        "saveAsResource": {
          "info": "Para guardar el conductor en la lista de recursos, es necesario completar los campos obligatorios en la sección de avisos de envío."
        },
        "termsOfConditionsAccepted": {
          "notSelected": "Debe aceptar los términos y condiciones para hacer un aviso de envío"
        },
        "trailerRegistrationNumber": {
          "regex": "Un número de registro del tráiler/semitráiler debe tener entre 4 y 50 caracteres, y solo puede contener letras, números y los caracteres . , ; / \\\\ & _"
        }
      },
      "label": "Aviso de envío",
      "pasteFromExcel": "Pegar datos de avisos de envío",
      "rodo": "Logintegra sp.z.o.o. puede procesar legalmente los datos que se introducen en el formulario.",
      "saveAsResource": "Guardar los datos del conductor y los datos del vehículo en la lista de recursos",
      "termsOfConditionsAccepted": "Acepto el ",
      "trailerRegistrationNumber": "Número de registro de tráiler/semitráiler",
      "trailerRegistrationNumber.placeholder": "Por ej., GDA6789",
      "transport": "Transporte"
    },
    "afterRegistration": {
      "addWarehouseButton": "Agregar un depósito",
      "info": "Abra su propio depósito e invite a sus compañeros a planificar transportes y avisos de envío.",
      "invitationButton": "Lista de invitaciones",
      "label": "Le damos la bienvenida.",
      "title": "Está a punto de despedirse para siempre de las colas."
    },
    "algorithmSettings": {
      "deleteSetting": "¿Está seguro de que desea eliminar esta configuración?",
      "deletionSuccessMessage": "La configuración de algoritmo para {n} paletas se eliminó correctamente",
      "label": "Configuración del algoritmo para calcular el tamaño de la franja horaria",
      "loadTimeInMinutes": "Tiempo de carga en minutos",
      "numberOfPallets": "Cantidad de paletas (límite máximo)",
      "saveSuccessMessage": "La configuración de algoritmo se guardó correctamente."
    },
    "announcement": {
      "errors": {
        "companies": {
          "chooseAtLeastOneCompany": "Elija al menos una empresa"
        },
        "roleGroups": {
          "chooseAtLeastOneRoleGroup": "Elija al menos un grupo de roles"
        }
      }
    },
    "announcements": {
      "add": "Agregar un anuncio",
      "announcementInEnglish": "Anuncio en inglés",
      "companies": "Las empresas que verán el anuncio:",
      "content": "Contenido del mensaje",
      "contentEn": "Contenido del mensaje (EN)",
      "dateCreated": "Fecha de creación",
      "dateFrom": "Fecha inicial de caducidad",
      "dateTo": "Fecha final de caducidad",
      "delete": "¿Está seguro de que desea eliminar este anuncio?",
      "edit": "Editar un anuncio",
      "expirationDates": "Fechas de caducidad",
      "label": "Anuncios",
      "lastEditor": "Última edición hecha por",
      "lastUpdated": "Última edición",
      "manage": "Gestionar anuncios",
      "noActiveAnnouncements": "No hay anuncios activos ahora.",
      "noAnnouncements": "No hay anuncios",
      "range": "Usuarios para elegir",
      "ranges": {
        "COMPANY": "Según la empresa' de los usuarios",
        "ROLE_GROUP": "Según los roles' de los usuarios"
      },
      "roleGroups": "Los usuarios con roles adecuados que verán el anuncio:",
      "subject": "Asunto del mensaje",
      "subjectEn": "Asunto del mensaje (EN)",
      "type": "Anuncio administrativo"
    },
    "apiIntegrationLogs": {
      "event": {
        "CHECKPOINT_CONFIRMED": "Punto de control confirmado",
        "TRANSPORT_CREATED": "Creación de transporte por API pública",
        "TRANSPORT_UPDATED": "Actualización de transporte por API pública",
        "TRANSPORT_UPDATED_MANUAL": "Actualización de transporte"
      }
    },
    "appliesSettings": {
      "add": "Agregar configuración de postulaciones",
      "description": {
        "createAntiCorruptionSection": "Agregar sección anticorrupción a postulaciones nuevas",
        "showPatternToDownload": "Mostrar formulario para descargar",
        "showStatementToDownload": "Mostrar declaración de empresa principal firmada para descargar"
      },
      "label": "Configuración de postulaciones",
      "noSettings": "Aún no hay ninguna configuración de postulaciones"
    },
    "artrSettings": {
      "add": "Agregar configuración de ANPR",
      "fields": {
        "entryGateCheckpointAlias": "Alias de la tarea de entrada (barrera)",
        "entryWhiteListCheckpointAlias": "Alias de la tarea que debe enviar el número de registro a la lista blanca: entrada",
        "exitGateCheckpointAlias": "Alias de la tarea de salida (barrera)",
        "exitWhiteListCheckpointAlias": "Alias de la tarea que debe enviar el número de registro a la lista blanca: salida",
        "expirationTimeInHours": "Tiempo de caducidad de la lista blanca (horas)"
      },
      "label": "Configuración de ANPR",
      "noSettings": "Aún no hay ninguna configuración de ANPR"
    },
    "assortmentGroup": {
      "add": "Agregar grupo de surtidos",
      "adviceFromTime": "Aviso de envío desde",
      "adviceToTime": "Aviso de envío hasta",
      "confirmDeletion": "¿Está seguro de que desea eliminar el grupo de surtidos?",
      "confirmEdition": "Nota: Se han modificado las horas para avisos de envío de grupos de surtidos. ¿Está seguro de que desea continuar?",
      "errors": {
        "adviceFromTime": {
          "invalidAssortmentGroupAdviceFromTime": "El aviso de envío debe estar dentro de las horas de atención del depósito."
        },
        "adviceToTime": {
          "invalidAssortmentGroupAdviceToTime": "El aviso de envío debe estar dentro de las horas de atención del depósito."
        },
        "nameEn": {
          "minLength": "Esta entrada debe incluir al menos 3 caracteres"
        },
        "namePl": {
          "minLength": "Esta entrada debe incluir al menos 3 caracteres"
        }
      },
      "label": "Grupo de surtidos",
      "listLabel": "Grupos de surtidos",
      "transportTypes": "Tipos de transporte para los que debe estar disponible el grupo de surtidos"
    },
    "auction": {
      "AUCTION": {
        "actualFinishTime": "Hora de cierre real de la subasta",
        "buttonLabel": "+ Subasta",
        "creator": "Creador'de la subasta",
        "currency": "Moneda de la subasta",
        "dateCreated": "Subasta creada el",
        "endedWithoutOffers": "La subasta finalizó sin ofertas",
        "finish": "Finalizar subasta",
        "inProgress": "La subasta termina el",
        "initialFinishTime": "Hora de cierre prevista de la subasta",
        "label": "Subasta",
        "noPreviousAuctions": "No hay subastas anteriores",
        "participants": "Empresas que participan de la subasta",
        "plannedFinishTime": "La fecha de finalización de la subasta",
        "previousAuctions": "Subastas anteriores",
        "reject": {
          "alert": "¿Está seguro de que desea rechazar esta subasta?",
          "fail": {
            "CannotRejectOffersExist": "No puede rechazar esta subasta; su empresa ya hizo una oferta."
          },
          "label": "No me interesa",
          "success": "La subasta se rechazó correctamente"
        },
        "save": "Guardar subasta",
        "savingInProgress": "Guardando subasta...",
        "selectParticipants": "Seleccionar participantes de la subasta"
      },
      "INQUIRY": {
        "actualFinishTime": "Hora real de finalización",
        "buttonLabel": "Consulta",
        "creator": "Creador de la consulta",
        "currency": "Moneda",
        "dateCreated": "Creada el",
        "endedWithoutOffers": "La consulta finalizó sin ofertas",
        "finish": "Finalizar la consulta",
        "inProgress": "La fecha de caducidad de la consulta",
        "initialFinishTime": "Hora prevista de finalización",
        "label": "Consulta",
        "noPreviousAuctions": "No hay consultas anteriores",
        "participants": "Empresas que participan de la consulta",
        "plannedFinishTime": "La fecha de finalización de la consulta",
        "previousAuctions": "Consultas anteriores",
        "reject": {
          "alert": "¿Está seguro de que desea rechazar esta consulta?",
          "fail": {
            "CannotRejectOffersExist": "No puede rechazar esta consulta; su empresa ya hizo una oferta."
          },
          "label": "No me interesa",
          "success": "La consulta se rechazó correctamente"
        },
        "save": "Crear una consulta",
        "savingInProgress": "Guardando consulta...",
        "selectParticipants": "Seleccionar los participantes de la consulta"
      },
      "VALUATION": {
        "actualFinishTime": "Hora final de cierre para realizar ofertas",
        "buttonLabel": "+ Tasación",
        "creator": "Creador'de la tasación",
        "currency": "Moneda",
        "dateCreated": "Creada el",
        "endedWithoutOffers": "La tasación finalizó sin ofertas",
        "finish": "Finalizar tasación",
        "inProgress": "La tasación finaliza el",
        "initialFinishTime": "Hora prevista de cierre para realizar ofertas",
        "label": "Tasación",
        "noPreviousAuctions": "No hay tasaciones anteriores",
        "participants": "Empresas que participan del procedimiento para ofertar",
        "plannedFinishTime": "La fecha final para las ofertas",
        "previousAuctions": "Tasaciones anteriores",
        "reject": {
          "alert": "¿Está seguro de que desea rechazar esta tasación?",
          "fail": {
            "CannotRejectOffersExist": "No puede rechazar esta tasación; su empresa ya hizo una oferta."
          },
          "label": "No me interesa",
          "success": "La tasación se rechazó correctamente"
        },
        "save": "Guardar tasación",
        "savingInProgress": "Guardando tasación...",
        "selectParticipants": "Seleccionar los participantes del procedimiento para ofertar"
      },
      "bid": "Oferta",
      "bidsCount": "Ofertas - ",
      "blockedCarriers": "Empresas transportistas bloqueadas",
      "buttonLabel": "+ Subasta",
      "buyNow": "Aceptar",
      "buyNowConfirmation": "¿Está seguro de que desea aceptar el pedido {transportIdentifier} con la tarifa de {currencySymbol} {minPrice}?",
      "buyNowHeader": "Aceptar de inmediato la oferta y recibir el pedido con la tarifa mínima",
      "buyNowLabel": "La oferta mínima",
      "create": "Crear subasta",
      "createTitle": "Crear una subasta para el transporte {transportIdentifier}",
      "currentOffer": "Oferta actual",
      "details": "Historial",
      "errors": {
        "companies": {
          "empty": "Elija al menos una empresa"
        },
        "maxPrice": {
          "invalidNumber": "El precio máximo debe ser un número mayor que cero.",
          "maxPriceLowerThanMinPrice": "El precio máximo no puede ser inferior al precio mínimo."
        },
        "minPrice": {
          "invalidNumber": "El precio mínimo debe ser un número mayor o igual que cero."
        },
        "plannedFinishTime": {
          "isInPast": "La fecha de finalización debe ser en el futuro."
        }
      },
      "finishTimeChanged": "La hora de finalización era {date}, pero se cambió.",
      "label": "Subasta",
      "lastUpdated": "Última modificación",
      "maxPrice": "Precio máx.",
      "minPrice": "Precio mín.",
      "noOffers": "No hay ofertas válidas",
      "plannedFinishTimeAbbr": "plan.",
      "priceRange": "Intervalo de precios",
      "selectGroup": "Seleccionar el grupo ",
      "transport": "Transporte"
    },
    "auth": {
      "accountActivated": "La cuenta se ha activado",
      "accountExpired": "Su cuenta puede haberse suspendido debido a una inactividad prolongada. El enlace para activar la cuenta se ha enviado a la dirección de correo electrónico de la cuenta. Ante cualquier problema, escríbanos a {supportEmail} o llámenos al: {supportPhone}.",
      "accountLocked": "Debido a muchos intentos de inicio de sesión, se ha bloqueado la cuenta por {arg} minutos. Vuelva a intentar dentro de {arg} minutos o restablezca la contraseña.",
      "accountLockedByAdmin": "El administrador del sistema bloqueó su cuenta.",
      "activateAccount": "Activar cuenta",
      "alreadyRegistered": "¿Ya tiene una cuenta?",
      "changePassword": {
        "false": "Cambiar contraseña",
        "true": "No cambiar la contraseña"
      },
      "confirmPassword": "Confirmar contraseña",
      "description": "Plataforma de logística",
      "email": "Dirección de correo electrónico",
      "emailPlaceholder": "Introduzca su dirección de correo electrónico",
      "fail": "Error de inicio de sesión",
      "footer": "Preparado por",
      "impersonate": "Inicie sesión con la cuenta del usuario",
      "invalidLoginOrPassword": "Usuario o contraseña no válido.",
      "login": "Inicie sesión",
      "loginPlaceholder": "Introduzca su correo electrónico",
      "loginWithActiveDirectory": "Inicio de sesión con Active Directory",
      "logout": "Cerrar sesión",
      "logoutByConcurrentSession": "Se ha cerrado su sesión porque está usando esta cuenta en otro dispositivo.",
      "logoutByService": "Se ha cerrado su sesión debido a tareas administrativas, o usted lleva un período prolongado sin usar el sistema.",
      "newPassword": "Contraseña nueva",
      "noLoginAndPassword": "Introduzca su usuario y contraseña.",
      "notRegistered": "¿Aún no tiene una cuenta?",
      "oldPassword": "Contraseña anterior",
      "or": "O",
      "password": "Contraseña",
      "passwordChanged": "Se cambió la contraseña del usuario {username}",
      "passwordPlaceholder": "Introduzca su contraseña",
      "passwordRemindedMessage": "Si la dirección especificada {email} existe, se enviará un mensaje.",
      "remind": "Recordar",
      "remindEmail": "Introduzca el correo electrónico conectado a su cuenta, y le enviaremos un enlace para restablecer la contraseña:",
      "remindFail": "La operación no se pudo completar",
      "remindHeader": "Recordar contraseña",
      "remindLink": "¿Olvidó su contraseña?",
      "remindingInProgress": "Se está enviando el correo electrónico",
      "repeatNewPassword": "Repetir la contraseña nueva",
      "repeatPassword": "Repetir contraseña",
      "serverError": "El servidor no responde. Vuelva a intentar dentro de unos minutos o escríbanos a info@logintegra.com.",
      "signin": "Regístrese",
      "username": "Nombre de usuario"
    },
    "authorityGroup": {
      "ADVICE": "Aviso de envío",
      "ANNOUNCEMENT": "Anuncios",
      "API": "API",
      "APPLIES": "Postulaciones de empresas transportistas",
      "ASSORTMENT_GROUP": "Grupos de surtidos",
      "AUCTION": "Derivación de pedidos, subastas",
      "COMPANY": "Empresas",
      "COURIER": "Servicios de mensajería",
      "CUSTOM_FIELD": "Campos personalizados",
      "DELIVERY": "Planificación de entregas",
      "INDEX": "Índices",
      "MAIN_TRANSPORT_DATA": "Los campos principales del formulario de pedidos",
      "MODE_OF_TRANSPORTATION": "Verificación de modo de transporte",
      "NOTES_AND_DOCUMENTS": "Notas, documentos",
      "OTHERS": "Otros",
      "PACKAGE": "Paquetes",
      "PRICE_LIST": "Listas de precios",
      "PRODUCT": "Productos",
      "QUEUE": "Colas",
      "SETTINGS": "Configuración",
      "TASKS": "Puntos de control",
      "TIME_WINDOW": "Franjas horarias",
      "TRANSPORT": "Transporte, pedido",
      "USER": "Usuarios",
      "WAREHOUSE": "Depósitos",
      "WEIGHT": "Pesaje"
    },
    "blockades": {
      "invalidSection": "Sección rechazada o no válida",
      "noActiveBlockades": "No hay bloqueos activos"
    },
    "checkpoint": {
      "actualExecutionDate": "Fecha real de confirmación",
      "actualExecutionDateLabel": "Indique la fecha real de confirmación del estado",
      "blockLabel": "Bloquear",
      "blockText": "{name} bloqueó la capacidad de confirmar este estado",
      "blockTextShort": "Bloqueado por {name}",
      "confirmator": "Confirmador",
      "errors": {
        "blockadeReason": {
          "length": "La cantidad máxima de caracteres es 255"
        },
        "executionDate": {
          "isInPast": "La fecha indicada debe ser en el pasado y posterior a la fecha de confirmación del estado anterior."
        }
      },
      "executionDate": "Fecha de confirmación de estado",
      "reject": "Rechazar",
      "rejectConfirm": "¿Está seguro de que desea rechazar este estado? Si confirma el rechazo, se enviará al conductor de regreso y se enviarán notificaciones por correo electrónico al cliente y a la empresa transportista.",
      "rejectionDate": "Fecha de último rechazo: ",
      "unblockLabel": "Desbloquear"
    },
    "checkpoints": {
      "confirm": "Confirmar",
      "confirmAfterTime": "Estado confirmado con demora",
      "confirmBeforeTime": "Estado confirmado antes de tiempo",
      "confirmStatus": "Confirmar estado",
      "confirmedMessage": "conf. el {date}",
      "confirmedMessageBy": "por {person}",
      "empty": "Sin estados",
      "plan": "plan.",
      "processFinished": "Proceso finalizado",
      "revokeConfirm": "¿Está seguro de que desea revocar la confirmación de este estado?",
      "revokeManyConfirm": "Nota: Dado que no es el último estado confirmado, también revocaremos las confirmaciones de todos los estados posteriores. ¿Está seguro de que desea proceder?"
    },
    "classifications": {
      "successMessage": "El vehículo {registrationNumber} se ha asignado a la cola: {squareName} - {queueName}"
    },
    "cmr-setting": {
      "errors": {
        "senderInstructionText": {
          "maxLineCountIs8": "La cantidad máxima de líneas en este campo es 8"
        }
      }
    },
    "cmrSettings": {
      "add": "Agregar configuración de CMR",
      "fields": {
        "appendedDocsSpecificationCustomField": "Agregar valor de campo personalizado en el campo núm. 5 (Documentos adjuntos)",
        "appendedDocsSpecificationNumber": "Agregar número en el campo núm. 5 (Documentos adjuntos)",
        "appendedDocsText": "Texto constante en el campo núm. 5 (Documentos adjuntos)",
        "featuresAndNumbersCustomField": "Agregar valor de campo personalizado en el campo núm. 6 (Marcas y números)",
        "featuresAndNumbersText": "Texto constante en el campo núm. 6 (Marcas y números)",
        "packageTypeText": "Texto constante en el campo núm. 8 (Método de embalaje)",
        "quantityCustomField": "Agregar valor de campo personalizado en el campo núm. 7 (Cantidad de paquetes)",
        "quantityText": "Texto constante en el campo núm. 7 (Cantidad de paquetes)",
        "senderInstructionCustomField": "Wyświetl wartość pola niestandardowego w polu nr 13 (Instrukcja nadawcy)",
        "senderInstructionText": "Texto constante en el campo núm. 13 (Instrucciones'del remitente)",
        "showPrincipalName": "Mostrar en el campo número 1 (Remitente) el nombre de la empresa principal",
        "showWarehouseName": "Mostrar en el campo número 1 (Remitente) el nombre del depósito",
        "weightGrossCustomField": "Agregar valor de campo personalizado en el campo núm. 11 (Peso bruto en kg)"
      },
      "label": "Configuración de CMR",
      "noSettings": "Aún no hay ninguna configuración de CMR",
      "senderInstructionTextHint": "Este campo puede tener 8 líneas como máximo. Recuerde no definir líneas demasiado largas; no serán'visibles en el CMR impreso."
    },
    "columns": {
      "ADVICE": "Aviso de envío",
      "ALT_IDENTIFIER": "Identificador alternativo",
      "ARRIVAL_DATE": "Entrega",
      "CAR": "Vehículo",
      "CARRIER": "Empresa transportista",
      "CONTRACTOR": "Contratista",
      "CONTRACTOR_FULL_NAME": "Contratista: nombre completo",
      "CONTRACTOR_SHORT_NAME": "Contratista: nombre corto",
      "CREATION_DATE": "Fecha de creación",
      "CUSTOM_FIELDS": "Campos personalizados",
      "DELIVERY_INCOTERMS": "Incoterms",
      "DELIVERY_NETTO_WEIGHT": "Peso neto [kg]",
      "DOCUMENTS": "Documentos",
      "DOCUMENT_IDENTIFIER": "ID del documento",
      "DRIVER": "Conductor",
      "DRIVER_IDENTIFIER": "ID'del conductor",
      "DRIVER_PHONE": "Teléfono",
      "EMAIL_ADVICE": "Aviso de envío por correo electrónico",
      "EMAIL_ADVICE_EMAIL_ADDRESS": "Dirección de correo electrónico",
      "IDENTIFIER": "ID",
      "INDEX_DOCUMENT_NUMBER": "Número de pedido",
      "INDEX_PRODUCTS": "Productos",
      "INDEX_TRANSPORT_NUMBER": "Núm. de transporte",
      "INSPECTION": "Inspección",
      "INVOICE": "Factura",
      "LOAD_CITY": "Ciudad",
      "LOAD_COUNTRY": "País",
      "LOAD_PACKAGES_QUANTITY": "Cargando paquetes",
      "LOAD_PLACE": "Lugar de carga",
      "LOAD_QUANTITY": "Cantidad de paletas cargadas",
      "LOAD_TIME_WINDOW": "Carga",
      "LOAD_WAREHOUSE": "Depósito",
      "LOCATION": "Ubicación",
      "MANUAL_NOTIFICATION": "Notificaciones",
      "NOTES": "Notas",
      "PERIODIC_TRANSPORT_INTERNAL_INDEX": "Núm. de aviso de envío periódico",
      "PRICE": "Precio",
      "QUANTITY": "Cantidad de paletas",
      "RAMP": "Número de rampa",
      "REQUIRES_INSPECTION": "Requiere inspección",
      "SERVICE_COMPANY_SHORT_NAME": "Empresa del servicio",
      "STATUS": "Estado",
      "STORAGE_ZONE": "Tipo",
      "SUM_LOAD_QUANTITY": "Total de paletas cargadas",
      "SUM_UNLOAD_QUANTITY": "Total de paletas descargadas",
      "TOTAL_WEIGHT": "Peso [kg]",
      "TRAILER": "Tráiler/Semitráiler",
      "TRANSPORT_DESCRIPTION": "Notas",
      "TRANSPORT_RELATION": "Número de relación",
      "TRANSPORT_SUPERVISOR": "Persona de contacto",
      "UNLOAD_CITY": "Ciudad",
      "UNLOAD_COUNTRY": "País",
      "UNLOAD_PACKAGES_QUANTITY": "Descargando paquetes",
      "UNLOAD_PLACE": "Lugar de entrega",
      "UNLOAD_QUANTITY": "Cantidad de paletas descargadas",
      "UNLOAD_TIME_WINDOW": "Descarga",
      "UNLOAD_WAREHOUSE": "Depósito",
      "WORKER": "Trabajadores",
      "disableCustomFieldInfo": "Al activar/desactivar este elemento, se activan/desactivan todos los campos customFields visibles en la tabla",
      "label": "Visibilidad de columnas"
    },
    "common": {
      "accept": "Aceptar",
      "accepted": "Aceptado",
      "active": "Activo",
      "add": "Agregar",
      "addedBy": "agregado por",
      "addingBlockedWindows": "Agregando ventanas de pausa",
      "address": "Dirección",
      "allRightsReserved": "Reservados todos los derechos",
      "and": "y",
      "andBetween": "y",
      "appVersion": "Versión",
      "back": "Atrás",
      "browseAndUploadDocuments": "Examinar y cargar documentos",
      "by": "por",
      "byYou": "por usted",
      "cancel": "Cancelar",
      "cancelProcess": "Cancelar proceso",
      "changeOfField": "Cambio de campo",
      "check": "verificar",
      "choosingReason": "Motivo",
      "clear": "Borrar",
      "clearFilters": "Borrar filtros",
      "clearTextArea": "Borrar contenido de esta área de texto",
      "client": "Cliente",
      "close": "Cerrar",
      "company": "Empresa",
      "confirmMessage": "¿Está seguro?",
      "createSimilar": "Crear similar",
      "currency": "Moneda",
      "dateCreated": "Fecha de creación",
      "day": "Día",
      "days2": "2 días",
      "days4": "4 días",
      "daysOfWeek": {
        "1": "Lun",
        "2": "Mar",
        "3": "Mié",
        "4": "Jue",
        "5": "Vie",
        "6": "Sáb",
        "7": "Dom",
        "label": "para los días"
      },
      "dedicatedWindow": "Franja horaria exclusiva",
      "defaultError": "Operation failed. Please try again or contact us at info@logintegra.com.",
      "delete": "Eliminar",
      "deleteTitle": "Eliminar este transporte (el transporte eliminado aparecerá en el archivo).",
      "deletingProgress": "eliminando",
      "desc": "Descripción",
      "description": "Descripción",
      "details": "detalles",
      "disable": "Desactivar",
      "disableXLS": "Para exportar el archivo a XLS, especifique el intervalo de fechas o filtre los resultados de la búsqueda.",
      "download": "Descargar {name}",
      "downloadFile": "Descargar archivo",
      "downloadManualHeader": "Descargar manual",
      "driverMobilePhoneNumber.placeholder": "por ej., 111222333",
      "driverMobilePhonePrefix.placeholder": "por ej. 48",
      "edit": "Editar",
      "enable": "Activar",
      "english": {
        "abbr": "ing.",
        "label": "inglés"
      },
      "error": "Error",
      "errorCode": "Error code",
      "errorList": "Error list",
      "export": "Exportar",
      "exportAsXLS": "Exportar como XLS.",
      "finishedWindow": "Proceso finalizado",
      "forDateRange": {
        "M": "mes",
        "X": "intervalo personalizado",
        "Y": "año"
      },
      "formShouldNotBeEmpty": "El formulario no puede quedar vacío",
      "from": "desde",
      "fromCompany": "de la empresa",
      "further": "Siguiente",
      "grayWindow": "Franja horaria bloqueada",
      "grossWeightKg": "Peso bruto [kg]",
      "hint": "Sugerencia:",
      "hour": "Hora",
      "id": "ID",
      "idx": "Índice",
      "instructionalVideo": "Video instructivo",
      "interval": {
        "M": "{count} {count, plural, one {month} other {months}}",
        "d": "{count} {count, plural, one {day} other {days}}"
      },
      "language": {
        "ENG": "Inglés",
        "PL": "Español"
      },
      "lastUpdated": "Fecha de última modificación",
      "lateWindow": "Franja horaria final",
      "legend": "Leyenda",
      "loadingData": "Cargando datos...",
      "max": "Máx.",
      "maxDate": "Fecha máx.",
      "message": "Mensaje",
      "min": "Mín.",
      "minDate": "Fecha mín.",
      "more": "Más",
      "name": "Nombre",
      "nameEn": "Nombre en inglés",
      "namePl": "Nombre en español",
      "netWeightKg": "Peso neto [kg]",
      "newMessagesTitle": "Tiene mensajes nuevos.",
      "next": "Siguiente",
      "no": "No",
      "noAdviceAddedYet": "Aún no se agregó ningún aviso de envío",
      "noAuthority": "No tiene permiso para realizar esta acción",
      "noData": "No hay datos",
      "noResults": "Su consulta no arrojó resultados",
      "notSpecified": "No se especificó",
      "ok": "Aceptar",
      "oldBrowser": "Está usando un navegador antiguo. Nuestra aplicación funciona mejor con navegadores modernos, como Google Chrome, Firefox, Opera, Vivaldi, Safari o IE Edge.",
      "onDay": "el",
      "operationInProgress": "Operación en curso...",
      "options": "Opciones",
      "or": "o",
      "order": "Pedido",
      "orderNumber": "Núm. de pedido",
      "other": "Otros",
      "otherRequirements": "Otros requisitos",
      "otherRequirementsVisibleToCarrier": "Otros requisitos (el campo es visible para la empresa transportista)",
      "outOf": "de",
      "perPage": "por página",
      "phone": "Teléfono",
      "plan": "Planificar",
      "plannedDate": "Fecha planificada",
      "pleaseWait": "Aguarde...",
      "previous": "Anterior",
      "recipient": "Destinatario",
      "refresh": "Actualizar",
      "register": "Unir",
      "registryOfPersons": "Registro de personas",
      "reject": "Rechazar",
      "rejected": "Rechazado",
      "rememberFilters": "Recordar filtros",
      "resend": "Volver a enviar",
      "reset": "Restablecer",
      "restore": "Restaurar",
      "role": "Rol",
      "save": "Guardar",
      "saveAndClose": "Guardar y cerrar",
      "saveSuccessAdditionalMessage": "Actualizar página para ver los cambios",
      "saveSuccessMessage": "Cambios guardados correctamente",
      "saved": "Guardado",
      "saving": "Guardando",
      "scrollToClosestTransport": "Desplazarse hasta el transporte con la fecha más cercana",
      "search": "Buscar",
      "selectDate": "Seleccionar fecha",
      "selectDeselectAll": "Seleccionar/Desmarcar todo",
      "selectValue": "Select value",
      "send": "Enviar",
      "settings": "Configuración",
      "shortName": "Nombre corto",
      "shortNameEn": "Nombre corto en inglés",
      "shortNamePl": "Nombre corto en español",
      "show": "Mostrar",
      "sure": "¿Está seguro de que desea hacer esto?",
      "timeSign": {
        "antes de": "antes de",
        "después de": "después de"
      },
      "timeUnit": {
        "d": "días",
        "h": "horas",
        "m": "minutos",
        "s": "segundos"
      },
      "to": "hasta",
      "today": "Hoy",
      "transportCombiningIndicator": "Tiene la misma relación con tipo de transporte",
      "transportDeletionDisabled": "No puede eliminar este transporte. Contacte al administrador.",
      "transportEditionDisabled": "No tiene permiso para editar este transporte.",
      "type": "Tipo",
      "update": "Actualizar",
      "uploadFile": "Cargar archivo",
      "value": "Valor",
      "waiting": "En espera",
      "week": "Semana",
      "weightAndSave": "Pesar y guardar",
      "windowMovedAutomatically": "Por la demora, la ventana de tiempo se movió automáticamente",
      "yes": "Sí"
    },
    "company": {
      "address": "Dirección",
      "addresses": "Direcciones",
      "alias": "Alias",
      "allowedDomains": {
        "emailNotMatch": "Puede invitar a esta empresa solo a personas con correo electrónico de los dominios indicados: {allowedDomains}",
        "header": "Dominios permitidos para la empresa {companyName}",
        "info": "Solo se puede invitar a la empresa a las personas con uno de los dominios permitidos de la siguiente lista",
        "label": "Dominios permitidos",
        "list": "Lista de dominios permitidos:",
        "successDeleteMsg": "El dominio se ha eliminado correctamente",
        "successSaveMsg": "Los dominios se han guardado correctamente"
      },
      "chooseCompanyTypeHint": "Introduzca el tipo de empresa",
      "chooseCountryHint": "Primero, elija el país.",
      "contractorNumber": "Número de contratista",
      "country": "País",
      "createCompany": "Crear empresa",
      "deleteConfirm": "¿Está seguro de que desea eliminar la sociedad entre la empresa {companyName} y la empresa {principalName}? Tras esta acción, la empresa {companyName} no tendrá acceso a la plataforma de {principalName}.",
      "deletedMessage": "“{companyName}” se ha eliminado correctamente.",
      "descriptionOfTheFleet": "Descripción de la flota",
      "editCompany": "Editar empresa",
      "employees": "Empleados",
      "errors": {},
      "existingCompanyName": "La empresa ya existe en la plataforma. Usted no puede editar el nombre.",
      "gusHasProblem": "No hay conexión con la base de datos de la Oficina Central de Estadística. La empresa se verificará a partir de los datos de la Oficina Central de Estadística una vez restaurada la conexión.",
      "krs": "KRS/CEiDG",
      "label": "Empresa",
      "legalStatus": {
        "businessActivity": "Actividad comercial",
        "label": "Estatus legal",
        "limitedCompany": "Empresa"
      },
      "limitedDataHint": "La visualización y la edición de algunos detalles de la empresa {company} están limitadas. Solo pueden gestionarlos los administradores de la empresa {company}.",
      "list": "Lista de empresas",
      "maxNumberOfAccounts": "Cantidad máxima de cuentas",
      "maxNumberOfAccountsHint": "Tras alcanzar la cantidad máxima de cuentas, no se podrá invitar a nuevos usuarios a esta empresa. Cuando no se configura la cantidad máxima de cuentas, no hay límite para la cantidad de cuentas.",
      "name": "Nombre",
      "noEmployees": "Esta empresa no tiene empleados",
      "noPartnershipWarning": "Su empresa no tiene sociedades establecidas. Esto puede causar problemas con la selección de contratistas.",
      "partnershipStatus": {
        "ACCEPTED": "Aceptada",
        "AWAITING_VERIFICATION": "Aguardando verificación",
        "BLOCKED": "Bloqueada",
        "NO_INVITATION": "Sin invitación",
        "REGISTRATION_IS_IN_PROGRESS": "Registro en curso",
        "REJECTED": "Rechazada",
        "UPDATED": "Actualizada",
        "WAITING": "En espera",
        "label": "Estado"
      },
      "partnershipTable": {
        "awaitingLabel": "Lista de invitaciones que aguardan respuesta",
        "companyName": "Nombre de empresa",
        "label": "Lista de empresas asociadas"
      },
      "patron": "Supervisor",
      "patronWillChange": "El supervisor se modificará en los pedidos {transportIds}. ¿Está seguro? Si elige “Cancelar”, no se guardarán'los cambios.",
      "possessedCertificates": "Certificados",
      "pricingPlan": "Opción de suscripción para la empresa",
      "rampEdition": {
        "header": "Editar rampa para usuarios de la empresa {companyName}",
        "label": "Editar rampa",
        "updateForAllUsers": "Actualizar rampas para todos los usuarios de esta empresa"
      },
      "registrationDate": "Fecha de registro",
      "regon": "REGON",
      "saveSuccessMessage": "La empresa se ha guardado correctamente.",
      "sections": "Secciones",
      "selectCountry": "Seleccionar país",
      "selectType": "Seleccionar tipo",
      "selectVoivodeship": "Seleccionar provincia",
      "shortName": "Nombre corto",
      "taxIdNumber": "Número de ID de contribuyente (NIP)",
      "type": {
        "CARRIER": "Empresa transportista",
        "CLIENT": "Cliente",
        "OPERATOR": "Operador",
        "SERVICE": "Servicio",
        "SERVICEMAN": "Encargado de mantenimiento",
        "SUPPLIER": "Proveedor\\\\Destinatario",
        "SUPPLIER.CLIENT": "Cliente",
        "SUPPLIER.COLLECTION": "Destinatario",
        "SUPPLIER.COMPANY": "Empresa",
        "SUPPLIER.COURIER": "Servicio de mensajería",
        "SUPPLIER.DELIVERY": "Proveedor",
        "label": "Tipo",
        "producer": "Productor"
      },
      "typeNipHint": "Primero, introduzca el NIP.",
      "update": "Actualizar",
      "verifiedByGus": "La Oficina Central de Estadística ha verificado correctamente a la empresa. Usted no puede editar el nombre."
    },
    "companyDisabledIcon": {
      "bothInsurancesMissing": "Bloqueada. No hay seguros activos de OCP y OCS",
      "insuranceAmountTooLow": "Bloqueada. Monto de cobertura "
    },
    "companyRamp": {
      "notExists": "La empresa {companyName} no tiene asignado aún ningún acceso a rampa predeterminado",
      "updateForAllUsersConfirmMsg": "¿Está seguro de que desea sobrescribir el acceso a rampas para todos los usuarios de esta empresa?",
      "usersWithIndividualRampAccess": "Lista de usuarios con una asignación diferente de acceso a rampas"
    },
    "contractors-group": {
      "addGroup": "Agregar grupo",
      "description": {
        "details": "Detalles",
        "ifYouDoNotHaveDocument": "Si no tiene un documento requerido, desactive el grupo seleccionado.",
        "label": "Para seleccionar un grupo, hay que agregar los documentos de los seguros y las licencias correspondientes",
        "notApplicable": "No corresponde",
        "optionalDocuments": "y uno de estos documentos:"
      },
      "errors": {
        "obligatoryDocuments": "Para pertenecer al grupo {groupName}, su empresa debe tener estos documentos: {documents}.",
        "optionalDocuments": "Para pertenecer al grupo {groupName}, su empresa debe tener al menos uno de estos documentos: {documents}."
      },
      "label": "Grupos",
      "name": "Nombre",
      "nameEn": "Nombre en inglés",
      "selectADRInfo": "Tras seleccionar esta opción, la empresa se sumará al grupo de empresas transportistas que podrán ocuparse de pedidos de transporte de artículos peligrosos. Asegúrese de que su empresa tenga la autoridad para transportar materiales peligrosos.",
      "shortName": "Nombre corto",
      "shortNameEn": "Nombre corto en inglés",
      "unSelectADRInfo": "Tras cancelar la selección de esta opción, la empresa será eliminada del grupo de empresas transportistas que pueden ocuparse de pedidos de transporte de artículos peligrosos. ¿Está seguro de que desea abandonar el grupo de ADR?"
    },
    "contractors-group-type": {
      "ADR": "División en función del transporte de cargamentos y artículos peligrosos",
      "AVAILABLE_VEHICLES": "División en función de los vehículos disponibles",
      "PRIVATE": "Grupos privados; no visibles para el contratista",
      "SUPPORTED_REGIONS": "División en función de las regiones admitidas",
      "SUPPORTED_VOIVODESHIPS": "División por voivodato admitido",
      "TERRITORIAL_DIVISION": "División territorial",
      "TYPES_OF_BUSINESS": "División en función del tipo de actividad comercial",
      "TYPES_OF_TRANSPORT": "División en función de los tipos de transporte admitidos",
      "errors": {
        "canSelectOnlyOneContractorsGroup": "Seleccione solo un grupo",
        "requiresContractorsGroup": "Seleccione al menos un grupo de la sección “{groupType}”"
      }
    },
    "courier": {
      "COURIER_PACKAGE": {
        "add": "Agregar",
        "description": "Contenido",
        "disableConfirm": "¿Está seguro de que desea eliminar este elemento?",
        "edit": "Editar",
        "height": "Alto [cm]",
        "internalId": "ID",
        "length": "Largo [cm]",
        "numberOfPackagesTotal": "Cantidad total:",
        "packageIdPlaceholder": "ID",
        "packageType": "Tipo",
        "packageTypePlaceholder": "Tipo",
        "packageValue": "Valor de artículos (por pieza)",
        "packageValueLine": "Valor de artículos (por línea)",
        "packageWeightTotal": "Peso total:",
        "pallet": "PALETA",
        "parcel": "PAQUETE",
        "quantity": "Cantidad",
        "sectionLabel": "Paquetes",
        "sum": "Total:",
        "weight": "Peso (por pieza) [kg]",
        "width": "Ancho [cm]"
      },
      "documents": {
        "connote": "Talón de expedición",
        "label": "Nombre",
        "manifest": "Manifiesto"
      },
      "priceDetails": {
        "Vat": "IVA",
        "basePrice": "Precio base",
        "basePriceVat": "IVA sobre precio base",
        "dhlPremium": "12:00 Prémium",
        "emergencySituation": "Situación de emergencia",
        "finalPriceComponents": "Componentes del precio final",
        "fuelCharge": "Recargo por combustible",
        "grossPrice": "Precio bruto",
        "grossServicePrice": "Precio bruto del servicio",
        "netPriceComponents": "Componentes del precio neto",
        "netWorth": "Valor neto",
        "peakPrice": "Recargo por pico de demanda",
        "priceMultiplePackaging": "Recargo por varios paquetes",
        "servicePrice": "Precio del servicio"
      },
      "validation.float.range": "El valor debe ser un número entre 0.001 y 999999999999",
      "validation.integer.range": "El valor debe ser un número entero entre 1 y 9999999",
      "validation.minimumOnePackage": "Debe agregar al menos un paquete válido",
      "validation.range": "El valor debe ser un número entre 1 y 9999999"
    },
    "courierSettings": {
      "add": "Agregar configuración de servicios de mensajería",
      "description": {
        "isGetCourierQuotesFieldVisibility": "Visibilidad del campo “Obtener cotizaciones de servicios de mensajería”",
        "shouldGetCourierQuotesByDefault": " Obtener cotizaciones de servicios de mensajería de manera predeterminada",
        "transportType": "Tipo de transporte"
      },
      "label": "Configuración de servicios de mensajería",
      "noSettings": "Aún no hay ninguna configuración de servicios de mensajería"
    },
    "custom-field-definition": {
      "addNewField": "Agregar un campo nuevo",
      "amountOfDelayedTimeForTaskToBeRequired": "El tiempo de demora de tarea para que el campo sea obligatorio",
      "autocompleteEnabled": "Función Autocompletar activada",
      "cancel": "¿Está seguro de que desea cancelar las actualizaciones?",
      "create": "Crear una definición de campo de formulario",
      "defaultOption": {
        "hint": "La opción indicada será el valor seleccionado predeterminado",
        "label": "Nombre de la opción predeterminada",
        "placeholder": "Seleccionar una de las opciones disponibles",
        "title": "Opción predeterminada"
      },
      "delete": "¿Está seguro de que desea eliminar este campo?",
      "deleteSuccessMessage": "La configuración del campo se eliminó correctamente.",
      "deliveryAlias": "Alias para integración de MSP",
      "englishName": "Nombre en inglés",
      "errors": {
        "maxValue": {
          "dotSeparator": "El separador decimal debe ser un punto, “.”",
          "maxLowerThenMin": "El valor máximo no puede ser inferior al valor mínimo"
        },
        "minValue": {
          "dotSeparator": "El separador decimal debe ser un punto, “.”",
          "minHigherThanMax": "El valor mínimo no puede ser superior al valor máximo"
        },
        "numberDecimalFormat": {
          "regex": "El formato de número puede contener solo los siguientes caracteres: # , . 0 i _"
        },
        "obligatory": {
          "invalid": "Si selecciona el campo “Obligatorio”, seleccione al menos una de las siguientes opciones: “Obligatoriedad de un campo durante y tras la confirmación del punto de control” y “Visible durante la creación de transporte”"
        },
        "options": {
          "empty": "Indique al menos una opción"
        },
        "textAreaRowsNumber": {
          "textAreaSize": "La cantidad de líneas debe ser superior a 0"
        }
      },
      "filterInArchive": "Aplicar filtros en el archivo",
      "filterLabel": "Filtros personalizados:",
      "idx": "Idx",
      "includedInPriceList": "Incluir en lista de precios",
      "makeFieldDependentOn": {
        "definitionPlaceholder": "Elegir un nombre de un campo dependiente",
        "dependsOnTaskInfo": "El campo seleccionado tiene asignado un punto de control",
        "fieldCompleted": "Campo completado",
        "hint": {
          "obligatory": "Si la condición se cumple, el campo será visible y obligatorio",
          "voluntary": "Si la condición se cumple, el campo será visible y opcional"
        },
        "title": "Hacer que la visibilidad de un campo dependa de un valor de otro campo"
      },
      "maxValue": "Valor máximo permitido:",
      "minValue": "Valor mínimo permitido:",
      "name": "Nombre",
      "noTaskSelected": "Más allá de la tarea",
      "numberDecimalFormat": {
        "comma": "Separador de grupos",
        "dot": "Separador decimal o separador decimal monetario",
        "hash": "Dígito, cero es ausente",
        "label": "Formato de número",
        "meaning": "Significado",
        "minus": "Signo menos",
        "symbol": "Símbolo",
        "zero": "Dígito"
      },
      "obligatory": "Obligatorio",
      "order": "Orden del pedido (idx)",
      "requiredInDelayedTransport": "Obligatorio en caso de demora de transporte",
      "requiredTaskInDelayedTransport": "Campo obligatorio durante la confirmación de tareas en caso de demora del transporte",
      "saveSuccessMessage": "La configuración del campo se guardó correctamente.",
      "saveSuccessMessageForTimeWindow": "La configuración de los campos se guardó correctamente. Los cambios serán visibles en pocos minutos.",
      "settingsFields": "Configuración de campo",
      "side": "El costado en el que estará visible el campo si la cantidad de campos del formulario supera los {number} campos personalizados",
      "sideLeft": "Izquierdo",
      "sideRight": "Derecho",
      "sideShort": "Costado",
      "task": "Un campo obligatorio durante y tras la confirmación de una tarea",
      "taskHint": "Para definir la obligatoriedad del campo: “Más allá de la tarea”, haga clic en la cruz al lado de la tarea seleccionada arriba",
      "textAreaRowsNumber": "Cantidad de filas para el campo",
      "type": {
        "date": "Fecha",
        "datetime": "Fecha y hora",
        "number": "Número",
        "select": "Seleccionar",
        "text": "Texto",
        "text_area": "Área de texto"
      },
      "typeField": "Tipo de campo",
      "visibilityDependsOnDefinition": "Nombre de un campo dependiente",
      "visibilityDependsOnOption": "Valor de un campo dependiente",
      "visibleDuringTransportCreation": "Visible durante la creación de transporte",
      "visibleInAdviceSection": "Visible en un formulario de aviso de envío",
      "visibleInDeliveryDocument": "Visible en la nota de entrega",
      "visibleInDiscrepancyReportSection": "Visible en la sección “Registro de discrepancias”",
      "visibleInTable": "Visible en una tabla",
      "visibleInTransportWeightReceipt": "Visible en recibo de peso de transporte",
      "visibleInWeightSection": "Visible en la sección “Pesaje”",
      "visibleOnTimeWindow": "Visible en una franja horaria"
    },
    "custom-field-option": {
      "add": "Agregar una opción",
      "dependenciesHint": "Seleccione el campo personalizado y las opciones, en los cuales el indicador seleccionado dependerá de",
      "editDependencies": "Editar dependencias",
      "errors": {},
      "optionKey": "Valor: indicador",
      "relatedDefinition": "Campo personalizado",
      "relatedOptions": "Opciones relacionadas",
      "remove": "Eliminar una opción"
    },
    "custom-field-value": {
      "autoCompletePlaceholder": "Comience a escribir para buscar una opción.",
      "errors": {
        "maxValue": {
          "value": "El valor máximo es {maxValue}"
        },
        "minValue": {
          "value": "El valor mínimo es {minValue}"
        },
        "value": {
          "notProperValueType": "Tipo de valor erróneo, se necesita un número"
        }
      }
    },
    "dateFilterProperty": {
      "DATE_CREATED": "Fecha de creación del pedido",
      "DEPARTURE_DATE": "Fecha de entrega seleccionada (no hay franja horaria para este paso)",
      "LOAD_DATE": "Fecha de carga",
      "TIME_RANGE_FOR_ADVICE_START": "Inicio de intervalo de tiempo (no hay franja horaria para este paso)",
      "TIME_RANGE_FOR_ADVICE_STOP": "Fin de intervalo de tiempo (no hay franja horaria para este paso)",
      "TIME_WINDOW_DATES": "Inicio o fin de una franja horaria",
      "TIME_WINDOW_START": "Inicio de una franja horaria",
      "TIME_WINDOW_STOP": "Fin de una franja horaria",
      "UNLOAD_DATE": "Fecha de descarga",
      "hint": "Si el tipo de transporte permite crear un pedido sin una franja horaria, ese pedido no se incluirá en los filtros relacionados con las propiedades de las franjas horarias (por ej., Inicio de una franja horaria)."
    },
    "delivery": {
      "additionalData": "Datos adicionales",
      "contactPersonDetails": "Persona de contacto",
      "create": "Crear nueva entrega",
      "delete": "¿Está seguro de que desea eliminar la entrega?",
      "deliveryNumber": "Número de entrega",
      "description": "Descripción",
      "edit": "Editar entrega",
      "freightCost": "Costo de flete",
      "freightCostCurrency": "Moneda",
      "incoterms": "Incoterms",
      "label": "Entrega {id}",
      "modeOfTransport": "Modo de transporte",
      "placeholder": {
        "city": "Ciudad",
        "postal": "12-345"
      },
      "planningTabTitle": "Planificación de entregas",
      "step": {
        "country": "País",
        "load": "Carga",
        "loadArrivalDate": "Fecha de llegada para carga",
        "loadRealDate": "Fecha real de llegada para carga",
        "maxAdviceDate": "Final de intervalo de tiempo",
        "minAdviceDate": "Inicio de intervalo de tiempo",
        "postalAndCity": "Código postal y ciudad",
        "recipient": "Destinatario",
        "street": "Calle",
        "unload": "Descarga",
        "unloadArrivalDate": "Fecha de llegada para descarga",
        "unloadRealDate": "Fecha real de llegada para descarga"
      },
      "toPlan": "Entregas no programadas"
    },
    "dev": {
      "confirm": "Confirmar",
      "performSaveAt": "Guardar en"
    },
    "discrepancyReport": {
      "generate": "Generar un registro de discrepancias",
      "label": "Registro de discrepancias",
      "noAdviceInfo": "Se podrá generar el registro de discrepancias luego de agregar el aviso de envío",
      "noDiscrepanciesFound": "No se hallaron discrepancias",
      "noDiscrepanciesInfo": "Se podrá generar el registro de discrepancias luego de hallar discrepancias"
    },
    "document": {
      "CERTIFICATE": {
        "expirationDate": "Fecha final de caducidad del certificado",
        "number": "Número de certificado"
      },
      "INSURANCE": {
        "create": "Agregar seguro",
        "expirationDate": "Fecha final de caducidad del seguro",
        "expirationDateFrom": "Fecha inicial de caducidad del seguro",
        "new": "Nuevo {typeLabel}",
        "number": "Número de seguro",
        "save": "Guardar seguro",
        "savingInProgress": "Guardando el seguro...",
        "showArchived": "Mostrar seguros archivados"
      },
      "LICENCE": {
        "create": "Agregar licencia",
        "expirationDate": "Fecha de caducidad",
        "new": "Nuevo {typeLabel}",
        "number": "Número de licencia",
        "save": "Guardar licencia",
        "savingInProgress": "Guardando la licencia...",
        "showArchived": "Mostrar licencias archivadas"
      },
      "MAPPING": {
        "expirationDate": "Fecha final de caducidad del certificado",
        "expirationDateFrom": "Fecha inicial de caducidad del certificado",
        "number": "Número de mapa"
      },
      "amount": "Cobertura de seguro",
      "currency": "Moneda",
      "disableConfirm": "¿Está seguro de que desea asignar este documento al archivo? Los documentos archivados no se tienen en cuenta al verificar la validez.",
      "documentType": "Alcance",
      "documentType.placeholder": "Seleccionar alcance",
      "errors": {
        "expirationDate": {
          "isInPast": "La fecha de caducidad no puede ser en el pasado."
        },
        "expirationDateFrom": {
          "afterExpirationDate": "La fecha inicial de caducidad debe ser anterior a la fecha final de caducidad."
        }
      },
      "expirationShiftInfo": "El documento pierde validez {days} días antes de la fecha de caducidad del seguro; por ej., {date}",
      "expired": "Este documento ha caducado",
      "files": "Archivos agregados",
      "maxCountExceeded": "Puede agregar {maxCount} archivos como máximo en una solicitud",
      "noFilesInfo": "No se agregó ningún archivo a este documento",
      "noFilesWarning": "Agregue un archivo del documento escaneado",
      "scopeOfInsurance": "Cobertura territorial"
    },
    "documentType": {
      "CERTIFICATE": {
        "add": "Agregar certificado de calibración",
        "expirationDate": "Fecha final de caducidad",
        "expirationDateFrom": "Fecha inicial de caducidad",
        "expirationDateLabel": "Validez de la calibración",
        "name": "Certificado de calibración de sensores",
        "number": "Número de certificado",
        "showArchived": "Mostrar certificados de calibración archivados"
      },
      "SUMMER_MAPPING": {
        "add": "Agregar mapa de verano",
        "expirationDate": "Fecha final de caducidad",
        "expirationDateFrom": "Fecha de última actualización del mapa",
        "name": "Mapa de verano",
        "showArchived": "Mostrar mapas de verano archivados"
      },
      "WINTER_MAPPING": {
        "add": "Agregar mapa de invierno",
        "expirationDate": "Fecha final de caducidad",
        "expirationDateFrom": "Fecha de última actualización del mapa",
        "name": "Mapa de invierno",
        "showArchived": "Mostrar mapas de invierno archivados"
      },
      "isRequiredForModeOfTransportationInfo": "Documentos faltantes:"
    },
    "driver": {
      "addDriver": "Agregar un conductor",
      "disableConfirm": "¿Está seguro de que desea eliminar este conductor?",
      "documentNumber": "Número de documento",
      "documentType": {
        "ID_CARD": "Tarjeta de identificación nacional",
        "PASSPORT": "Pasaporte",
        "label": "Tipo de documento"
      },
      "errors": {
        "phoneNumber": {
          "regex": "El número debe contener entre 9 y 12 dígitos"
        }
      },
      "label": "Conductores",
      "lastUpdated": "Última modificación",
      "name": "Nombre y apellido",
      "nationality": "Nacionalidad",
      "phoneNumber": "Número de teléfono",
      "timeWindow": "Franja horaria"
    },
    "emailAdvice": {
      "adviceDetails": "Detalles de aviso de envío",
      "adviceDetailsCf": "Completar los datos",
      "alreadySent": "La solicitud de aviso de envío por correo electrónico ya se había enviado",
      "alreadySentCf": "La solicitud de completar los datos ya se había enviado",
      "confirm": "¿Está seguro de que desea volver a enviar la solicitud de datos? Se desactivará el enlace anterior.",
      "logLabel": {
        "ADVICE_DATA": "Solicitud de detalles de aviso de envío",
        "CF_DATA": "Solicitud de completar los datos"
      },
      "noRole": "Usted no'cuenta con suficientes permisos para realizar esta acción",
      "title": "Enviar por correo electrónico la solicitud de datos de aviso de envío",
      "titleCf": "Enviar por correo electrónico la solicitud de completar los datos"
    },
    "errors": {
      "blank": "Introduzca un valor",
      "blankCheckbox": "El campo debe estar seleccionado",
      "blankSelect": "Seleccione un valor",
      "emptyFile": "El archivo cargado está vacío; seleccione otro archivo",
      "fileAlreadyExists": "Se produjo un error al cargar el archivo. Ya existe un archivo con el nombre indicado.",
      "fileTooLarge": "Se produjo un error al cargar el archivo. El archivo es demasiado grande.",
      "fileUploadError": "Se produjo un error al cargar el archivo",
      "filterRequestTimeout": "Se produjo un error durante la búsqueda. Acote el intervalo de fecha de la búsqueda y vuelva a intentar.",
      "invalidDatesSequence": "La fecha de 'Desde' debe ser anterior a la fecha de 'Hasta'",
      "invalidEmail": "Introduzca una dirección de correo electrónico válida",
      "invalidQuantity": "La cantidad de paletas debe ser un número entero positivo",
      "noNegativeNumber": "Esta entrada no puede ser un número negativo",
      "number": "Esta entrada solo puede contener números",
      "positiveInteger": "Esta entrada debe ser un número entero positivo",
      "positiveNumber": "Esta entrada debe ser un número positivo",
      "tooLong": "Esta entrada no puede incluir más de %@ caracteres",
      "tooShort": "Esta entrada debe incluir al menos %@ caracteres"
    },
    "file": {
      "confirmDeletion": "¿Está seguro de que desea eliminar este archivo?"
    },
    "formChanges": {
      "dirtyValueChanged": "Atención Usted cambió el valor de este campo por “{yourValue}”, pero otro usuario ha guardado cambios en este campo con el valor “{newValue}”.",
      "newValue": "Atención Otro usuario definió el valor “{value}” en este campo.",
      "valueChanged": "Atención Otro usuario cambió en este campo el valor “{oldValue}” por “{newValue}”.",
      "valueRemoved": "Atención Otro usuario eliminó el valor “{value}” en este campo."
    },
    "freeSubsequentSpots": {
      "infoAboutCurrentSpot": "Rampa seleccionada: <b>{currentRamp}</b>, hora: <b>{currentStart}</b>",
      "infoAboutWindowSize": "Su franja horaria no entra en el lugar seleccionado; tiempo necesario para la descarga: <b>{windowSizeInMinutes}</b> minutos",
      "showSearchSpotsButton": "Mostrar espacios disponibles en el cronograma",
      "suggestionsHeader": "La hora más temprana posible para el aviso de envío es:"
    },
    "gates": {
      "artrIdentifier": "Identificador de ANPR",
      "create": "Crear puerta",
      "gateTypes": {
        "ENTRY_GATE": "Puerta de entrada",
        "EXIT_GATE": "Puerta de salida",
        "UNKNOWN": "Desconocida",
        "label": "Gate's type"
      },
      "label": "Puertas"
    },
    "groupTypeSettings": {
      "description": {
        "canSelectOnlyOneContractorsGroup": "Permite seleccionar solo un grupo en un tipo determinado",
        "partnershipTypesWithAccess": "Especifica los tipos de sociedades donde debe ser visible el tipo de grupo",
        "requiresContractorsGroup": "Determina si debe haber al menos un grupo seleccionado en el tipo de grupo",
        "visible": "Especifica si el tipo de grupo debe estar visible en las notificaciones"
      },
      "label": "Configuración de tipo de grupo",
      "labels": {
        "canSelectOnlyOneContractorsGroup": "Es obligatorio seleccionar solo un grupo",
        "partnershipTypesWithAccess": "Para qué tipos de sociedades debe ser visible el tipo de grupo",
        "requiresContractorsGroup": "Es obligatorio seleccionar al menos un grupo",
        "visible": "Visibilidad de tipo de grupo en presentaciones"
      }
    },
    "help": {
      "changePrincipalSpaceInfo": "Nota: La página donde se encuentra se va a actualizar; guarde los cambios antes de completar esta acción.",
      "contactUs": "Servicio de asistencia de Logintegra",
      "contactWithWarehouse": "Comuníquese con el depósito",
      "label": "Ayuda",
      "newMessagesTitle": "Tiene %@1 mensaje(s) nuevo(s).",
      "selectRamp": "Seleccione el grupo de carga.",
      "statute": "Regulaciones"
    },
    "index": {
      "INDEX": {
        "add": "Agregar un índice",
        "disableConfirm": "¿Está seguro de que desea eliminar este índice?",
        "enabled": "Índice activado",
        "globalNumber": "Número de índice",
        "globalNumber.abbr": "Núm. de índice",
        "label": "Índice",
        "localNumber": "Número local",
        "localNumber.abbr": "Núm. local",
        "name": "Nombre del producto",
        "name.abbr": "Nombre",
        "notes": "Notas de índice",
        "product": "Producto",
        "recipient": "Destinatario",
        "sectionLabel": "Índices",
        "step": "Asignar un índice a un paso de transporte",
        "version": "Número de versión"
      },
      "PALLETS": {
        "add": "Agregar paleta",
        "disableConfirm": "¿Está seguro de que desea eliminar este elemento?",
        "enabled": "Elemento activado",
        "globalNumber": "Número de paleta",
        "globalNumber.abbr": "Núm. de paleta",
        "label": "Paletas",
        "localNumber": "Número local",
        "localNumber.abbr": "Núm. local",
        "name": "Nombre del producto",
        "name.abbr": "Nombre",
        "notes": "Información adicional",
        "product": "Producto",
        "sectionLabel": "Paletas",
        "shortName": "Nombre corto",
        "step": "Asociación de paletas con un paso"
      },
      "company": "Contratista",
      "companyRecipient": "Destinatario'del cliente",
      "dateCreated": "Fecha de creación",
      "documentNumber": "Número de pedido",
      "documentNumber.abbr": "Número de pedido",
      "exportDisabledHint": "Podrá exportar tras guardar los cambios.",
      "selectCompany": "Seleccionar un cliente",
      "selectCompanyHint": "Primero seleccione el cliente en el formulario de pedidos",
      "selectRecipient": "Seleccionar un destinatario",
      "transportNumber": "Tipo de pedido",
      "transportNumber.abbr": "Tipo de pedido"
    },
    "index-quantity": {
      "errors": {
        "quantity": {
          "conditionallyObligatory": "Introduzca uno de los valores: {names}.",
          "invalidNumber": "Introduzca el número positivo correcto.",
          "obligatory": "Introduzca la cantidad."
        }
      },
      "incompleteLoadingRulesNotMet": "Se puede introducir este valor únicamente para cargas incompletas."
    },
    "inspectionSection": {
      "fillInspection": "Completar",
      "label": "Inspección",
      "noInspection": "No se realizó la inspección del transporte"
    },
    "invitation": {
      "acceptedByAnotherPerson": "Otra persona de su empresa ya aceptó la invitación.",
      "acceptedSummary": "Aceptada",
      "alreadyAccepted": "Esta invitación ya se aceptó.",
      "alreadyHandled": "Esta invitación ya se ha manejado",
      "companyRegistration": "Registro de empresa",
      "createdBy": "{personName} lo invita al sistema",
      "creator": "Creador",
      "email": "Correo electrónico",
      "errors": {},
      "expirationDate": "Fecha de caducidad",
      "for": {
        "CARRIER": "Empresa transportista",
        "CLIENT": "Cliente",
        "JOIN_COMPANY": "A su empresa",
        "OPERATOR": "Operador",
        "SERVICEMAN": "Encargado de mantenimiento",
        "SUPPLIER": "Proveedor\\\\Destinatario",
        "SUPPLIER.CLIENT": "Cliente"
      },
      "hasExpired": "Caducada",
      "instruction": {
        "label": "He leído los",
        "linkName": "TÉRMINOS GENERALES DE SERVICIO"
      },
      "invalidTaxIdNumber": {
        "info": "La información de la empresa no coincide con la de la invitación. Comuníquese con Logintegra."
      },
      "invite": "Invitar",
      "inviteToCompany": "Invitar a empresa",
      "label": "Invitación",
      "notFound": "La invitación no existe o ha caducado.",
      "ofCompany": "de la empresa {companyName}",
      "registeredAccount": "Cuenta registrada",
      "registrationComplete": "Gracias. Su cuenta se ha registrado. Inicie sesión empleando el nombre de usuario {username}.",
      "registrationCompleteLdap": "Thank you. Your account has been registered. You can log in using the login {username} and the password that is used to log in to your company's internal systems (Active Directory).",
      "rejectedSummary": "Rechazada",
      "sendTo": "Enviar una invitación",
      "sent": "Invitación enviada a {email}.",
      "taxIdNumberExist": {
        "info": "El número de identificación de contribuyente (NIP) ya existe en la base de datos. Comuníquese con Logintegra."
      },
      "toCompanyWithRole": "para iniciar una empresa {companyName} de cooperación como {roleName}",
      "toJoinExistingCompany": "a la empresa {companyName}",
      "toPartnership": "{personName} lo invita a iniciar la empresa {companyName} como {roleName}.",
      "type": {
        "CARRIER": "Unirse como empresa transportista",
        "CLIENT": "Unirse como cliente",
        "JOIN_COMPANY": "Unirse a una empresa",
        "SERVICEMAN": "Unirse como encargado de mantenimiento",
        "SUPPLIER": "Unirse a un proveedor\\\\destinatario",
        "SUPPLIER.CLIENT": "Unirse como cliente"
      },
      "userRegistration": "Registro de usuario"
    },
    "invitations": {
      "cannotInviteAnyRoleGroup": "No tiene autorización para invitar a ningún usuario",
      "emptyReceived": "No recibió una invitación aún.",
      "emptySent": "No envió una invitación aún.",
      "label": "Invitaciones",
      "noRoleGroupsFound": "No tiene autorización para invitar a ningún rol con un tipo de sociedad seleccionada",
      "received": "Recibidas",
      "sent": "Enviadas",
      "types": {
        "ACCEPTED": "Aceptada",
        "EXPIRED": "Caducada",
        "REJECTED": "Rechazada",
        "WAITING": "En espera"
      }
    },
    "log": {
      "actor": "Autor del cambio",
      "change": "Cambio",
      "dateCreated": "Fecha",
      "fieldChange": "Cambio de campo",
      "integration": {
        "resend": "Volver a enviar",
        "resendFailed": "Error al volver a enviar el mensaje",
        "resendSuccess": "El nuevo envío del mensaje se planificó correctamente",
        "sureToResend": "¿Está seguro de que desea volver a enviar el mensaje de la API?"
      },
      "method": "Método",
      "object": "Objeto",
      "partnership": "Sociedad número {id}",
      "request": "Solicitud",
      "requestType": "Tipo de solicitud",
      "response": "Respuesta",
      "transport": "Transporte",
      "url": "Dirección URL"
    },
    "logintegraEmail": "info@logintegra.com",
    "logintegraPhone": "0048 585 357 806",
    "logs": {
      "DELETE": {
        "ActionAuthority": "Se eliminó el permiso de rol de usuario dependiente del tipo de transporte",
        "ActiveAuctionRestriction": "Se eliminó la restricción",
        "ActiveRestriction": "Se eliminó la restricción",
        "AdviceDatesRestriction": "Se eliminó la restricción",
        "AfterCheckpointBasedRestriction": "Se eliminó la restricción",
        "AuthorityRestriction": "Se eliminó el permiso de rol de usuario dependiente del objeto",
        "BeforeCheckpointBasedRestriction": "Se eliminó la restricción",
        "BlockedRestriction": "Se eliminó la restricción bloqueada",
        "CompanyRamp": "Eliminación de asignación de rampas para una empresa",
        "CustomFieldValue": "Se actualizó el campo personalizado",
        "Delivery": "Entrega eliminada: ",
        "NoRouteOfferRestriction": "Se eliminó la restricción",
        "OwnCompanyRestriction": "Se eliminó la restricción",
        "PersonNotification": "Se eliminó la notificación de persona",
        "PersonRamp": "Eliminación de asignación de rampas para un usuario",
        "RoleGroupAuthority": "Se eliminó el permiso de rol de usuario",
        "Step": "Se eliminó el paso",
        "TimeBasedRestriction": "Se eliminó la restricción",
        "TransportTransportRelation": "Se eliminó la relación con transporte",
        "UnlimitedRestriction": "Se eliminó la restricción"
      },
      "INSERT": {
        "ARTR": "Integración con el sistema de ANPR",
        "ActionAuthority": "Se agregó el permiso de rol de usuario dependiente del tipo de transporte",
        "ActiveAuctionRestriction": "Se agregó la restricción",
        "ActiveRestriction": "Se agregó la restricción",
        "Advice": "Se creó el aviso de envío",
        "AdviceDatesRestriction": "Se agregó la restricción",
        "AfterCheckpointBasedRestriction": "Se agregó la restricción",
        "Auction": "Se creó la subasta",
        "AuthorityRestriction": "Se agregó el permiso de rol de usuario dependiente del objeto",
        "BeforeCheckpointBasedRestriction": "Se agregó la restricción",
        "BlockedRestriction": "Se agregó la restricción bloqueada",
        "CompanyRamp": "Reasignación de rampas de empresa",
        "CustomFieldValue": "Se finalizó el campo personalizado",
        "File": "Se agregó el archivo",
        "Index": "Se agregó el índice",
        "NoRouteOfferRestriction": "Se agregó la restricción",
        "Note": "Se creó la nota",
        "Offer": "Se agregó la oferta",
        "OwnCompanyRestriction": "Se agregó la restricción",
        "Person": "Se creó la cuenta",
        "PersonNotification": "Se agregó la notificación de persona",
        "PersonRamp": "Reasignación de rampas de usuario",
        "RoleGroupAuthority": "Se agregó el permiso de rol de usuario",
        "Step": "Se agregó el paso",
        "TimeBasedRestriction": "Se agregó la restricción",
        "Transaction": "Se derivó el pedido",
        "Transport": "Se creó el transporte",
        "TransportTransportRelation": "Se agregó la relación con transporte",
        "UnlimitedRestriction": "Se agregó la restricción"
      },
      "TYPE_EMAIL": "Envío de una notificación por correo electrónico",
      "TYPE_SMS": "Envío de notificación por SMS",
      "UPDATE": {
        "ActionAuthority": "Se cambió el permiso de rol de usuario dependiente del tipo de transporte",
        "Advice": "Se actualizó el aviso de envío",
        "Auction": "Se actualizó la subasta",
        "AuthorityRestriction": "Se cambió el permiso de rol de usuario dependiente del objeto",
        "Checkpoint": "Se actualizó el estado",
        "CompanyRamp": "Reasignación de rampas de empresa",
        "CustomFieldValue": "Se actualizó el campo personalizado",
        "File": "Se actualizó el archivo",
        "Index": "Se actualizó el índice",
        "IndexQuantity": "Se actualizó la cantidad del índice",
        "Note": "Se actualizó la nota",
        "Offer": "Se actualizó la oferta",
        "Person": "Nombre de usuario",
        "PersonRamp": "Reasignación de rampas de usuario",
        "Step": "Se actualizó el paso",
        "StepPackageType": "Paquetes",
        "TimeWindow": "Se actualizó la ventana de tiempo",
        "Transaction": "Se actualizó la transacción",
        "Transport": "Se actualizó el transporte"
      },
      "WeightLog": "Lectura de peso"
    },
    "max-pallet": {
      "errors": {},
      "label": "Límite de paletas",
      "title": "Límite de paletas por día"
    },
    "modeOfTransportation": {
      "approve": "Aprobar el modo de transporte",
      "brand": "Marca",
      "create": "Agregar un modo de transporte",
      "disabledReason": {
        "blockedSections": "Secciones bloqueadas: {blockedSections}",
        "blockingDocument": "No puede seleccionar este modo de transporte y enviar un aviso de envío al respecto debido a la fecha de validez del documento {blockingDocument} ({minValidityDate}). La validez caduca antes del final del transporte ({maxTransportDate}).",
        "label": "Estado de modo de transporte: {status}",
        "rejectedSections": "Secciones rechazadas: {rejectedSections}"
      },
      "documents": {
        "disabled": "No puede agregar documentos a un vehículo desactivado"
      },
      "edit": {
        "disable": "¿Está seguro de que desea desactivar este modo de transporte?"
      },
      "label": "{count, plural, one {Mode of transportation} other {Modes of transportation}}",
      "serialNumberOfRecorder": "Número de serie de un registrador",
      "showEnabled": {
        "false": "Mostrar solo modos de transporte activados",
        "true": "Mostrar también modos de transporte desactivados"
      },
      "status": {
        "ACCEPTED": "Aceptado",
        "AWAITING_VERIFICATION": "No aceptado",
        "BLOCKED": "Bloqueado",
        "DISABLED": "Desactivado",
        "REJECTED": "Rechazado",
        "UPDATED": "Actualizado"
      },
      "subcontractor": "Subcontratista",
      "verificationInfo": "El modo de transporte fue aprobado el {date} por el usuario {user}"
    },
    "noAccessibleStatisticsReports": "Usted no tiene acceso a ningún tipo de informe",
    "noAccessibleTransportTypes": "Usted no tiene acceso a ningún tipo de transporte",
    "note": {
      "autoGenerated": "Generada automáticamente",
      "creator": "Creador",
      "dateCreated": "Creada el",
      "label": "Nota",
      "note": "Nota",
      "transport": "Transporte"
    },
    "notification": {
      "ADVICE_CONFIRMATION": {
        "desc": "La notificación enviada al creador después de que se guarda un aviso de envío.",
        "label": "Confirmación de la creación de un aviso de envío: {transportTypeName}",
        "labelShort": "Confirmación de la creación de un aviso de envío"
      },
      "ADVICE_CREATED": {
        "desc": "Notificación enviada después de que se guarda un aviso de envío.",
        "label": "Creación de un aviso de envío: {transportTypeName}",
        "labelShort": "Creación de un aviso de envío"
      },
      "ADVICE_CREATED_SMS": {
        "desc": "Notificación por SMS enviada al conductor tras crear un aviso de envío",
        "label": "Notificación por SMS enviada al conductor tras crear un aviso de envío",
        "labelShort": "SMS enviado al conductor tras crear un aviso de envío"
      },
      "ADVICE_UPDATED": {
        "desc": "Notificación enviada a los interesados (por ej., si el creador de un aviso de envío es un proveedor\\\\destinatario, se envía un correo electrónico a un experto en logística y a las empresas transportistas) luego de la modificación de un aviso de envío.",
        "label": "Modificación de un aviso de envío: {transportTypeName}",
        "labelShort": "Modificación de un aviso de envío"
      },
      "ADVICE_UPDATED_SMS": {
        "desc": "Notificación por SMS enviada al conductor tras editar un aviso de envío",
        "label": "Notificación por SMS enviada al conductor tras editar un aviso de envío",
        "labelShort": "SMS enviado al conductor tras editar un aviso de envío"
      },
      "ADVICE_VERIFICATION": {
        "desc": "Notificación sobre la verificación de un aviso de envío",
        "label": "Notificación sobre la verificación de un aviso de envío",
        "labelShort": "Verificación de un aviso de envío"
      },
      "AUCTION_ENDED_WITHOUT_OFFERS": {
        "desc": "Notificación enviada cuando una subasta finaliza sin ofertas. Se envía a los usuarios que pueden ofertar en la subasta.",
        "label": "No hubo ofertas en esta subasta: {transportTypeName}",
        "labelShort": "Sin ofertas en la subasta"
      },
      "AUCTION_LOST": {
        "desc": "Notificación enviada luego de que usted pierde una subasta.",
        "label": "Se perdió la subasta para el transporte {transportTypeName}",
        "labelShort": "La subasta se perdió"
      },
      "AUCTION_REMINDER": {
        "Shortabel": "Recordatorio de final de subasta",
        "desc": "Notificación enviada para recordarle que se acerca el final de la subasta.",
        "label": "Recordatorio de final de subasta para el transporte {transportTypeName}"
      },
      "AUCTION_WON": {
        "desc": "Notificación enviada luego de que usted gana una subasta.",
        "label": "Se ganó la subasta para el transporte {transportTypeName}",
        "labelShort": "La subasta se ganó"
      },
      "CARRIER_CHOSEN": {
        "desc": "Notificación enviada cuando se elige o modifica la empresa transportista",
        "label": "Se ha seleccionado la empresa transportista para el transporte {transportTypeName}",
        "labelShort": "Se ha seleccionado la empresa transportista"
      },
      "CHECKPOINT_CONFIRMED_BY_SMS": {
        "desc": "Notificación por SMS enviada al conductor con la fecha programada de confirmación de un estado determinado.",
        "label": "Notificación por SMS enviada al conductor para solicitar confirmación del estado",
        "labelShort": "Notificación por SMS enviada al conductor para solicitar confirmación del estado"
      },
      "CHECKPOINT_CONFIRMED_SMS": {
        "desc": "Notificación por SMS enviada al conductor tras confirmar un punto de control",
        "label": "Notificación por SMS para el conductor tras confirmar un punto de control",
        "labelShort": "SMS tras confirmación de punto de control"
      },
      "CHECKPOINT_CONFIRMED_SMS_ASSOCIATED_WITH_USER": {
        "desc": "Notificación por SMS enviada al usuario tras confirmar un punto de control",
        "label": "Notificación por SMS para el usuario tras confirmar un punto de control",
        "labelShort": "Notificación por SMS para el usuario tras confirmar un punto de control"
      },
      "CONFIRMED_CHECKPOINT": {
        "desc": "Notificación enviada cuando se confirma el estado de {checkpointName}.",
        "label": "El estado de confirmación de {checkpointName} para el tipo de transporte {transportTypeName} ",
        "labelShort": "Confirmación de estado"
      },
      "DISCREPANCY_PROTOCOL_CREATED": {
        "desc": "Notificación enviada tras la creación del registro de discrepancias a los usuarios que tienen acceso al pedido al que se agregó el registro.",
        "label": "Creación de un registro de discrepancias para {transportTypeName}",
        "labelShort": "Creación de un registro de discrepancias"
      },
      "INVITATION": {
        "desc": "Invitación a asociarse con",
        "label": "Invitación a asociarse con",
        "labelShort": "Invitación a la sociedad"
      },
      "LATE_ADVICE": {
        "desc": "La notificación enviada si hay alguna demora en la llegada'del conductor.",
        "label": "Aviso de demora: {transportTypeName}",
        "labelShort": "Aviso de demora"
      },
      "LATE_DOCUMENT_EMAIL": {
        "desc": "La notificación enviada si el proveedor no agrega los documentos del envío en tiempo y forma.",
        "label": "Demora para agregar documentos: {transportTypeName}, {checkpointName}",
        "labelShort": "Demora para agregar documentos"
      },
      "MODE_OF_TRANSPORTATION_UPDATED": {
        "desc": "Notificación enviada tras actualizar un modo de transporte",
        "label": "Actualización del modo de transporte",
        "labelShort": "Actualización del modo de transporte"
      },
      "NEW_ADDED_OFFER": {
        "desc": "Notificación enviada cuando la empresa transportista agrega una oferta nueva.",
        "label": "Adición de oferta nueva: {transportTypeName}",
        "labelShort": "Adición de oferta nueva"
      },
      "NEW_AUCTION": {
        "desc": "Notificación enviada a los usuarios que pueden ofertar en una subasta y a los usuarios de las empresas seleccionadas durante la creación de la subasta.",
        "label": "Subasta nueva para el transporte {transportTypeName}",
        "labelShort": "Subasta nueva"
      },
      "NEW_MODE_OF_TRANSPORTATION": {
        "desc": "Notificación enviada tras agregar un modo de transporte nuevo",
        "label": "Nuevo modo de transporte",
        "labelShort": "Nuevo modo de transporte"
      },
      "NEW_OFFER": {
        "desc": "La notificación enviada a los usuarios de empresas seleccionadas durante la creación de la consulta.",
        "label": "Nueva consulta para transporte: {transportTypeName}",
        "labelShort": "Nueva consulta para transporte"
      },
      "NEW_USER_IN_COMPANY": {
        "desc": "Notificación enviada cuando se agrega un usuario nuevo a la empresa",
        "label": "Notificación enviada cuando se agrega un usuario nuevo a la empresa",
        "labelShort": "Usuario nuevo en la empresa"
      },
      "OFFER_HAS_BEEN_OUTBID": {
        "desc": "Notificación enviada cuando hay un cambio en la mejor oferta de una subasta.",
        "label": "La oferta ha sido superada: {transportTypeName}",
        "labelShort": "La oferta ha sido superada"
      },
      "OFFER_REJECTED": {
        "desc": "Notificación enviada a los usuarios de las empresas seleccionadas durante la etapa de consulta en caso de que la oferta no se haya seleccionado",
        "label": "No se seleccionó ninguna oferta en la consulta para el transporte {transportTypeName}",
        "labelShort": "No se seleccionó ninguna oferta en la consulta"
      },
      "ORDER_PASSED": {
        "desc": "Notificación enviada a los usuarios de la empresa'transportista cuando se deriva un pedido a la empresa transportista",
        "label": "Pedido derivado: {transportTypeName}",
        "labelShort": "Pedido derivado"
      },
      "ORDER_WAITING_FOR_CONFIRMATION": {
        "desc": "Notificación enviada a los usuarios de la empresa'transportista cuando se deriva un pedido a la empresa transportista y se está aguardando'la confirmación de la empresa transportista",
        "label": "El pedido aguarda confirmación: {transportTypeName}",
        "labelShort": "El pedido aguarda confirmación"
      },
      "REJECTED_CHECKPOINT": {
        "desc": "Notificación enviada cuando se rechaza el estado de {checkpointName}.",
        "label": "El rechazo del estado de {checkpointName} para el tipo de transporte {transportTypeName}",
        "labelShort": "Rechazo de estado"
      },
      "TIME_WINDOW_ADDED": {
        "desc": "Notificación enviada tras agregar una ventana de tiempo nueva al pedido",
        "label": "Ventana de tiempo creada",
        "labelShort": "Ventana creada"
      },
      "TIME_WINDOW_EDITED": {
        "desc": "Notificación enviada cuando el usuario editó una ventana de tiempo",
        "label": "Notificación enviada cuando el usuario editó una ventana de tiempo",
        "labelShort": "Ventana de tiempo editada"
      },
      "TRANSPORT_CREATED": {
        "desc": "La notificación enviada a los contratistas después de guardar un transporte.",
        "label": "Creación de un transporte {transportTypeName}",
        "labelShort": "Creación de un transporte"
      },
      "TRANSPORT_DELETED_EMAIL": {
        "desc": "Notificación enviada al eliminar un transporte",
        "label": "Notificación enviada al eliminar un transporte",
        "labelShort": "Transporte eliminado"
      },
      "TRANSPORT_DELETED_SMS": {
        "desc": "Notificación por SMS tras eliminar el transporte (avisos de envío por correo electrónico)",
        "label": "Notificación por SMS tras eliminar el transporte (avisos de envío por correo electrónico)",
        "labelShort": "SMS tras eliminar el transporte (avisos de envío por correo electrónico)"
      },
      "roleGroups": {
        "desc": "Los usuarios con un rol determinado podrán recibir una notificación determinada y activar/desactivar notificaciones en la configuración de su cuenta.",
        "label": "Roles de usuario",
        "labelShort": "Roles de usuario"
      }
    },
    "notificationLog": {
      "TYPE_EMAIL": "Se ha enviado un correo electrónico",
      "TYPE_SMS": "Se ha enviado un mensaje de texto"
    },
    "notificationToast": {
      "deliveryTransport": {
        "ADD_DELIVERY_TO_TRANSPORT": {
          "error": "Incompatible loading cities in deliveries!"
        },
        "CREATE": {
          "error": "Error during planning of transport: ",
          "loading": "In progress of planning transport: ",
          "success": "Successfully planned transport: "
        }
      },
      "titles": {
        "error": "Error",
        "loading": "Cargando",
        "success": "Correcto"
      },
      "transportCombining": {
        "error": "Error al fusionar transportes",
        "loading": "Combinación de transporte en curso",
        "success": "Se fusionaron los transportes"
      }
    },
    "notifications": {
      "add": "Agregar notificación",
      "appendTransportFiles": "Enviar archivos adjuntos",
      "availableNotifications": "Notificaciones disponibles",
      "edit": "Editar",
      "label": "Notificaciones",
      "legendAdviceUpdated": "Variables empleadas para el mensaje de notificación ACTUALIZACIÓN DE AVISO DE ENVÍO",
      "legendCommon": "Variables generales",
      "legendDescriptions": "Puede usar variables en el contenido del SMS. Solo introduzca el nombre de la variable delimitada con “%” en el cuerpo del SMS. Ejemplo de uso de variable: %TRAILER_REG_NUM%",
      "mailNotification": "Notificación por correo electrónico",
      "manualNotification": "Notificación manual",
      "messageEn": "Contenido en inglés",
      "messageInputType": {
        "ADDRESS_CITY": "Ciudad de descarga",
        "CAR_REG_NUM": "Número de registro de vehículo",
        "COMPANY_SHORT_NAME": "Nombre corto de la empresa",
        "GATE": "Gate's name in location",
        "LOCATION": "Ubicación de depósito",
        "NEW_ADVICE_DATE": "Fecha nueva del aviso de envío",
        "OLD_ADVICE_DATE": "Fecha anterior del aviso de envío",
        "OLD_REGISTRATION_NUMBER": "Número de registro anterior del vehículo",
        "PLACE_NAME": "Queue's place name",
        "QUEUE_NAME": "Nombre de cola",
        "RAMP_MESSAGE_EN": "Información adicional de la rampa asignada al transporte EN",
        "RAMP_MESSAGE_PL": "Información adicional de la rampa asignada al transporte ES",
        "RAMP_NUMBER": "Número de rampa",
        "SQUARE_NAME": "Nombre de cuadra",
        "TIME_WINDOW_DATES": "Fechas de ventanas de tiempo",
        "TRAILER_REG_NUM": "Número de registro de tráiler",
        "TRANSPORT_ID": "Número de identificación de transporte"
      },
      "messagePl": "Contenido en español",
      "new": "Notificación nueva",
      "noNotificationsForRole": "No hay notificaciones disponibles para el rol seleccionado.",
      "selectRole": "Seleccione un rol primero.",
      "sureToDelete": "¿Está seguro de que desea eliminar esta notificación?",
      "task": "Tarea",
      "transportType": "Tipo de transporte"
    },
    "offer": {
      "auction": "Subasta",
      "company": "Empresa",
      "creator": "Usuario",
      "dateCreated": "Fecha de creación",
      "deleted": "Eliminada",
      "description": "Comentarios sobre la oferta",
      "edit": "Editar",
      "enabled": "Activada",
      "errors": {
        "auction": {
          "hasEnded": "Disculpe,'la subasta de este pedido ya ha finalizado. No pudimos guardar su oferta."
        },
        "price": {
          "higherThanMaximum": "El precio introducido supera el precio máximo que la empresa principal definió para este pedido.",
          "lowerThanMinimum": "El precio introducido es inferior al precio mínimo que la empresa principal definió para este pedido.",
          "nonPositiveNumber": "La oferta realizada debe ser un número mayor que cero.",
          "reductionNotMet": "Disculpe, {principalCompanyName} ha establecido que el monto mínimo por el cual debe reducir su oferta anterior es {currencySymbol} {minReductionSize}."
        }
      },
      "hasWon": "Oferta ganadora",
      "label": "Oferta",
      "place": "lugar",
      "price": "Su oferta",
      "revoke": {
        "alert": "¿Está seguro de que desea revocar la oferta actual para este pedido?",
        "fail": {
          "CannotRevokeOffer": "No puede revocar esta oferta."
        },
        "label": "Revocar la oferta actual",
        "success": "La oferta se revocó correctamente"
      },
      "save": "Guardar oferta",
      "savingInProgress": "Guardando oferta...",
      "transitTime": "Tiempo de tránsito",
      "valid": "Válida"
    },
    "packageTypes": {
      "CARTONS": {
        "label": "Cajas",
        "lowerCase": "cajas",
        "singular": "caja"
      },
      "CHEP_PALLETS": {
        "label": "Paletas CHEP",
        "lowerCase": "Paletas CHEP",
        "singular": "Paleta CHEP"
      },
      "CUBIC_METERS": {
        "label": "Metros cúbicos",
        "lowerCase": "metros cúbicos",
        "singular": "Metro cúbico"
      },
      "DISPOSABLE_PALLETS": {
        "label": "Paletas descartables",
        "lowerCase": "Paletas descartables",
        "singular": "Paleta descartable"
      },
      "EURO_PALLETS": {
        "label": "Paletas EURO",
        "lowerCase": "Paletas EURO",
        "singular": "Paleta EURO"
      },
      "LOADING_PLACES": {
        "label": "Lugares de carga",
        "lowerCase": "lugares de carga",
        "singular": "Lugar de carga"
      },
      "PALLETS": {
        "label": "Paletas",
        "lowerCase": "paletas",
        "singular": "paleta"
      },
      "PALLETS_WITH_RETURNED_GOODS": {
        "label": "Paletten mit zurückgegebenen Waren",
        "lowerCase": "paletten mit zurückgegebenen Waren",
        "singular": "Palette mit zurückgegebenen Waren"
      },
      "PALLET_PLACES": {
        "label": "Lugares de paletas",
        "lowerCase": "lugares de paletas",
        "singular": "Lugar de paletas"
      },
      "RETURNABLE_PALLETS": {
        "label": "Paletas retornables",
        "lowerCase": "paletas retornables",
        "singular": "Paleta retornable"
      },
      "SPATIAL_METERS": {
        "label": "Metros espaciales",
        "lowerCase": "metros espaciales",
        "singular": "Metro espacial"
      },
      "THE_NUMBER_OF_ITEMS": {
        "label": "La cantidad de artículos",
        "lowerCase": "la cantidad de artículos",
        "singular": "Una pieza"
      },
      "noRelationsWithPackageTypes": "No hay relaciones entre el tipo de transporte y los tipos de paquete",
      "referenceQuantity": "Cantidad como {referencePackage}: {referenceQuantity}",
      "transportTypes": {
        "confirmDelete": "¿Está seguro de que desea eliminar la conexión entre el tipo de transporte y el tipo de paquete?",
        "defaultValue": "Valor predeterminado",
        "idx": "Orden del pedido",
        "minValue": "Valor mínimo",
        "multiplier": "{package} por {referencePackage}",
        "packageType": "Tipo de paquete",
        "referencePackage": "Atención Está cambiando la configuración del tipo de paquete de referencia.",
        "referencePackageChkbox": "Paquete de referencia",
        "required": "Obligatorio",
        "transportType": "Tipo de transporte",
        "usedForMaxPalletLimitChkbox": "Incluir para recuento de límite máx. de paletas",
        "visibleInContractorStep": "Visible en paso de contratista",
        "visibleInLoadPackageQuantityInTransportTable": "Cantidad de carga visible en tabla de transportes",
        "visibleInUnloadPackageQuantityInTransportTable": "Cantidad de descarga visible en tabla de transportes",
        "visibleInWarehouseStep": "Visible en paso de depósito"
      }
    },
    "page": {
      "pageTitle": "Plataforma de logística"
    },
    "paidFeaturesInfo": {
      "authorities": {
        "UNLIMITED_ACCESS_TO_ARCHIVED_TRANSPORTS": "El plan de precios actual de su empresa le permite acceder a pedidos de los últimos <b>3 meses</b>."
      },
      "label": "Recomendamos leer sobre los planes de precios disponibles para empresas"
    },
    "partnership": {
      "ANTI_CORRUPTION_TYPE": "Declaración anticorrupción",
      "CONTRACTOR_GROUP_ADDED": "Grupo agregado",
      "CONTRACTOR_GROUP_REMOVED": "Grupo eliminado",
      "GROUPS": "Grupos",
      "INSURANCE_DOMESTIC_OCP": "Seguro de responsabilidad civil de la empresa transportista para transporte nacional",
      "INSURANCE_INTERNATIONAL_OCP": "Seguro de responsabilidad civil de la empresa transportista para transporte internacional",
      "INSURANCE_OC": "Seguro contra terceros",
      "INSURANCE_OCS": "Seguro de responsabilidad civil'del transitario",
      "LICENCE_DOMESTIC": "Licencia nacional",
      "LICENCE_FORWARDING": "Licencia de transitario",
      "LICENCE_INTERNATIONAL": "Licencia internacional",
      "MAIN_DATA_TYPE": "Datos principales",
      "SECONDATY_DATA_TYPE": "Datos secundarios",
      "STATUS_CHANGED": "Cambio de estado",
      "TYPE_EMAIL": "Correo electrónico enviado",
      "add": {
        "hint": "Agregar sociedad entre proveedor y empresa transportista.",
        "label": "Agregar sociedad",
        "title": "Agregar sociedad entre contratistas"
      },
      "additionalDetails": "Detalles adicionales",
      "adviceVerification": {
        "firstPartLabel": "Envíe un correo electrónico para verificar los datos",
        "secondPartLabel": "antes de cada aviso de envío"
      },
      "allowsPeriodicTransport": "Avisos de envío periódicos",
      "auxiliary": {
        "editionDisabled": "Usted no tiene permiso de edición.",
        "title": "La empresa {contractorName} opera como contratista de la empresa {principalCompanyName}.",
        "usersEditionDisabled": "Usted no puede editar los usuarios."
      },
      "connectAll": {
        "CARRIER": "empresas transportistas",
        "SUPPLIER": "proveedores",
        "SUPPLIER.CLIENT": "clientes",
        "cofirmMsg": "¿Está seguro de que desea conectar a la empresa {companyName} con todos los {contractorsType} en el espacio de {principalCompanyName}?",
        "label": "Conectar a todos los contratistas",
        "success": "Se han creado las sociedades"
      },
      "contractor": "Contratista",
      "contractorOfCompany": "de la empresa {companyName}",
      "dateCreated": "Fecha de creación",
      "deleteMsg": "¿Está seguro de que desea eliminar esta sociedad entre contratistas?",
      "errors": {
        "additionalDetails": {
          "length": "La cantidad máxima de caracteres es 1000"
        },
        "adviceVerificationInterval": {
          "intervalFormatError": "Formato de intervalo erróneo; introduzca un número seguido de una unidad de tiempo: s (segundos), m (minutos), h (horas) o d (días). Por ej., 48 h"
        },
        "contractorShortName": {
          "contractorShortNameTooLong": "El nombre corto es demasiado largo."
        },
        "representativeEmail": {
          "email": "Introduzca una dirección de correo electrónico válida"
        },
        "representativePhoneNumber": {
          "regex": "El número de teléfono de México debe constar de diez dígitos"
        },
        "transportLimit": {
          "invalidNumber": "El límite de transportes debe ser un número natural."
        }
      },
      "logs": {
        "CONTRACTOR_GROUP_ADDED": "Se ha agregado el grupo de contratistas",
        "CONTRACTOR_GROUP_REMOVED": "Se ha eliminado el grupo de contratistas",
        "DOCUMENT_DISABLED": "Se ha desactivado el documento",
        "DOCUMENT_SAVED": "Se ha guardado el documento",
        "DOCUMENT_UPDATED": "Se ha actualizado el documento",
        "FILE_ADDED": "Se ha agregado el archivo",
        "FILE_DELETED": "Se ha eliminado el archivo",
        "SECTION_CONFIRMED": "Se ha confirmado la sección",
        "SECTION_REJECTED": "Se ha rechazado la sección",
        "SECTION_SAVED": "Se ha guardado la sección",
        "SECTION_UPDATED": "Se ha actualizado la sección",
        "STATUS_CHANGED": "Ha cambiado el estado",
        "TYPE_EMAIL": "Notificación por correo electrónico"
      },
      "representativeEmail": "Representante: correo electrónico",
      "representativeName": "Representante",
      "representativePhoneNumber": "Representante: número de teléfono",
      "transportLimit": "Límite de transportes",
      "transportLimitHint": "Si no hay límite, deje el campo vacío.",
      "transportsCompletedCount": "De los cuales se completaron",
      "transportsCreatedCount": "Transportes creados",
      "transportsInProgressCount": "Transportes en curso"
    },
    "partnerships": {
      "label": "Sociedades"
    },
    "periodicTransport": {
      "cannotChangeCompany": "No puede cambiar esta empresa; en el pedido debe incluirse al menos una empresa con la opción de aviso de envío periódico activada",
      "cannotDeleteStep": "No puede eliminar este paso; en el pedido debe incluirse al menos una empresa con la opción de aviso de envío periódico activada",
      "deleteAllConfirmMsg": "¿Desea eliminar todos los avisos de envío periódicos futuros? “Cancelar” elimina solo este pedido",
      "errors": {
        "chooseAtLeastOneDay": "Elija al menos un día",
        "chooseFirstDayOfPeriodicTransport": "Elija un día de la semana en que se realice el primer transporte periódico",
        "endDateMustBeAfterStartDate": "La fecha final debe ser posterior a la fecha inicial",
        "incorrectRangeOption": "Intervalo incorrecto; los valores posibles son “Diaria”, “Semanal” y “Mensual”",
        "windowMustBeInFirstDayOfPeriodicTransport": "El primer día del aviso de envío periódico debe ser el mismo que en la fecha programada para el aviso de envío",
        "wrongDateRange": "Para la opción seleccionada “{range}” el intervalo de fecha máximo para un aviso de envío periódico es {interval}. Defina un intervalo de fecha más corto para un aviso de envío periódico."
      },
      "frequencies": {
        "DAILY": "Diaria",
        "MONTHLY": "Mensual",
        "WEEKLY": "Semanal"
      },
      "frequency": "Frecuencia del aviso de envío",
      "label": "Aviso de envío periódico",
      "updateAllConfirmMsg": "¿Desea actualizar todos los avisos de envío periódicos futuros? “Cancelar” guarda los cambios solo de este pedido"
    },
    "pricingPlans": {
      "BASIC": "BÁSICO",
      "CLIENT": "CLIENTE",
      "OPERATOR": "OPERADOR",
      "PRO": "PROFESIONAL"
    },
    "product": {
      "INDEX": {
        "name": "Nombre del producto",
        "name.autoCompletePlaceholder": "Comience a escribir para buscar un producto por su nombre"
      },
      "ORDER": {
        "name": "Tipo de pedido",
        "name.autoCompletePlaceholder": "Comience a escribir para buscar por tipo de pedido"
      },
      "add": "Agregar producto",
      "disableConfirm": "¿Está seguro de que desea eliminar este producto?",
      "disabled": "El producto {index} se eliminó correctamente.",
      "errors": {
        "index": {
          "tooLong": "El texto introducido es demasiado largo. La longitud máxima es de 255 caracteres."
        },
        "name": {
          "tooLong": "El texto introducido es demasiado largo. La longitud máxima es de 255 caracteres."
        },
        "shortName": {
          "tooLong": "El texto introducido es demasiado largo. La longitud máxima es de 255 caracteres."
        }
      },
      "index": "Índice",
      "index.autoCompletePlaceholder": "Comience a escribir para buscar un producto por su índice",
      "saved": "El producto {index} se guardó correctamente.",
      "shortName": "Nombre corto",
      "shortName.autoCompletePlaceholder": "Comience a escribir para buscar un producto por su nombre corto",
      "unit": "Unidad",
      "updated": "El producto {index} se actualizó correctamente."
    },
    "products": {
      "INDEX": {
        "label": "Productos",
        "table": "Tabla de productos"
      },
      "ORDER": {
        "label": "Productos",
        "table": "Tabla de productos"
      }
    },
    "queues": {
      "disableConfirm": "¿Está seguro de que desea desactivar esta cola?",
      "englishName": "Nombre en inglés",
      "idx": "Secuencia",
      "updateSuccessMessage": "La configuración de la cola {queueName} se modificó correctamente."
    },
    "queuesStatus": {
      "sendSmsModal": {
        "messagePlaceholder": "Introduzca el mensaje de texto",
        "title": "Un mensaje de texto para los conductores de la cola:"
      },
      "tableHeader": "Resumen del estado actual de las colas"
    },
    "ramp": {
      "additionalSmsNotificationInfoEn": "Additional information EN",
      "additionalSmsNotificationInfoPl": "Additional information PL",
      "backToWarehouse": "De regreso en el depósito",
      "deleteDedicatedWindows": "Eliminar franjas horarias exclusivas sin utilizar/utilizadas",
      "disableConfirm": "¿Está seguro de que desea desactivar esta rampa?",
      "disabledMessage": "La rampa “{rampName}” se eliminó correctamente.",
      "enableConfirm": "¿Está seguro de que desea activar esta rampa?",
      "enabledMessage": "El nombre “{rampName}” se activó correctamente.",
      "errors": {
        "cannotMoveWindowException": "No puede mover la franja horaria al lugar indicado.",
        "idx": {
          "idxTooBig": "El orden de la rampa no debe ser superior a la cantidad de rampas del depósito.",
          "nonPositiveInteger": "El orden de la rampa debe ser un número entero positivo."
        },
        "shortName": {
          "sizeTooBig": "El tamaño máximo para los nombres cortos es de 10 caracteres."
        },
        "wrongSwappedWindowSize": "Las franjas horarias intercambiadas tienen duraciones diferentes"
      },
      "idx": "Orden",
      "label": "Rampa",
      "maxPallets": "Límite de paletas",
      "roleGroupsLabel": "Roles de usuarios para los cuales la rampa estará disponible",
      "updateSuccessMessage": "Se ha'actualizado la configuración de la rampa.",
      "wantToSwapWindow": "¿Desea intercambiar las franjas horarias?"
    },
    "ramps": {
      "label": "Rampas"
    },
    "relationsWizard": {
      "description": "Tras la aprobación, los elementos seleccionados se vincularán y representarán a un vehículo. Los elementos no seleccionados tras la aprobación no estarán en el grupo de transportes vinculados.",
      "errors": {
        "atLeastOneOfAKind": "You must select at least one transport each from the list of existing links and the list of transports without links."
      },
      "label": "Combinación de transportes",
      "table": {
        "button": {
          "cancel": "Return",
          "createRelations": "Create relations"
        },
        "column": {
          "address": "Dirección",
          "relationIdentifier": "Identificador de relación",
          "weight": "Peso [kg]",
          "wz": "Identificador de ERP"
        },
        "header": {
          "existingRelations": "Relaciones actuales",
          "withoutRelations": "Elementos para relacionar"
        }
      }
    },
    "reports": {
      "contractors": {
        "header": "Generar un informe de transportes cargados/descargados entre",
        "label": "Informe de franjas horarias"
      },
      "label": "Informes"
    },
    "resetPasswordWindow": {
      "enteredPasswordsIsNotIdentical": "Las dos contraseñas deben ser idénticas.",
      "header": "Cambie su contraseña del sistema",
      "info": "Restablezca la contraseña de acuerdo con nuestra política de seguridad de sistemas",
      "info2": "Las contraseña nueva y la anterior deben ser diferentes y tener 8 caracteres (números, y letras minúsculas y mayúsculas)."
    },
    "roleGroup": {
      "label": "Rol",
      "placeholder": "Seleccione un rol"
    },
    "route": {
      "addNewRate": "Agregar tarifa nueva",
      "addNewRoute": "Agregar ruta nueva",
      "address": {
        "city": "Ciudad",
        "country": "País",
        "load": "Carga",
        "postal": "Código postal",
        "unload": "Descarga"
      },
      "addresses": "Direcciones",
      "bestOffer": "Mejor oferta",
      "details": "Detalles de ruta",
      "edition": "Editar",
      "errors": {
        "description": {
          "length": "El texto introducido es demasiado largo. La longitud máxima es de 255 caracteres."
        }
      },
      "label": "Ruta",
      "newRoute": "Ruta nueva",
      "noTransportTypeAvailable": "Actualmente no tiene acceso a ningún tipo de transporte con una lista de precios",
      "offer": {
        "carrier": "Empresa transportista",
        "confirmation": {
          "failure": "Disculpe, no pudimos confirmar la oferta. Contacte a su administrador del sistema.",
          "message": "¿Está seguro de que desea confirmar esta oferta?"
        },
        "confirmed": "Confirmada",
        "date": "Fecha de tarifa",
        "deleteMessage": "¿Está seguro de que desea eliminar esta oferta?",
        "edition": "Editar",
        "number": "Número de oferta",
        "price": "Tarifa",
        "showLess": "Mostrar menos ofertas ({n})",
        "showMore": "Mostrar más ofertas ({n})",
        "toggleTable": "Alternar lista' de ofertas",
        "validity": "Fechas de validez"
      },
      "rate": "Tarifa",
      "rates": {
        "label": "Rates"
      },
      "termOfValidity": "Plazo de validez"
    },
    "scheduleActionSelectModal": {
      "addWindow": "Agregar ventana",
      "blockedWindow": "Ventana bloqueada",
      "createTransport": "Crear transporte",
      "dedicatedWindow": "Ventana exclusiva",
      "title": "Elegir acción"
    },
    "sección anticorrupción": {
      "errors": {
        "signedFiles": "A fin de enviar una solicitud de verificación, debe agregar la declaración anticorrupción firmada"
      },
      "internalNotes": "Notas",
      "patternFileDescription": "Plantilla de formularios",
      "signedFile": "Agregar archivo firmado",
      "statementFileDescription": "Declaración firmada por {company} para descargar"
    },
    "sección principal": {
      "errors": {
        "hasErrors": "La sección de datos principales contiene errores. Complete correctamente los campos obligatorios.",
        "krsFile": "Para presentar una solicitud de verificación, debe agregar el documento exigido como prueba de KRS.",
        "nipFile": "Para presentar una solicitud de verificación, debe agregar el documento exigido como prueba de NIP.",
        "regonFile": "Para presentar una solicitud de verificación, debe agregar el documento exigido como prueba de REGON."
      }
    },
    "sección secundaria": {
      "bankAccountNumber": "Número de cuenta bancaria",
      "bankFilePattern": {
        "label": "Plantilla de formularios"
      },
      "errors": {},
      "internalNotes": "Notas internas",
      "label": "Datos adicionales",
      "otherAttachments": "Otros adjuntos"
    },
    "section": {
      "accept": "Aceptar",
      "dateOfArchiving": "Fecha de archivado",
      "dateOfDeletion": "Fecha de eliminación",
      "dateOfExpiration": "Fecha de caducidad",
      "deleted": "Eliminada",
      "disable": "Desactivar",
      "disabled": "Desactivada",
      "expired": "Caducada",
      "label": "sección",
      "reject": "Rechazar",
      "requestVerification": "Solicitar verificación",
      "state": {
        "ACCEPTED": "Aceptada",
        "ARCHIVED": "Archivada",
        "DELETED": "Eliminada",
        "DOCUMENT_ADDED": "Actualizada",
        "EXPIRED": "Caducada",
        "PENDING": "Pendiente",
        "REJECTED": "Rechazada",
        "UPDATED": "Actualizada",
        "label": "Estado de sección"
      },
      "successMessage": "La sección se guardó correctamente",
      "update": "Actualizar"
    },
    "sectionType": {
      "ANTI_CORRUPTION_TYPE": "Declaración anticorrupción",
      "BLOCKADES": "Bloqueos activos",
      "GROUPS": "Grupos",
      "HISTORY": "Historial",
      "INSURANCE": "Seguros",
      "INSURANCE_DOMESTIC_OCP": "Seguro de responsabilidad civil de la empresa transportista para transporte nacional",
      "INSURANCE_INTERNATIONAL_OCP": "Seguro de responsabilidad civil de la empresa transportista para transporte internacional",
      "INSURANCE_OC": "Seguro contra terceros de la empresa",
      "INSURANCE_OCS": "Seguro de responsabilidad civil'del transitario",
      "LICENCE": "Licencia",
      "LICENCE_DOMESTIC": "Licencia nacional",
      "LICENCE_FORWARDING": "Licencia de transitario",
      "LICENCE_INTERNATIONAL": "Licencia internacional",
      "MAIN_DATA_TYPE": "Datos principales",
      "SECONDARY_DATA_TYPE": "Datos secundarios",
      "SENSOR_CALIBRATION_CERTIFICATE": "Certificado de calibración de sensores",
      "SUMMER_MAPPING": "Mapa de verano",
      "USERS": "Usuarios",
      "VEHICLE_MAIN_DATA_TYPE": "Datos del modo de transporte",
      "WINTER_MAPPING": "Mapa de invierno"
    },
    "service": {
      "arrival": "Llegada",
      "company": "Empresa",
      "contractorCompanyPlaceholder": "Nombre de empresa",
      "createTitle": "Programar servicio",
      "departure": "Salida",
      "description": "Descripción del servicio",
      "registrationNumber": "Número de registro",
      "selectCompany": "Seleccionar empresa",
      "serviceman": "Nombre del encargado de mantenimiento",
      "title": "Servicio #%@1"
    },
    "serviceNotWorking": {
      "content": "Momentáneamente, la plataforma no está disponible. Vuelva a intentar más tarde. Disculpe las molestias.",
      "header": "Estimado usuario:",
      "signature": "El equipo de Logintegra"
    },
    "settings": {
      "backToSettings": "Volver a la configuración",
      "confirmDelete": "¿Está seguro de que desea eliminar esta configuración?",
      "create": "Agregar opción de configuración",
      "desc": {
        "ADDITIONAL_ADD_FILE_BUTTON": "Botón adicional para agregar documentos en el formulario de pedidos",
        "ADDITIONAL_CONFIRMATION_DURING_REMOVING_GRAY_WINDOW": "Confirmación adicional al retirar la ventana de bloqueo gris",
        "ADDITIONAL_INFO_ON_PLAIN_TEMPLATE_VIEW": "Información adicional incluida en la vista de impresión del formulario de pedidos",
        "ADDITIONAL_INFO_ON_PLAIN_TEMPLATE_VIEW_EN": "Información adicional incluida en la vista de impresión del formulario de pedidos: versión en inglés",
        "ADD_FILE_TO_TRANSPORT_AFTER_OFFER_ACCEPTATION": "Especifica si el archivo debe agregarse al transporte tras ganar la subasta o tras la derivación del pedido a la empresa transportista",
        "ADD_FILE_TO_TRANSPORT_AFTER_OFFER_ACCEPTATION_NAME": "Nombre del archivo que se adjunta al transporte tras ganar la subasta o tras la derivación del pedido a la empresa transportista",
        "ADVICE_CREATED_EMAIL_FILE_NAME": "Versión del correo electrónico sobre la adición del aviso de envío",
        "ADVICE_CREATED_EMAIL_SUBJECT": "El asunto del correo electrónico sobre la adición del aviso de envío",
        "ADVICE_UPDATED_EMAIL_FILE_NAME": "Versión del correo electrónico sobre la edición del aviso de envío",
        "ADVICE_VERIFICATION_EMAIL_ENABLED": "Especifica si los campos relacionados con el envío de un correo electrónico para verificar los datos antes de cada aviso de envío aparecen en los detalles de la empresa",
        "ALT_IDENTIFIER_LABEL": "El nombre de la columna en la que se especifica el número de pedido",
        "ALT_IDENTIFIER_LABEL_EN": "El nombre de la columna en la que se especifica el número de pedido: en inglés",
        "ALT_IDENTIFIER_RESET_INTERVAL": "La frecuencia de restablecimiento de la secuencia del número de “altIdentifier” generado automáticamente",
        "ANNOUNCEMENTS_COUNTER_POLL_INTERVAL": "Frecuencia de la actualización de datos en ms: contador de anuncios",
        "API_MESSAGE_URL": "Dirección de envío de mensajes de la API",
        "ARCHIVING_JOB_INTERVAL": "¿Cuánto tiempo después de la confirmación del estado final debemos mover el transporte al archivo?",
        "ASSORTMENT_GROUP_LABEL": "El nombre del campo del formulario en el que se especifica el grupo de surtidos",
        "ASSORTMENT_GROUP_LABEL_EN": "El nombre del campo del formulario en el que se especifica el grupo de surtidos: en inglés",
        "AUCTION_ENDED_WITHOUT_OFFERS_EMAIL_FILE_NAME": "Versión de un correo electrónico sobre un final de subasta sin ofertas",
        "AUTOMATIC_DELETING_DEDICATED_WINDOWS_INTERVAL": "Cuándo, en relación con su tiempo en el cronograma, deben eliminarse las ventanas exclusivas sin utilizar (si está seleccionada la opción “Eliminar automáticamente franjas exclusivas sin ocupar/ocupadas”). Por ejemplo, si el valor es “-2 h”, la franja exclusiva sin utilizar de las 16:00 (4:00 p. m.) se eliminará a las 14:00 (2:00 p. m.).",
        "AUTOMATIC_DELETING_UNUSED_DEDICATED_WINDOWS_ENABLED": "Eliminar automáticamente franjas horarias exclusivas sin utilizar",
        "AUTOMATIC_DELETING_USED_DEDICATED_WINDOWS_ENABLED": "Eliminar automáticamente franjas horarias exclusivas utilizadas",
        "AUTOMATIC_GENERATE_DOCUMENT": "Especifica si debemos mostrar el indicador shouldGenerateDocument",
        "AUTOMATIC_WEIGHING": "Activa la opción de pesaje automático",
        "AUTO_GENERATED_DELIVERY_NOTE_FIXED_TEXT": "Texto fijo que se muestra en una nota de entrega generada automáticamente.",
        "AVAILABLE_LOGIN_PAGE_BACKGROUND_FILE_NAMES": "Nombres de archivos de fondos de página de inicio de sesión disponibles",
        "CAN_ARRIVAL_DATE_BE_IN_THE_PAST": "Determina si la fecha de llegada puede ser en el pasado",
        "CAN_EXPORT_INDEXES_TO_XLS": "Activa la posibilidad de exportar índices a un archivo de Excel",
        "CAN_REFRESH_MANUALLY": "Opción de actualización manual de los datos",
        "CHANGE_TRANSPORT_INTERVAL": "Hasta qué punto en relación con la fecha estimada de carga/descarga debe ser posible para el proveedor/la empresa transportista cambiar el transporte.",
        "CHECKPOINT_CONFIRMED_EMAIL_FILE_NAME": "Versión del correo electrónico sobre la confirmación en el punto de control",
        "CHECKPOINT_REJECTED_EMAIL_FILE_NAME": "Versión del correo electrónico sobre el rechazo en el punto de control",
        "CLASSIFICATIONS_TAB_POLL_INTERVAL": "Frecuencia en ms con la que se actualizan los datos: pestaña “Clasificación”.",
        "CLASSIFICATION_RESOURCE_COMPLETED_DELAY": "Especifica el tiempo tras el cual el objeto debe desaparecer de la cola en la pestaña “Colas” si aún no se ha confirmado el estado de Salida",
        "CLASSIFIED_RESOURCE_DISAPPEARANCE_DELAY": "Especifique la demora tras la cual los transportes clasificados desaparecerán de la pestaña “Clasificación”.",
        "COMPANY_FORM_SHOW_REPRESENTATIVE": "Visibilidad de los campos relacionados con el representante en el formulario de empresa.",
        "CONTRACTOR_NUMBER_LABEL": "Nombre del campo en el que se especifica el número'del contratista",
        "CONTRACTOR_NUMBER_LABEL_EN": "Nombre del campo en el que se especifica el número'del contratista: en inglés",
        "CUSTOM_FIELD_SPLIT_VALUE": "La cantidad de campos personalizados que, cuando se supera, genera la división de esos campos en dos columnas en el formulario de transporte",
        "DAYS_TO_RESET_PASSWORD": "Con qué frecuencia, en días, se debe indicar al usuario que cambie la contraseña.",
        "DAYS_TO_SHOW_ADVICE_HISTORY": "¿Desde qué momento (defina un intervalo de tiempo en días) debemos incluir los datos del conductor en la lista de sugerencias durante la creación/edición del aviso de envío?",
        "DEFAULT_ADVICE_FUTURE_DAYS_LIMIT": "El límite predeterminado de días para enviar avisos de envío asignado a los nuevos contratistas.",
        "DEFAULT_EMAIL_DELAY_INTERVAL": "Demora predeterminada para el envío de correos electrónicos",
        "DEFAULT_NUMBER_OF_DISPLAYED_OFFERS": "Cantidad predeterminada de ofertas para la ruta en la lista de precios",
        "DEFAULT_TAB_AFTER_LOGIN": "Pestaña predeterminada que se muestra tras el inicio de sesión",
        "DELAY_TIME_TO_MOVE_ORDER_TO_ARCHIVE_AFTER_TASK_TIMEOUT": "Demora tras la cual el pedido se mueve automáticamente al archivo (solo para las tareas indicadas)",
        "DELAY_TIME_TO_MOVE_TIME_WINDOW_TO_NEXT_FREE_SPOT": "Demora tras la cual la plataforma debe mover la ventana a la ventana más cercana posible",
        "DISPLAY_INDEXES_IN_SCHEDULE": "Mostrar una lista de índices en la vista del cronograma",
        "DISPLAY_TRAILER_REG_NUMBER_IN_ADVICE_COLUMN": "Muestra un número de registro de tráiler en lugar de un número de registro en la columna “Aviso de envío”",
        "DOCUMENTS_MONITORING_INTERVAL": "La demora al agregar los documentos tras la cual se envía un correo electrónico al cliente.",
        "DOCUMENT_SECTION_VISIBLE_IN_TRANSPORT_FORM": "Visibilidad'de la sección de documentos en el formulario de transporte",
        "DRIVER_PUNCTUALITY_INTERVAL": "Tiempo con respecto al inicio de la ventana que determina la demora de la confirmación del paso",
        "EMAIL_NOTIFICATION_CONTAINS_PRODUCTS_SECTION": "Especifica si la sección de productos debe aparecer en los correos electrónicos",
        "EMAIL_NOTIFICATION_CONTAINS_UNLOAD_SECTION": "Especifica si la sección de descarga debe aparecer en los correos electrónicos",
        "ENABLE_TRANSPORT_ROW_HIGHLIGHTING": "Determina si la fila de transporte debe resaltarse tras hacer clic",
        "ENABLE_TRANSPORT_TABLE_DAYS_LIMIT": "Activa un filtro en la tabla de pedidos activos para ocultar los pedidos con franjas horarias posteriores a una fecha especificada",
        "END_OF_DAY_IN_WAREHOUSE_INTERVAL": "Final del día hábil en el depósito empleado para contar las franjas horarias de aviso de envío. Advertencia: El final del día hábil en el depósito puede diferir. Ejemplo: Para definir el final del día a las 6:00 del día siguiente, el valor del parámetro debe ser 30 h [30.01. 00:00 + 30 h = 31.01. 06:00]",
        "ERP_IDENTIFIER_LABEL": "El nombre de la columna en la que se especifica el número de pedido de ERP",
        "ERP_IDENTIFIER_LABEL_EN": "El nombre de la columna en la que se especifica el número de pedido de ERP: en inglés",
        "FILE_TERMS_OF_CONDITIONS_ACCEPTED": "Enlace a los términos de servicio.",
        "FIND_ADDRESSES_MAX": "Cantidad de direcciones propuestas con la opción de autocompletar",
        "FOOTER": "Enlace a un pie de página que se muestra en la plataforma",
        "FOREIGN_DELIVERY_MULTIPLIER": "El valor por el cual se multiplica la duración de la descarga para las entregas en el extranjero.",
        "HAS_ACCESS_TO_APPLIES": "Especifica si deben verificarse los documentos'de la empresa transportista",
        "HAS_ACCESS_TO_MAX_PALLET_PER_DAY": "Especifica si se activa el límite de paletas para cada día",
        "HAS_ACCESS_TO_MAX_PALLET_PER_RAMP": "Especifica si se activa el límite de paletas para cada rampa",
        "HAS_ACCESS_TO_NUMBER_OF_WINDOWS": "Especifica si se debe mostrar la cantidad de franjas horarias para un día determinado en un depósito. Depende del comienzo y el final del día en el depósito.",
        "HD_MODE_NEXT_DAY_SHIFT_TIME": "Especifica el horario en que un cronograma con la vista de HD debe pasar al día siguiente (formato de HH:mm)",
        "HOW_MANY_DAYS_BEFORE_CERTIFICATE_EXPIRATION_SHOULD_MAIL_BE_SENT": "Cuántos días antes del final del plazo de validez del certificado de calibración de sensores debe enviarse un recordatorio por correo electrónico",
        "HOW_MANY_DAYS_BEFORE_DOCUMENT_EXPIRATION_DATE_CARRIER_SHOULD_BE_BLOCKED": "¿Cuántos días antes de la fecha de caducidad del documento debe bloquearse la empresa transportista?",
        "HOW_MANY_DAYS_BEFORE_INSURANCE_EXPIRATION_SHOULD_MAIL_BE_SENT": "Cuántos días antes del final del plazo de validez del seguro debe enviarse a la empresa transportista un recordatorio por correo electrónico",
        "HOW_MANY_DAYS_BEFORE_MAPPING_EXPIRATION_SHOULD_MAIL_BE_SENT": "Cuántos días antes del final del plazo de validez del mapa debe enviarse un recordatorio por correo electrónico",
        "HOW_MUCH_TIME_BEFORE_START_WINDOW_EDITING_ORDER_SENDS_SMS": "Cuánto tiempo antes del inicio de la franja horaria la edición del aviso de envío generará el envío de mensajes de texto.",
        "HYPERLINK_TO_LOGO_ON_NAVBAR": "Hiperenlace al logotipo que aparece en la barra de navegación tras el inicio de sesión",
        "INVITATION_EXPIRATION_INTERVAL": "El período de validez de la invitación.",
        "LATE_ADVICE_EMAIL_FILE_NAME": "Versión de un correo electrónico sobre la demora",
        "LIMITED_INVITATION_MODE": "Los usuarios, salvo los administradores que pueden hacer todo, solo pueden invitar a usuarios para su rol y a contratistas",
        "LINK_TO_APPLIES_MANUAL": "Enlace al Manual de postulaciones",
        "LOADING_URL": "La URL de la plataforma",
        "LOCAL_PRINCIPAL_LANGUAGE": "Idioma local de la empresa",
        "LOGO_ON_LOGIN_PAGE": "Enlace al logotipo que aparece en la página de inicio de sesión.",
        "LOGO_ON_NAVBAR": "Enlace al logotipo que aparece en la barra de navegación tras el inicio de sesión.",
        "MANAGE_TRANSPORT_INTERVAL": "Hasta qué punto en relación con la fecha estimada de carga/descarga debe ser posible crear o modificar el aviso de envío del transporte",
        "MAXIMUM_NUMBER_OF_ORDERS_TO_COPY": "Cantidad máxima de pedidos que pueden crearse mediante la función “Crear similar”",
        "MAXIMUM_PERMISSIBLE_VEHICLE_WEIGHT_SETTING": "Peso máximo permitido para el vehículo [t]",
        "MAX_CHARACTERS_IN_INDEX_PRODUCTS_COLUMN": "Cantidad máxima de caracteres que se muestran en la columna de productos",
        "MAX_CHARACTERS_IN_ORDER_NUMBER_COLUMN": "Cantidad de caracteres que se muestran en las columnas con números de pedidos",
        "MAX_CHARACTERS_IN_TRANSPORT_RELATION_COLUMN": "Cantidad máxima de caracteres que se muestran en la columna de relaciones",
        "MAX_NUMBER_OF_TIME_WINDOWS_FOR_SUGGEST": "Cantidad máxima de ventanas de tiempo sugeridas",
        "MAX_SIMULTANEOUSLY_ADDED_FILES_COUNT": "Cantidad máxima permitida de archivos que se pueden agregar al pedido en simultáneo",
        "MAX_WINDOW_LENGTH": "Duración máxima permitida de la ventana de tiempo",
        "MIN_WEIGHT_TO_CREATE_TRANSPORT": "Peso mínimo para crear un transporte automáticamente",
        "NAME_TERMS_OF_CONDITIONS_ACCEPTED": "Nombre de los términos de servicio.",
        "NAME_TERMS_OF_CONDITIONS_ACCEPTED_EN": "Nombre de los términos de servicio: en inglés",
        "NEW_ADVICE_EMAIL_RECIPIENTS": "Direcciones de correo electrónico adicionales a las que se enviará la notificación sobre un nuevo aviso de envío (separadas con comas; por ej., xyz@xyz.com,abc@abc.com)",
        "NEW_AUCTION_EMAIL_FILE_NAME": "Versión de un correo electrónico sobre una subasta nueva",
        "NEW_TRANSPORT_EMAIL_FILE_NAME": "Versión de un correo electrónico sobre un pedido nuevo",
        "ORDER_FORM_PRINTING_ENABLED": "Especifica si, en el formulario de pedidos, hay una versión para imprimir disponible",
        "ORDER_WAITING_FOR_CONFIRMATION_EMAIL_FILE_NAME": "Versión del correo electrónico sobre la derivación del pedido a la empresa transportista",
        "OWN_ADDRESSES_ONLY": "Acceso solo a direcciones introducidas en un espacio de empresa principal (no se aplica a las direcciones principales de empresas)",
        "PACKAGE_TYPES_FEATURE_ENABLED": "Visibilidad de la pestaña de gestión de la configuración de paquetes",
        "PATRON_IN_CONTRACTOR_DETAILS_ENABLED": "Determina si el campo “Supervisor” está visible en los detalles de la empresa",
        "PERCENTAGE_OF_TRANSPORTS_TAKEN_FOR_RANDOM_INSPECTIONS": "¿Qué porcentaje de los transportes debe escribirse para los controles aleatorios?",
        "POLL_INTERVAL": "Frecuencia en ms con la que se actualizan los datos: tabla y cronograma.",
        "QUEUES_TAB_POLL_INTERVAL": "Frecuencia en ms con la que se actualizan los datos: la pestaña “Colas” y la pestaña “Estado de colas”",
        "RESET_PASSWORD_IS_DISABLED": "Desactivar el restablecimiento obligatorio de contraseña.",
        "ROLES_WITH_ENABLED_USER_AND_GROUP_FILTER": "Lista de roles con filtros adicionales activados (mis pedidos, pedidos del grupo, todos los pedidos). Los valores deben indicarse tras el punto decimal; por ej., “ADMIN, GUARD”.",
        "ROLE_ADD_CONTRACTOR_GROUP": "La posibilidad de agregar un grupo nuevo en la sección de agrupamiento de los detalles'del contratista",
        "ROLE_EDIT_CONTRACTOR_GROUP": "Posibilidad de editar grupos de contratistas ya existentes",
        "SCHEDULE_LEGEND_ENABLED": "Activa una leyenda en un cronograma",
        "SEND_API_MESSAGE_EVERY_TRANSPORT_UPDATE": "Activa el envío de mensajes de la API tras cada actualización de transportes",
        "SEND_CONTRACTOR_LATE_ADVICE_MAIL": "Especifica si se debe enviar el correo electrónico de demora a los contratistas externos cuando el creador del pedido es la empresa principal o al contratista creador cuando el creador del pedido es el contratista.",
        "SEND_FUTURE_PERIODIC_TRANSPORTS_EDIT_MAIL_INTERVAL_IN_DAYS": "Cantidad de días en el futuro en que deben enviarse las notificaciones por correo electrónico si se editan los avisos de envío periódicos",
        "SEND_SMS_AFTER_CREATING_ORDER": "Enviar un mensaje de texto al conductor tras crear un aviso de envío",
        "SEND_SMS_AFTER_EDITING_ORDER": "Enviar un mensaje de texto al conductor tras editar un aviso de envío",
        "SET_SCHEDULE_LEGEND_TO_FIXED_POSITION": "Determina si la leyenda del cronograma debe estar siempre a la vista",
        "SHOULD_RENDER_ALL_TRANSPORT_TYPES": "De forma predeterminada, mostrar todos los tipos de transportes en la tabla de pedidos activos.",
        "SHOW_COMPANY_VOIVODESHIP_FIELD": "Visibilidad del campo “Provincia” en los datos de empresas",
        "SHOW_PRINCIPAL_ADDRESS_IN_PRINT_VIEW": "Visibilidad de la dirección de la empresa principal en la vista de impresión",
        "SHOW_QUANTITY_SUM_CHECK_MESSAGE": "Mostrar un mensaje cuando hay más de X paletas en un pedido",
        "SHOW_TERMS_OF_CONDITIONS_ACCEPTED": "El campo donde se pueden aceptar los términos de servicio debe estar visible en el formulario de aviso de envío.",
        "SHOW_TRANSPORT_COMBINING_MODAL_ON_SINGLE_OPTION": "Muestra una ventana para fusionar transportes, incluso con una sola opción de fusión posible",
        "SHOW_USERS_FOR_SUPPLIER": "Activa una lista de usuarios en los detalles del proveedor/destinatario",
        "SORT_TABLE_BY_TRANSPORT_COMPLETED": "Determina si los transportes de la tabla de transportes estarán ordenados de modo predeterminado por el estado “Finalizado”",
        "START_OF_DAY_IN_WAREHOUSE_INTERVAL": "El comienzo del día hábil en el depósito empleado para contar las franjas horarias de aviso de envío. Nota: El comienzo del día hábil puede diferir.",
        "SUPPLIER_FIELD_IN_COMPANY_DETAILS_TRANSLATION_KEY": "Identificador de traducción del campo “Proveedor/Receptor”. Valores posibles: 'CLIENT','COMPANY','COURIER'",
        "TERMS_OF_SERVICE_FILE_EXT": "Extensión del archivo de Términos de servicio; por ej., .docx o .pdf",
        "TIME_TO_ACCEPT_AUTO_ROUTE_OFFER_PASS": "Tiempo para aceptar el pedido en caso de derivación automática de los pedidos",
        "TIME_TO_ACCEPT_TRANSPORT_INTERVAL": "Tiempo para aceptar un pedido",
        "TIME_TO_RESTORE_ORDER_FROM_ARCHIVE": "Tiempo antes del cual el pedido se puede restaurar del archivo",
        "TIME_WINDOW_OFFSET_INTERVAL": "Cantidad de minutos para la que se puede definir una franja horaria. Los valores posibles son 15, 30 y 60",
        "TRANSPORTS_LIST_LABEL": "Nombre de lista de transportes",
        "TRANSPORTS_LIST_LABEL_EN": "Nombre de lista de transportes: inglés",
        "TRANSPORT_DESCRIPTION_COLUMN_MAX_CHARS": "Cantidad máxima de caracteres que se muestran en la columna Notas",
        "TRANSPORT_FREE_SLOT_SEARCH_INTERVAL_IN_DAYS": "Intervalo (en días) en el cual el programa debe buscar una ventana de tiempo libre durante la creación de un aviso de envío periódico",
        "TRANSPORT_IDENTIFIER_TYPE": "Especifica el valor con el cual el usuario que tiene acceso a la API REST tendrá acceso a los transportes. Las opciones disponibles son: ID, ERP_IDENTIFIER y ALT_IDENTIFIER.",
        "TRANSPORT_PAGINATION_DEFAULT_MAX_ROWS": "Especifica la cantidad máxima predeterminada de filas por página para la paginación de la tabla de transportes",
        "TRANSPORT_TABLE_DAYS_LIMIT": "El tiempo (en días) tras la fecha actual en el que se ocultarán los pedidos en la tabla de pedidos activos",
        "USE_ADFS_AUTH_FOR_API_MESSAGE": "Enables authentication for the ADFS service (when sending API messages)",
        "USE_BIDS_COUNTER": "Muestra el número de ofertas en lugar de la etiqueta «Historial» en la tabla Pedidos en la columna Carga.",
        "USE_WEBSOCKETS": "Activa la compatibilidad con websocket",
        "WAREHOUSE_LABEL": "El nombre del campo del formulario en el que se especifica el depósito",
        "WAREHOUSE_LABEL_EN": "El nombre del campo del formulario en el que se especifica el depósito: en inglés",
        "WAREHOUSE_ZONE_LABEL": "El nombre del campo del formulario en el que se especifica la zona de depósito",
        "WAREHOUSE_ZONE_LABEL_EN": "El nombre del campo del formulario en el que se especifica la zona de depósito: en inglés",
        "WEEK_SCHEDULE_START_DATE_SHIFT": "Cantidad de días agregados al comienzo de un cronograma en la vista semanal",
        "WEIGHT_OF_PALLET": "Indica el peso de una paleta en kilogramos",
        "WEIGHT_SELECTOR": "Marca de un elemento con un peso en la página",
        "WEIGHT_URL": "Enlace a la aplicación de la balanza",
        "WINDOW_CHANGE_MAIL_DELAY_INTERVAL": "La duración de la demora tras la que se envía un correo electrónico al definir la franja horaria.",
        "WINDOW_CONSTANT_TIME": "Valor constante en minutos agregado a la duración de la descarga en las franjas.",
        "XLS_ADDRESSES_ENABLED": "Incluir la columna “Direcciones” en el informe XLS",
        "XLS_DESCRIPTION_ENABLED": "Incluir la columna “Notas” en el informe XLS",
        "XLS_EXTERNAL_ADDRESSES_DATA_ENABLED": "Incluir la columna “Datos de direcciones externas” en el informe XLS",
        "XLS_TOP_ROW_ENABLED": "Activar el encabezado para los informes en XLS."
      },
      "description": "Descripción",
      "edit": "Editar opción de configuración",
      "editable": "Editable",
      "label": "Opciones principales de configuración",
      "name": "Nombre",
      "other": "Otras opciones de configuración",
      "restSettingLabel": "Otras opciones de configuración",
      "roleGroups": {
        "actionIsPossible": "la acción es posible",
        "actionType": "Autoridad",
        "actionTypeDescription": {
          "ACCEPT_AND_REJECT_TRANSPORT": "Con este permiso, se puede aceptar y rechazar los pedidos derivados al contratista.",
          "ACCESS_CARRIER_COMPANY_FIELD": "Define la visibilidad del campo “Empresa transportista” en el formulario de transporte. También requiere el permiso “Acceso a tipo de transporte”",
          "ACCESS_CUSTOM_FIELD": "Define la visibilidad de un campo personalizado en el formulario de transporte",
          "ACCESS_DESCRIPTION_FIELD": "Define la visibilidad del campo “Nota” en el formulario de transporte. También requiere el permiso “Acceso a tipo de transporte”",
          "ACCESS_INDEX": "Un usuario con este rol tiene acceso a la sección de índices.",
          "ACCESS_PACKAGES": "Define la visibilidad de la sección “Paquetes” en el formulario de transporte. También requiere el permiso “Acceso a tipo de transporte”",
          "ACCESS_SERVICE_COMPANY_FIELD": "Define la visibilidad del campo “Servicio” en el formulario de transporte. También requiere el permiso “Acceso a tipo de transporte”",
          "ACCESS_SUPPLIER_COMPANY_FIELD": "Define la visibilidad del campo “Proveedor/Destinatario” en el formulario de transporte. También requiere el permiso “Acceso a tipo de transporte”",
          "ACCESS_TRANSPORT": "Activa el acceso a un tipo de transporte indicado (la visibilidad del tipo de transporte en la tabla de pedidos)",
          "ADD_ADVICE": "Le permite agregar datos de avisos de envío.",
          "ADD_DOCUMENTS": "Brinda la posibilidad de adjuntar documentos al pedido. Se necesita acceso a la columna con documentos en la tabla.",
          "ADD_LOAD_STEP_FROM_LOAD": "Agregar un paso de carga tras un paso de carga.  También es necesario activar la opción de tipo de transporte “Visibilidad de una sección de pasos en el formulario de transporte”.",
          "ADD_LOAD_STEP_FROM_UNLOAD": "Agregar un paso de carga tras un paso de descarga. También es necesario activar la opción de tipo de transporte “Visibilidad de una sección de pasos en el formulario de transporte”.",
          "ADD_NOTES": "Activa la posibilidad de agregar una nota a un transporte en la tabla de pedidos. Se necesita acceso a la columna con notas en la tabla.",
          "ADD_PACKAGES": "Brinda la opción de agregar un paquete en la sección “Paquetes” del formulario de transporte. También requiere el permiso “Visibilidad de sección de paquetes”",
          "ADD_TIME_WINDOW": "Activa la posibilidad de agregar una ventana de tiempo.",
          "ADD_UNLOAD_STEP_FROM_LOAD": "Agregar un paso de descarga tras un paso de carga.  También es necesario activar la opción de tipo de transporte “Visibilidad de una sección de pasos en el formulario de transporte”.",
          "ADD_UNLOAD_STEP_FROM_UNLOAD": "Agregar un paso de descarga tras un paso de descarga. También es necesario activar la opción de tipo de transporte “Visibilidad de una sección de pasos en el formulario de transporte”.",
          "ADVICE_ONLY_ON_DEDICATED_WINDOWS": "Agregar ventanas de tiempo solo en ventanas exclusivas",
          "BID_AUCTION": "Brinda la posibilidad de participar en la subasta y presentar ofertas.",
          "CREATE_AUCTION": "El usuario con este permiso tiene la opción de crear una subasta.",
          "CREATE_TRANSPORT": "Le permite crear un transporte nuevo. También requiere el permiso “Acceso a tipo de transporte”.",
          "DELETE_DOCUMENTS": "Le permite al usuario eliminar el documento adjunto para transporte. Se necesita acceso a la columna con documentos en la tabla.",
          "DELETE_INDEX": "Le permite eliminar el índice agregado para transporte.",
          "DELETE_LOAD_STEPS": "Activa la opción de eliminar etapas de carga.  También es necesario activar la opción de tipo de transporte “Visibilidad de una sección de pasos en el formulario de transporte”.",
          "DELETE_PACKAGES": "Brinda la opción de eliminar un paquete en la sección “Paquetes” del formulario de transporte. También requiere el permiso “Visibilidad de sección de paquetes”",
          "DELETE_TRANSPORT": "El usuario con este nivel de permiso puede eliminar transportes creados previamente. También requiere el permiso “Acceso a tipo de transporte”.",
          "DELETE_UNLOAD_STEPS": "Activa la opción de eliminar etapas de descarga.  También es necesario activar la opción de tipo de transporte “Visibilidad de una sección de pasos en el formulario de transporte”.",
          "DISABLE_DATA_FIELDS": "Especifica si los datos del formulario de transporte deben desactivarse",
          "EDIT_COURIER_INVOICE_SECTION": "Activa la opción de editar la sección “Factura de artículos”",
          "EDIT_DESCRIPTION_FIELD": "Activa la posibilidad de editar el campo “Notas” en los detalles del pedido. También requiere el permiso “Acceso a tipo de transporte”",
          "EDIT_INDEX": "Brinda la posibilidad de editar índices.",
          "EDIT_PACKAGES": "Permite editar el paquete en la sección “Paquetes” del formulario de transporte. También requiere el permiso “Visibilidad de sección de paquetes”",
          "EDIT_TRANSACTION_FREIGHT": "Activa la opción de editar el flete en un pedido derivado a la empresa transportista",
          "MOVE_ADVICE_WINDOW": "Activa la posibilidad de mover franjas horarias de avisos de envío",
          "MOVE_LOAD_STEPS": "Activa la opción de mover las etapas de carga en el formulario.  También es necesario activar la opción de tipo de transporte “Visibilidad de una sección de pasos en el formulario de transporte”.",
          "MOVE_UNLOAD_STEPS": "Activa la opción de mover las etapas de descarga en el formulario. También es necesario activar la opción de tipo de transporte “Visibilidad de una sección de pasos en el formulario de transporte”.",
          "PASS_COURIER": "Brinda la posibilidad de derivar el transporte al servicio de mensajería.",
          "PASS_TRANSPORT": "Brinda la posibilidad de derivar el transporte al contratista.",
          "RESIZE_ADVICE_WINDOW": "Permite cambiar el tamaño de las franjas horarias de avisos de envío. También requiere el permiso “Mover franja horaria de avisos de envío” para funcionar correctamente.",
          "ROLE_ACCESS_AUCTION_DATA": "Activa la visibilidad de los datos de subastas",
          "ROLE_ACCESS_COURIER_INVOICE_SECTION": "Define la visibilidad de la sección “Factura de artículos”",
          "ROLE_ACCESS_FREIGHT_DATA": "Activa la visibilidad de la tarifa del flete",
          "ROLE_ACCESS_TO_INSPECTION_BUTTON": "Visibilidad del botón “Inspección”",
          "ROLE_ACCESS_TO_INSPECTION_PANEL": "Visibilidad del panel “Inspección”",
          "ROLE_ACCESS_TO_LOCATION": "Visibility of the 'Ubicación' field.",
          "ROLE_ACCESS_TRANSPORT_ADDITIONAL_DESCRIPTION": "Visibilidad del campo “Notas adicionales”",
          "ROLE_ACCESS_WEIGHT_SECTION": "Define la visibilidad de la sección “Pesaje” en el formulario de transporte. También requiere el permiso “Acceso a tipo de transporte”",
          "ROLE_ACCESS_WORKER_FIELD": "Con el permiso, puede hacer visible el campo “Trabajadores” en el formulario de creación de transporte",
          "ROLE_AUTOMATIC_PASS_TRANSPORT_TO_ROUTE_OFFERS": "Activa la transferencia automática de transporte a los contratistas posteriores.",
          "ROLE_CAN_ACCESS_ARCHIVED_TRUCK_DOCUMENTS": "Acceso a documentos archivados en las secciones del modo de transporte",
          "ROLE_CAN_ACCESS_COURIERS_SECTION": "Define el acceso a la sección “Servicios de mensajería” en el formulario de transporte",
          "ROLE_CAN_ACCESS_TASK": "Activa la visibilidad de la tarea",
          "ROLE_CAN_ACCESS_TRANSPORT_SUPERVISOR": "Visibilidad del campo “Persona de contacto” en el formulario de transporte",
          "ROLE_CAN_ADD_HAS_TO_WAIT_FOR_RELATION": "Activa la posibilidad de agregar un enlace entre transportes",
          "ROLE_CAN_ADD_ROUTE": "Posibilidad de agregar una ruta nueva a la lista de precios",
          "ROLE_CAN_ADD_TRANSPORT_COMBINING_RELATION": "Activa la posibilidad de vincular transportes entre sí. Los transportes combinados tienen en común las notificaciones, las ventanas y los estados.",
          "ROLE_CAN_AUTOMATICALLY_CONFIRM_PREVIOUS_CHECKPOINTS": "Al confirmar el estado indicado, el usuario también confirma automáticamente todos los estados previos sin confirmar",
          "ROLE_CAN_BLOCK_TASK": "Activa la opción de bloquear la confirmación de tarea",
          "ROLE_CAN_CHOOSE_MORE_THAN_ONE_LOCATION": "Activa la selección de más de 1 valor en el campo “Ubicación” en los detalles de pedidos. También requiere el siguiente permiso: “Acceso a tipo de transporte”",
          "ROLE_CAN_CONFIRM_TASK": "Activa la opción de confirmación de tarea",
          "ROLE_CAN_CREATE_DELIVERY": "Activa la posibilidad de crear entregas en la pestaña “Planificación”",
          "ROLE_CAN_CREATE_TRANSPORT_FROM_SCHEDULE": "Creación de un transporte directamente desde el cronograma",
          "ROLE_CAN_DELETE_DELIVERY": "Activa la eliminación de entregas en la pestaña “Planificación”",
          "ROLE_CAN_DELETE_RELATION": "Activa la posibilidad de desvincular transportes. Con el permiso, puede desconectar uno de los transportes.",
          "ROLE_CAN_DELETE_ROUTE": "Posibilidad de eliminar rutas en listas de precios",
          "ROLE_CAN_DOWNLOAD_REGISTRY_OF_PERSONS_XLS": "Con el permiso, puede exportar el informe de registros de personal a un archivo de Excel",
          "ROLE_CAN_EDIT_COURIERS_SECTION": "Le permite editar los campos de la sección “Servicios de mensajería” en el formulario de transporte. Requiere el permiso “Acceso a la sección Servicios de mensajería”",
          "ROLE_CAN_EDIT_DELIVERY": "Activa la posibilidad de editar entregas en la pestaña “Planificación”",
          "ROLE_CAN_EDIT_READY_TO_PLAN": "Activa la posibilidad de editar el indicador “Preparado para planificar” en el formulario de transporte",
          "ROLE_CAN_EDIT_ROUTE": "Posibilidad de editar rutas en listas de precios",
          "ROLE_CAN_EDIT_TRANSPORT_PALLETS_WITHOUT_CHANGING_TIME_WINDOW_SIZE": "Posibilidad de aumentar la cantidad de paquetes en el transporte a pesar de la falta de espacio para extender la ventana de tiempo de acuerdo con el algoritmo.",
          "ROLE_CAN_EDIT_TRANSPORT_SUPERVISOR": "Posibilidad de editar el campo “Persona de contacto” en el formulario de transporte",
          "ROLE_CAN_MULTI_ARCHIVE_TRANSPORTS": "Archivado grupal de transportes",
          "ROLE_CAN_SCAN_BARCODES": "Activa la posibilidad de escanear códigos de barras para buscar transportes",
          "ROLE_CAN_SEND_EMAIL_ADVICE": "Brinda la posibilidad de enviar una solicitud para suministrar datos de avisos de envío mediante avisos de envío por correo electrónico",
          "ROLE_CAN_SEND_EMAIL_ADVICE_CF": "Brinda la posibilidad de enviar una solicitud para suministrar datos de pedidos mediante avisos de envío por correo electrónico",
          "ROLE_CAN_SKIP_CHECKPOINT_CONFIRMATION": "Permite saltear una etapa a pesar del orden de confirmación requerido de cada etapa",
          "ROLE_CREATE_SIMILAR": "Le permite crear un pedido similar (una copia del pedido).",
          "ROLE_CREATE_SIMILAR_MULTIPLE": "Crear similar: la posibilidad de definir la cantidad de copias del pedido",
          "ROLE_CREATE_WORKER": "Posibilidad de crear un trabajador en la subpestaña “Lista de trabajadores”",
          "ROLE_DELETE_WORKER": "Posibilidad de eliminar un trabajador en la subpestaña “Lista de trabajadores”",
          "ROLE_EDIT_TRANSPORT_ADDITIONAL_DESCRIPTION": "Activa la posibilidad de editar el campo “Notas adicionales”",
          "ROLE_EDIT_WAREHOUSE_LOCATION": "Activa la posibilidad de editar el campo “Ubicación” en los detalles del pedido. También requiere el permiso “Acceso a tipo de transporte”",
          "ROLE_EDIT_WEIGHT": "Edición del peso en la sección “Pesaje”",
          "ROLE_EDIT_WORKER": "Posibilidad de editar un trabajador en la subpestaña “Lista de trabajadores”",
          "ROLE_EDIT_WORKER_FIELD": "Con el permiso, puede editar el campo “Trabajadores” en el formulario de creación de transporte",
          "ROLE_HIDE_TRANSPORTS_WITHOUT_ACTIVE_WAREHOUSE_STATUSES": "The permission hides transports that do not have active statuses with the user's warehouse",
          "SWAP_ADVICE_WINDOWS": "Permite intercambiar el lugar de franjas horarias de avisos de envío.",
          "UPDATE_ADVICE": "Le permite editar datos de notificaciones.",
          "UPDATE_CARRIER_COMPANY_FIELD": "Activa la posibilidad de editar el campo “Empresa transportista” en los detalles del pedido. También requiere el permiso “Acceso a tipo de transporte”",
          "UPDATE_CUSTOM_FIELD": "Brinda la posibilidad de editar un campo personalizado en el formulario de transporte",
          "UPDATE_MAIN_DATA_SECTION": "Activa la posibilidad de editar transporte. Se aplica a todos los campos, excepto los datos de avisos de envío, los campos personalizados y el campo “Notas”. También requiere el permiso “Acceso a tipo de transporte”.",
          "UPDATE_SERVICE_COMPANY_FIELD": "Activa la posibilidad de editar el campo “Servicio” en los detalles del pedido. También requiere el permiso “Acceso a tipo de transporte”",
          "chooseActionType": "Elija un nivel de autoridad"
        },
        "addPermission": "Agregar permiso",
        "authorityLabels": {
          "ACCEPT_AND_REJECT_TRANSPORT": "Aceptar/Rechazar el transporte que se ha derivado",
          "ACCESS_CARRIER_COMPANY_FIELD": "Visibilidad del campo “Empresa transportista”",
          "ACCESS_CUSTOM_FIELD": "Visibilidad de campos personalizados",
          "ACCESS_DESCRIPTION_FIELD": "Visibilidad del campo “Nota”",
          "ACCESS_INDEX": "Acceso a la sección Índices",
          "ACCESS_PACKAGES": "Visibilidad de sección de paquetes",
          "ACCESS_SERVICE_COMPANY_FIELD": "Visibilidad del campo “Servicio”",
          "ACCESS_SUPPLIER_COMPANY_FIELD": "Visibilidad del campo “Proveedor/Destinatario”",
          "ACCESS_TO_TRANSPORT_DETAILS": "Acceso a detalles de transporte",
          "ACCESS_TRANSPORT": "Acceso a un tipo de transporte",
          "ADD_ADVICE": "Agregar datos de un aviso de envío",
          "ADD_DOCUMENTS": "Agregar documentos",
          "ADD_LOAD_STEP_FROM_LOAD": "Agregar un paso de carga tras un paso de carga.",
          "ADD_LOAD_STEP_FROM_UNLOAD": "Agregar un paso de carga tras un paso de descarga.",
          "ADD_NOTES": "Agregar notas en la tabla",
          "ADD_PACKAGES": "Agregar un paquete",
          "ADD_TIME_WINDOW": "Agregar ventana de tiempo",
          "ADD_UNLOAD_STEP_FROM_LOAD": "Agregar un paso de descarga tras un paso de carga.",
          "ADD_UNLOAD_STEP_FROM_UNLOAD": "Agregar un paso de descarga tras un paso de descarga.",
          "BID_AUCTION": "Ofertar en una subasta",
          "CAN_DELETE_APPLIES_FILES": "Eliminar documentos de la postulación'de la empresa transportista",
          "CAN_DELETE_MOT_FILES": "Eliminar documentos del modo de transporte",
          "CREATE_AUCTION": "Crear una subasta",
          "CREATE_TRANSPORT": "Crear un transporte",
          "DELETE_DOCUMENTS": "Eliminar documentos",
          "DELETE_INDEX": "Eliminar un índice",
          "DELETE_LOAD_STEPS": "Eliminar un paso de carga",
          "DELETE_PACKAGES": "Eliminar paquete",
          "DELETE_TRANSPORT": "Eliminar un transporte",
          "DELETE_UNLOAD_STEPS": "Eliminar un paso de descarga",
          "DISABLE_DATA_FIELDS": "Ocultar datos en un formulario de transporte",
          "EDIT_COURIER_INVOICE_SECTION": "Activa la opción de editar la sección “Factura de artículos”",
          "EDIT_DESCRIPTION_FIELD": "Activa la posibilidad de editar el campo “Nota”",
          "EDIT_INDEX": "Actualizar un índice",
          "EDIT_PACKAGES": "Editar paquete",
          "EDIT_TRANSACTION_FREIGHT": "Editar flete",
          "MOVE_ADVICE_WINDOW": "Mover una franja horaria de aviso de envío",
          "MOVE_LOAD_STEPS": "Mover pasos de carga",
          "MOVE_UNLOAD_STEPS": "Mover pasos de descarga",
          "PASS_COURIER": "Derivar el transporte al servicio de mensajería",
          "PASS_TRANSPORT": "Derivar un transporte",
          "RESIZE_ADVICE_WINDOW": "Cambiar el tamaño de una franja horaria de aviso de envío",
          "ROLE_ACCESS_ALL_DEDICATED_WINDOWS": "La posibilidad de crear un aviso de envío en las franjas horarias de cualquier empresa",
          "ROLE_ACCESS_ALL_QUEUES": "Acceso a todas las colas",
          "ROLE_ACCESS_ALL_RAMPS": "Acceso a todas las rampas/franjas horarias del cronograma",
          "ROLE_ACCESS_ALL_USER_GROUP_DEDICATED_WINDOWS": "La posibilidad de crear un aviso de envío en las franjas horarias de cualquier departamento",
          "ROLE_ACCESS_API": "Acceso a la API REST",
          "ROLE_ACCESS_COURIER_INVOICE_SECTION": "Define la visibilidad de la sección “Factura de artículos”",
          "ROLE_ACCESS_PALLETS_SUMMARY": "Acceso a datos de límites de paletas",
          "ROLE_ACCESS_PRODUCT": "Acceso a detalles de productos",
          "ROLE_ACCESS_QUEUES_ACCESSIBILITY": "Visibilidad de acceso a patios y colas",
          "ROLE_ACCESS_TASK_DATA": "Acceso a datos de tareas",
          "ROLE_ACCESS_TO_APPLY_HISTORY": "Acceso al historial de postulaciones'de la empresa transportista",
          "ROLE_ACCESS_TO_NUMBER_OF_WINDOWS": "Muestra el contador de franjas horarias en la pestaña Cronograma",
          "ROLE_ACCESS_TO_WAREHOUSE_TEMPLATES": "Acceso a plantillas de cronograma ",
          "ROLE_ACCESS_TRANSPORT_TYPE_DATA": "Acceso a datos de tipos de transporte",
          "ROLE_ACCESS_TRUCKS": "Acceso a lista de camiones",
          "ROLE_ACCESS_USERS_ACTIVITY": "Acceso a historial de la actividad de usuarios",
          "ROLE_ACCESS_WAREHOUSE_DATA": "Acceso a datos de depósitos",
          "ROLE_ACCESS_WEIGHT_SECTION": "Visibilidad de la sección Pesaje",
          "ROLE_ACCESS_WORKER_FIELD": "Visibilidad del campo “Trabajadores”",
          "ROLE_ACCESS_WORKFLOW_DATA": "Acceso a datos de flujos de trabajo",
          "ROLE_ADD_CONTRACTOR_GROUP": "Agregar un grupo para un contratista",
          "ROLE_ADD_NEW_ADDRESS_IN_COMPANY_DETAILS": "Agregar nueva dirección en la sección de detalles de empresas",
          "ROLE_ADD_WINDOW_NOTES": "Agregar una nota a una franja horaria gris (bloqueada)",
          "ROLE_ADVICE_EXCEL": "Descargar pedidos en un archivo de Excel",
          "ROLE_ALLOW_CONCURRENT_SESSIONS": "Permite sesión simultánea para un usuario",
          "ROLE_ANNOUNCEMENTS_TAB": "Acceso a la pestaña Anuncios",
          "ROLE_API_MESSAGE_RESENDING": "Volver a enviar mensajes de la API",
          "ROLE_APPROVE_MOT": "Aprobación del modo de transporte",
          "ROLE_ARCHIVE_TAB": "Acceso a la pestaña “Archivo”",
          "ROLE_AUTOMATIC_PASS_TRANSPORT_TO_ROUTE_OFFERS": "Transferencia automática de transporte",
          "ROLE_BLOCK_RAMPS": "Creación de un bloqueo en un cronograma",
          "ROLE_CAN_ACCESS_COMPANY_TRANSPORT_LIMIT": "Visibilidad de los campos de límite de transportes para la empresa",
          "ROLE_CAN_ACCESS_COURIERS_SECTION": "Acceso a la sección Servicios de mensajería",
          "ROLE_CAN_ACCESS_TASK": "Visibilidad de tareas",
          "ROLE_CAN_ACCESS_TRANSPORT_SUPERVISOR": "Visibilidad del campo “Persona de contacto”",
          "ROLE_CAN_ADD_CONFIRMATION_DATE_TASK": "Confirmación de fechas de tareas",
          "ROLE_CAN_ADD_HAS_TO_WAIT_FOR_RELATION": "Agregar un enlace entre transportes",
          "ROLE_CAN_ADD_INVOICE_IN_TRANSACTION": "Agregar datos de facturas",
          "ROLE_CAN_ADD_MOT": "Agregar un modo de transporte",
          "ROLE_CAN_ADD_ROUTE": "Agregar una ruta a la lista de precios",
          "ROLE_CAN_ADD_TRANSPORT_COMBINING_RELATION": "Agregar una vinculación para combinar transportes",
          "ROLE_CAN_AUTOMATICALLY_CONFIRM_PREVIOUS_CHECKPOINTS": "Confirmación automática de todos los pasos anteriores",
          "ROLE_CAN_BLOCK_TASK": "Bloqueo de confirmación de tareas",
          "ROLE_CAN_CHOOSE_MORE_THAN_ONE_LOCATION": "Selección de más de 1 valor en el campo “Ubicación”",
          "ROLE_CAN_CONFIRM_ROUTE_OFFER": "Aprobación de la oferta en la lista de precios",
          "ROLE_CAN_CONFIRM_TASK": "Confirmación de tareas",
          "ROLE_CAN_CREATE_DELIVERY": "Activa la posibilidad de crear envíos en la pestaña “Planificación”",
          "ROLE_CAN_CREATE_MAX_PALLET": "Posibilidad de configurar un límite de paletas",
          "ROLE_CAN_CREATE_ROUTE_OFFER": "Agregar una oferta en la lista de precios",
          "ROLE_CAN_CREATE_TRANSPORT_FROM_SCHEDULE": "Creación de un transporte directamente desde el cronograma",
          "ROLE_CAN_DELETE_DELIVERY": "Activa la eliminación de entregas en la pestaña “Planificación”",
          "ROLE_CAN_DELETE_RELATION": "Eliminar la vinculación de transportes",
          "ROLE_CAN_DELETE_ROUTE": "Eliminación de rutas de las listas de precios",
          "ROLE_CAN_DELETE_ROUTE_OFFER": "Eliminación de una oferta de la lista de precios",
          "ROLE_CAN_DOWNLOAD_ENTRY_PASS": "Activa la descarga del pase de entrada",
          "ROLE_CAN_EDIT_ADDRESSES": "Editar direcciones",
          "ROLE_CAN_EDIT_COMPANY_TRANSPORT_LIMIT": "Editar el campo con el límite de transportes para la empresa",
          "ROLE_CAN_EDIT_COURIERS_SECTION": "Edición de campos en la sección Servicios de mensajería",
          "ROLE_CAN_EDIT_DELIVERY": "Activa la posibilidad de editar envíos en la pestaña “Planificación”",
          "ROLE_CAN_EDIT_INVOICE_IN_TRANSACTION": "Editar datos de facturas de la API",
          "ROLE_CAN_EDIT_READY_TO_PLAN": "Edición del indicador “Preparado para planificar” en el formulario de transporte",
          "ROLE_CAN_EDIT_ROUTE": "Edición de rutas en las listas de precios",
          "ROLE_CAN_EDIT_ROUTE_OFFER": "Edición de una oferta en la lista de precios",
          "ROLE_CAN_EDIT_TRANSPORT_SUPERVISOR": "Activa la edición del campo “Persona de contacto”",
          "ROLE_CAN_EXCEED_MAX_PALLET": "Enviar avisos de envío al superar el límite de paletas",
          "ROLE_CAN_EXCEED_MAX_PALLET_ON_DEDICATED_WINDOWS": "Avisar cuando se supera el límite de paletas en ventanas exclusivas",
          "ROLE_CAN_GENERATE_FULL_SETTLEMENT_REPORT": "Descargar informe de facturación en un archivo de Excel",
          "ROLE_CAN_GENERATE_LIST_OF_POINTS_REPORT": "Descarga de la lista de puntos en un archivo de Excel.",
          "ROLE_CAN_GENERATE_SHORT_SETTLEMENT_REPORT": "Generación de un informe de avisos de envío",
          "ROLE_CAN_MOVE_WINDOW_OUT_OF_ASSORTMENT_GROUP": "Le permite mover una franja horaria fuera de un grupo de surtidos",
          "ROLE_CAN_PLAN_ADVICE_IN_THE_PAST": "Programación de un aviso de envío en el pasado",
          "ROLE_CAN_REJECT_TASK": "Rechazo de tareas",
          "ROLE_CAN_REVOKE_TASK": "Revocación de tareas",
          "ROLE_CAN_SEE_CURRENT_PLACE_IN_AUCTION": "Visibilidad del lugar que se ocupa actualmente en una subasta en curso",
          "ROLE_CAN_SEND_EMAIL_ADVICE": "Aviso de envío por correo electrónico: envío de una solicitud de datos de avisos de envío",
          "ROLE_CAN_SEND_EMAIL_ADVICE_CF": "Aviso de envío por correo electrónico: envío de una solicitud para completar los datos",
          "ROLE_CAN_SKIP_CHECKPOINT_CONFIRMATION": "Omitir confirmación de etapa",
          "ROLE_CAN_UPDATE_VEHICLE_MAIN_DATA_SECTION": "Editar los datos principales del medio de transporte",
          "ROLE_CARRIERS_TAB": "Acceso a la pestaña “Empresas transportistas”",
          "ROLE_CHANGE_WAREHOUSE": "Seleccionar un depósito en un cronograma",
          "ROLE_CLASSIFICATION_TAB": "Acceso a la pestaña “Clasificación”",
          "ROLE_COMPANIES_TAB": "Acceso a la pestaña “Empresas”",
          "ROLE_CREATE_ASSORTMENT_GROUP": "Crear un grupo de surtidos",
          "ROLE_CREATE_BLOCKED_WINDOWS": "Crear franjas bloqueadas",
          "ROLE_CREATE_COMPANY": "Crear una empresa nueva",
          "ROLE_CREATE_CUSTOM_FIELD_DEFINITIONS": "Acceso a la configuración de campos personalizados",
          "ROLE_CREATE_DISCREPANCY_PROTOCOL": "Generación del registro de discrepancias",
          "ROLE_CREATE_DRIVER": "Crear un conductor nuevo",
          "ROLE_CREATE_GUARD_ROLE_GROUP": "Creación de un rol de guardián",
          "ROLE_CREATE_PLACE": "Creación de lugares",
          "ROLE_CREATE_PRODUCT": "Crear un producto",
          "ROLE_CREATE_QUEUE": "Creación de colas",
          "ROLE_CREATE_RAMP": "Crear una rampa nueva",
          "ROLE_CREATE_ROLE_GROUP": "La posibilidad de crear un grupo de roles nuevo",
          "ROLE_CREATE_SIMILAR": "Crear un transporte similar",
          "ROLE_CREATE_SIMILAR_MULTIPLE": "Crear similar: la posibilidad de definir la cantidad de copias del pedido",
          "ROLE_CREATE_SQUARE": "Creación de patios",
          "ROLE_CREATE_TRUCK": "Crear un camión nuevo",
          "ROLE_CREATE_USED_FOR_DELIVERIRES_ROLE_GROUP": "Creación de un rol de planificación de entregas",
          "ROLE_CREATE_USER": "Crear un usuario nuevo",
          "ROLE_CREATE_WAREHOUSE": "Crear un depósito nuevo",
          "ROLE_CREATE_WAREHOUSE_ZONE": "Crear una zona de depósito",
          "ROLE_DELETE_COMPANY": "Desactivar una empresa",
          "ROLE_DELETE_DRIVER": "Eliminar un conductor",
          "ROLE_DELETE_INVITATION": "Eliminación de invitación activa",
          "ROLE_DELETE_MULTIPLE_PERIODIC_TRANSPORTS": "Eliminación de varios avisos de envío periódicos",
          "ROLE_DELETE_PLACE": "Eliminación del lugar",
          "ROLE_DELETE_PRODUCT": "Eliminar un producto",
          "ROLE_DELETE_QUEUE": "Eliminación de la cola",
          "ROLE_DELETE_RAMP": "Eliminar una rampa",
          "ROLE_DELETE_SQUARE": "Eliminación de la cuadra",
          "ROLE_DELETE_TRUCK": "Eliminar un vehículo",
          "ROLE_DELETE_USER": "Eliminar un usuario",
          "ROLE_DELETE_WAREHOUSE": "Eliminar un depósito",
          "ROLE_DEV_TOOLS": "Herramientas de desarrollo adicionales",
          "ROLE_DISABLE_USER": "Desactivar un usuario",
          "ROLE_EDIT_ALL_USERS": "Edición de todos los usuarios",
          "ROLE_EDIT_ASSORTMENT_GROUP": "Editar un grupo de surtidos",
          "ROLE_EDIT_COMPANY": "Editar campos seleccionados de una empresa",
          "ROLE_EDIT_CONTRACTOR_GROUP": "Editar grupo de contratistas",
          "ROLE_EDIT_GUS_DATA": "Edición de datos descargados desde la Oficina Central de Estadística",
          "ROLE_EDIT_INCOMPLETE_LOADING": "Gestión de la casilla de verificación de “Carga incompleta”.",
          "ROLE_EDIT_MULTIPLE_PERIODIC_TRANSPORTS": "Edición de varios avisos de envío periódicos",
          "ROLE_EDIT_MY_NOTIFICATION": "Edición de configuración de notificaciones propias",
          "ROLE_EDIT_MY_QUEUES": "Edición de acceso a colas y cuadras propias",
          "ROLE_EDIT_MY_RAMP": "Edición de acceso a rampas propias",
          "ROLE_EDIT_PLACE": "Editar lugar",
          "ROLE_EDIT_PRODUCT": "Actualizar un producto",
          "ROLE_EDIT_QUEUE": "Edición de colas",
          "ROLE_EDIT_RAMP": "Editar una rampa",
          "ROLE_EDIT_ROLE_GROUP": "Cambio de rol de usuario",
          "ROLE_EDIT_SQUARE": "Edición de patios",
          "ROLE_EDIT_USERS_MAIN_DATA": "Edición de datos maestros del usuario",
          "ROLE_EDIT_USERS_NOTIFICATION": "Edición de notificaciones",
          "ROLE_EDIT_USERS_RAMP": "Edición de acceso de usuarios a rampas",
          "ROLE_EDIT_WAREHOUSE": "Editar un depósito",
          "ROLE_EDIT_WAREHOUSE_LOCATION": "Editar el campo de ubicación del depósito",
          "ROLE_EDIT_WAREHOUSE_ZONE": "Editar una zona de depósito",
          "ROLE_EDIT_WEIGHT": "Editar el peso en la sección “Pesaje”",
          "ROLE_EDIT_WORKER_FIELD": "Editar el campo “Trabajadores”",
          "ROLE_EDIT_WORKFLOW": "Editar la configuración de estados",
          "ROLE_FILTER_ACTIVE_TRANSPORTS_TABLE": "La visibilidad de filtros por columnas en la tabla de pedidos activos",
          "ROLE_FULL_EDIT_COMPANY": "Editar todos los campos de una empresa",
          "ROLE_HIDE_TRANSPORTS_WITHOUT_ACTIVE_WAREHOUSE_STATUSES": "Ocultar transportes tras confirmar el último estado en un depósito disponible",
          "ROLE_INVITATIONS_TAB": "Acceso a la pestaña “Invitaciones”",
          "ROLE_INVITE_AS_TEMPORARY_USER_WHEN_ACCOUNT_LIMIT_EXCEEDED": "Invitación a usuarios temporales, que excede el límite de la empresa",
          "ROLE_INVOICE_COLUMN": "Mostrar la columna de facturas",
          "ROLE_MANAGE_ADMIN_ANNOUNCEMENTS": "Gestión de anuncios administrativos",
          "ROLE_MANAGE_ADVICE_VERIFICATION_MAIL": "Gestionar una notificación por correo electrónico donde se solicita la verificación de los datos",
          "ROLE_MANAGE_ALLOWED_DOMAINS": "Acceso a gestionar los dominios de correo electrónico permitidos para la empresa",
          "ROLE_MANAGE_ANNOUNCEMENTS": "La posibilidad de gestionar anuncios",
          "ROLE_MANAGE_APPLIES_SETTINGS": "Acceso a gestión de configuración de postulaciones",
          "ROLE_MANAGE_APPLY_SECTIONS": "Gestión de las postulaciones' de las empresas transportistas",
          "ROLE_MANAGE_ARTR_SETTINGS": "Gestión de configuración de ANPR",
          "ROLE_MANAGE_AUCTION_SETTINGS": "Acceso a gestión de configuración de subastas",
          "ROLE_MANAGE_AUTHORITIES": "La posibilidad de gestionar permisos para un rol",
          "ROLE_MANAGE_CMR_SETTINGS": "Gestión de configuración de archivos CMR",
          "ROLE_MANAGE_COLUMN_VISIBILITY": "Gestionar visibilidad de columnas",
          "ROLE_MANAGE_COMPANY_PRICING_PLAN": "Editar la opción de suscripción para la empresa",
          "ROLE_MANAGE_COMPANY_RAMP": "Acceso a la modificación de una rampa para la empresa",
          "ROLE_MANAGE_DEDICATED_WINDOWS": "Gestionar franjas exclusivas",
          "ROLE_MANAGE_LOCAL_ADMINS": "Gestión de administradores locales en empresas contratistas",
          "ROLE_MANAGE_MAX_COMPANY_ACCOUNT_NUMBER": "Editar la cantidad máxima de cuentas para la empresa",
          "ROLE_MANAGE_MOT_SECTIONS": "Gestión de modos de transporte",
          "ROLE_MANAGE_NOTIFICATIONS": "Gestionar notificaciones",
          "ROLE_MANAGE_PACKAGE_TYPE_TRANSPORT_TYPE": "Gestionar configuración de paquetes",
          "ROLE_MANAGE_PARTNERSHIP": "Gestionar conexiones de sociedades",
          "ROLE_MANAGE_PRINCIPAL_DEDICATED_WINDOWS": "Creación de ventanas exclusivas para la empresa de los clientes",
          "ROLE_MANAGE_QUEUES_ACCESSIBILITY": "Gestión de acceso a patios y colas",
          "ROLE_MANAGE_SETTINGS": "Gestión de configuraciones",
          "ROLE_MANAGE_TRANSPORT_TYPE": "Gestionar la configuración de tipo de transporte",
          "ROLE_MANAGE_USER_GROUPS": "Gestión de grupos dentro de la empresa'del contratista",
          "ROLE_MANAGE_USER_ROLE_INVITATIONS": "Gestionar las invitaciones a roles de usuarios",
          "ROLE_MODE_OF_TRANSPORTATION_TAB": "Acceso a la pestaña “Modo de transporte”",
          "ROLE_MOVE_BLOCKED_WINDOWS": "Mover franjas bloqueadas",
          "ROLE_MOVE_DEDICATED_WINDOWS": "Mover franjas exclusivas",
          "ROLE_MOVE_WINDOWS_BETWEEN_WAREHOUSE_ZONES": "Mover franjas horarias entre zonas de depósitos",
          "ROLE_OWN_COLUMNS_EXCEL": "Generar un informe basado en columnas de una tabla",
          "ROLE_OWN_COMPANY_TAB": "Acceso a la pestaña “Empresa” en la cuenta del contratista",
          "ROLE_PERFORM_ACTION_IN_WAREHOUSE": "Acciones en un depósito",
          "ROLE_PLANNING_TAB": "Acceso a la pestaña Planificación",
          "ROLE_PRICE_LIST_TAB": "Acceso a la pestaña “Listas de precios”",
          "ROLE_PRODUCTS_TAB": "Acceso a la lista de productos",
          "ROLE_QUEUES_TAB": "Acceso a la pestaña “Colas”",
          "ROLE_QUEUE_STATUS_TAB": "Acceso a la pestaña “Estado de cola”",
          "ROLE_READ_WINDOW_NOTES": "Visibilidad de notas en una franja horaria gris (bloqueada)",
          "ROLE_REJECT_CHECKPOINT": "Rechazo de punto de control",
          "ROLE_RESIZE_BLOCKED_WINDOWS": "Cambiar tamaño de franjas bloqueadas",
          "ROLE_RESIZE_DEDICATED_WINDOWS": "Cambiar tamaño de franjas exclusivas",
          "ROLE_RESTORE_ORDER_FROM_ARCHIVE": "La posibilidad de restaurar pedidos desde el archivo",
          "ROLE_REVOKE_CHECKPOINT": "Revocar un punto de control",
          "ROLE_SEND_SMS_TO_ALL_DRIVERS_IN_QUEUE": "La posibilidad de enviar notificaciones por mensaje de texto a todos los conductores de una cola",
          "ROLE_SETTINGS_TAB": "Acceso a la pestaña “Configuración”",
          "ROLE_SHOW_API_INTEGRATION_LOGS": "Acceso a la sección de integración",
          "ROLE_SHOW_AWAITING_INVITATIONS_TABLE": "Mostrar la lista de invitaciones que aguardan respuesta",
          "ROLE_SHOW_CUSTOM_FIELD_DEFINITIONS": "Gestión de campos personalizados",
          "ROLE_SHOW_PARTNERSHIP_TABLE": "Mostrar la lista de sociedades",
          "ROLE_SHOW_TRANSPORT_LOGS": "Acceso al historial de pedidos",
          "ROLE_SORT_TRANSPORT_TABLE": "Ordenar la tabla de transportes",
          "ROLE_STATISTICS_REPORT_TAB": "Acceso a la pestaña “Estadísticas”",
          "ROLE_SUPER_USER": "Permisos de usuarios especiales",
          "ROLE_TRANSPORT_TABLE": "Acceso a la tabla de transportes",
          "ROLE_USERS_TAB": "Acceso a la pestaña “Usuarios”",
          "ROLE_WAREHOUSE_TAB": "Acceso a la pestaña “Cronograma”",
          "SWAP_ADVICE_WINDOWS": "Intercambiar el lugar de franjas horarias de avisos de envío",
          "UNLIMITED_ACCESS_TO_ARCHIVED_TRANSPORTS": "Acceso ilimitado a pedidos en el archivo",
          "UPDATE_ADVICE": "Actualizar un aviso de envío",
          "UPDATE_CARRIER_COMPANY_FIELD": "Actualizar el campo “Empresa transportista”",
          "UPDATE_CUSTOM_FIELD": "Actualizar un campo personalizado",
          "UPDATE_MAIN_DATA_SECTION": "Actualizar campos principales de transporte",
          "UPDATE_SERVICE_COMPANY_FIELD": "Actualizar el campo “Servicio”"
        },
        "authorityRangeLabel": "Intervalo de autoridad",
        "authorityRangeLabels": {
          "ACTIVE": "Si el pedido aún no se ha archivado.",
          "ACTIVE_AUCTION_BASED": "Depende de la subasta activa asignada al pedido.",
          "ADVICE_RESTRICTION_BASED": "Durante un intervalo de tiempo especificado para un aviso de envío.",
          "AFTER_CHECKPOINT_BASED": "Después de que el punto de control esté confirmado.",
          "BEFORE_CHECKPOINT_BASED": "Hasta que el punto de control esté confirmado.",
          "BLOCKED": "Acción bloqueada.",
          "NO_ROUTE_OFFER": "Depende de que la empresa transportista acepte el transporte a la tarifa de la lista de precios.",
          "OWN_COMPANY_BASED": "Depende de la empresa del creador de este pedido.",
          "TIME_BASED": "Depende del tiempo",
          "UNLIMITED": "Sin condiciones adicionales"
        },
        "authorityRangePhrases": {
          "ACTIVE": "hasta que el pedido se haya archivado.",
          "ACTIVE_AUCTION_BASED": "si el transporte no tiene una subasta activa.",
          "ADVICE_RESTRICTION_BASED": "durante un intervalo de tiempo especificado para un aviso de envío.",
          "AFTER_CHECKPOINT_BASED": "después de que el punto de control {checkpointName} esté confirmado.",
          "BEFORE_CHECKPOINT_BASED": "hasta que el punto de control {checkpointName} esté confirmado.",
          "BLOCKED": "acción bloqueada.",
          "CHECKPOINT_BASED": "basado en el punto de control {checkpointName}.",
          "NO_ROUTE_OFFER": "ante la ausencia de ofertas preferidas o cuando empresas transportistas con ofertas válidas en la lista de precios no hayan aceptado el transporte para la ejecución.",
          "OWN_COMPANY_BASED": "dentro de la empresa del creador.",
          "TIME_BASED": "no después de {timeInterval} {afterOrBefore} {timeReference}.",
          "TIME_BASED_CURRENT_DATE_AFTER": "{timeInterval} después de la fecha actual.",
          "TIME_BASED_CURRENT_DATE_BEFORE": "hasta {timeInterval} a partir de la fecha actual.",
          "UNLIMITED": "sin condiciones adicionales."
        },
        "checkpoint": "Estado",
        "conditions": "Condiciones",
        "create": {
          "copyFrom": "Copiar permisos de otro rol:",
          "info": "Los permisos se pueden modificar tras guardar el rol",
          "isGuard": "Rol de guardián",
          "isGuardInfo": "Rol con confirmación simplificada de puntos de control",
          "label": "Agregar un rol",
          "nameEn": "Nombre: en inglés",
          "namePl": "Nombre",
          "noPartnershipInfo": "Si no hay ninguna sociedad, se creará un rol de empresa principal",
          "partnershipType": "Tipo de sociedad:",
          "usedForDeliveries": "Rol de planificación de entregas",
          "usedForDeliveriesInfo": "Rol para planificar las entregas en la pestaña Planificación"
        },
        "customFieldDefinition": "Campo personalizado",
        "dateAfterPhrase": "después de la fecha que especifica",
        "dateBeforePhrase": "antes de la fecha que especifica",
        "deleteAllAuthoritiesTitle": "Botón para eliminar todos los permisos de {title}",
        "generalAuthorityDescriptions": {
          "ACCESS_TO_TRANSPORT_DETAILS": "Activa el acceso a los detalles de pedidos",
          "ADVICE_ONLY_ON_DEDICATED_WINDOWS": "El derecho restringe la posibilidad de agregar una ventana de tiempo solo en lugar de una ventana exclusiva disponible para el usuario que agrega la ventana",
          "CAN_DELETE_APPLIES_FILES": "La posibilidad de eliminar documentos que se adjuntaron a la postulación'de la empresa transportista.",
          "CAN_DELETE_MOT_FILES": "Le permite eliminar documentos que se adjuntaron a un modo de transporte.",
          "ROLE_ACCESS_ALL_DEDICATED_WINDOWS": "Le permite crear un aviso de envío en las franjas horarias exclusivas de cualquier empresa",
          "ROLE_ACCESS_ALL_QUEUES": "Acceso a todas las colas.",
          "ROLE_ACCESS_ALL_RAMPS": "Acceso a todas las rampas/franjas horarias del cronograma.",
          "ROLE_ACCESS_ALL_USER_GROUP_DEDICATED_WINDOWS": "Le permite crear un aviso de envío en las franjas horarias exclusivas de cualquier departamento",
          "ROLE_ACCESS_API": "Activa el acceso a métodos de la API REST para el usuario",
          "ROLE_ACCESS_AUCTION_DATA": "Visibilidad de datos de subastas",
          "ROLE_ACCESS_FREIGHT_DATA": "Visibilidad de tarifa de flete",
          "ROLE_ACCESS_PALLETS_SUMMARY": "Acceso a datos de límites de paletas",
          "ROLE_ACCESS_PRODUCT": "Agrega acceso a los detalles del producto.",
          "ROLE_ACCESS_QUEUES_ACCESSIBILITY": "Con el permiso, puede gestionar la visibilidad del acceso a patios y colas. Cuando este permiso está activado, la opción queda visible en los detalles del usuario.",
          "ROLE_ACCESS_TASK_DATA": "Acceso a datos de tareas",
          "ROLE_ACCESS_TO_APPLY_HISTORY": "Acceso al historial de postulaciones'de la empresa transportista.",
          "ROLE_ACCESS_TO_INSPECTION_BUTTON": "Visibilidad del botón de control en la tabla de transportes en la columna “Inspección”",
          "ROLE_ACCESS_TO_INSPECTION_PANEL": "Visibilidad del panel “Inspección”",
          "ROLE_ACCESS_TO_LOCATION": "Visibility of the 'Ubicación' field.",
          "ROLE_ACCESS_TO_NUMBER_OF_WINDOWS": "Muestra un contador de franjas horarias en el Cronograma",
          "ROLE_ACCESS_TO_WAREHOUSE_TEMPLATES": "Le permite gestionar plantillas de franjas horarias en el cronograma.",
          "ROLE_ACCESS_TRANSPORT_ADDITIONAL_DESCRIPTION": "Visibilidad del campo “Notas adicionales”",
          "ROLE_ACCESS_TRANSPORT_TYPE_DATA": "Acceso a datos de tipos de transporte",
          "ROLE_ACCESS_TRUCKS": "Acceso a lista de camiones",
          "ROLE_ACCESS_USERS_ACTIVITY": "Activa el acceso al historial de actividad de usuarios",
          "ROLE_ACCESS_WAREHOUSE_DATA": "Acceso a datos de depósitos",
          "ROLE_ACCESS_WORKFLOW_DATA": "Acceso a datos de flujos de trabajo",
          "ROLE_ADD_CONTRACTOR_GROUP": "Posibilidad de agregar un grupo nuevo para un contratista",
          "ROLE_ADD_NEW_ADDRESS_IN_COMPANY_DETAILS": "Permite al usuario agregar una dirección nueva en la sección de detalles de la empresa",
          "ROLE_ADD_WINDOW_NOTES": "Activa la posibilidad de agregar notas en franjas horarias grises.",
          "ROLE_ADVICE_EXCEL": "Le permite exportar datos de pedidos a un archivo de Excel.",
          "ROLE_ALLOW_CONCURRENT_SESSIONS": "Permite sesión simultánea para un usuario desde varios navegadores",
          "ROLE_ANNOUNCEMENTS_TAB": "Activa el acceso a la pestaña Anuncios",
          "ROLE_API_MESSAGE_RESENDING": "Posibilidad de volver a enviar el mensaje de la API",
          "ROLE_APPROVE_MOT": "Le permite aprobar un modo de transporte.",
          "ROLE_ARCHIVE_TAB": "Activa el acceso a la pestaña “Archivo”.",
          "ROLE_BLOCK_RAMPS": "La posibilidad de bloquear una franja horaria en el cronograma y de bloquear toda la rampa tras hacer clic en el encabezado de la rampa.",
          "ROLE_CAN_ACCESS_ARCHIVED_TRUCK_DOCUMENTS": "Activa el acceso a documentos archivados en los detalles de modo de transporte en la pestaña “Modo de transporte”.",
          "ROLE_CAN_ACCESS_COMPANY_TRANSPORT_LIMIT": "Activa la visibilidad de los campos relacionados con el límite de transportes para la empresa",
          "ROLE_CAN_ACCESS_COURIERS_SECTION": "Define el acceso a la sección “Servicios de mensajería” del formulario de transporte",
          "ROLE_CAN_ADD_HAS_TO_WAIT_FOR_RELATION": "Activa la posibilidad de agregar un enlace entre transportes",
          "ROLE_CAN_ADD_INVOICE_IN_TRANSACTION": "Le permite agregar datos de facturas en una transacción desde la tabla de transportes",
          "ROLE_CAN_ADD_MOT": "Le permite agregar un modo de transporte.",
          "ROLE_CAN_ADD_ROUTE": "Posibilidad de agregar una ruta nueva a la lista de precios",
          "ROLE_CAN_ADD_TRANSPORT_COMBINING_RELATION": "Activa la posibilidad de vincular transportes entre sí. Los transportes combinados tienen en común las notificaciones, las ventanas y los estados.",
          "ROLE_CAN_AUTOMATICALLY_CONFIRM_PREVIOUS_CHECKPOINTS": "Al confirmar el estado indicado, el usuario también confirma automáticamente todos los estados previos sin confirmar",
          "ROLE_CAN_CHOOSE_MORE_THAN_ONE_LOCATION": "Activa la selección de más de 1 valor en el campo “Ubicación” en los detalles de pedidos. También requiere el siguiente permiso: “Acceso a tipo de transporte”",
          "ROLE_CAN_CREATE_DELIVERY": "Activa la posibilidad de crear envíos en la pestaña “Planificación”",
          "ROLE_CAN_CREATE_MAX_PALLET": "La posibilidad de configurar el límite de paletas.",
          "ROLE_CAN_CREATE_ROUTE_OFFER": "Posibilidad de agregar una oferta nueva a la lista de precios",
          "ROLE_CAN_DELETE_DELIVERY": "Activa la eliminación de entregas en la pestaña “Planificación”",
          "ROLE_CAN_DELETE_RELATION": "Activa la posibilidad de desvincular transportes. Con el permiso, puede desconectar uno de los transportes.",
          "ROLE_CAN_DELETE_ROUTE": "Posibilidad de eliminar rutas en listas de precios",
          "ROLE_CAN_DELETE_ROUTE_OFFER": "Posibilidad de eliminar una oferta de la lista de precios",
          "ROLE_CAN_DOWNLOAD_ENTRY_PASS": "Descargar pase de entrada",
          "ROLE_CAN_DOWNLOAD_REGISTRY_OF_PERSONS_XLS": "Descarga de registros de personas en la planta",
          "ROLE_CAN_EDIT_ADDRESSES": "La posibilidad de editar y eliminar direcciones en la pestaña “Empresa”.",
          "ROLE_CAN_EDIT_COMPANY_TRANSPORT_LIMIT": "Activa la edición del campo con el límite de transportes para la empresa",
          "ROLE_CAN_EDIT_COURIERS_SECTION": "Le permite editar los campos de la sección “Servicios de mensajería” en el formulario de transporte. Requiere el permiso “Acceso a la sección Servicios de mensajería”.",
          "ROLE_CAN_EDIT_DELIVERY": "Activa la posibilidad de editar envíos en la pestaña “Planificación”",
          "ROLE_CAN_EDIT_INVOICE_IN_TRANSACTION": "Le permite editar datos de facturas en una transacción de transporte mediante la API pública",
          "ROLE_CAN_EDIT_READY_TO_PLAN": "Activa la posibilidad de editar el indicador “Preparado para planificar” en el formulario de transporte",
          "ROLE_CAN_EDIT_ROUTE": "Posibilidad de editar rutas en listas de precios",
          "ROLE_CAN_EDIT_ROUTE_OFFER": "Posibilidad de editar una oferta ya existente en la lista de precios",
          "ROLE_CAN_EDIT_TRANSPORT_PALLETS_WITHOUT_CHANGING_TIME_WINDOW_SIZE": "Le permite aumentar la cantidad de paquetes en el transporte incluso a pesar de la falta de espacio para extender la ventana de tiempo de acuerdo con el algoritmo.",
          "ROLE_CAN_EXCEED_MAX_PALLET": "La posibilidad de enviar avisos de envío al superar el límite de paletas.",
          "ROLE_CAN_EXCEED_MAX_PALLET_ON_DEDICATED_WINDOWS": "La posibilidad de enviar avisos de envío al superar el límite de paletas en el caso de ventanas de tiempo ubicadas totalmente en ventanas exclusivas",
          "ROLE_CAN_GENERATE_FULL_SETTLEMENT_REPORT": "Le permite exportar datos de informes de facturación a un archivo de Excel.",
          "ROLE_CAN_GENERATE_LIST_OF_POINTS_REPORT": "Con el permiso, puede exportar una lista de puntos a un archivo de Excel.",
          "ROLE_CAN_GENERATE_SHORT_SETTLEMENT_REPORT": "Permite generar un informe de avisos de envío en formato de archivo de Excel",
          "ROLE_CAN_MOVE_WINDOW_OUT_OF_ASSORTMENT_GROUP": "Le permite quitar la franja horaria de un grupo de surtidos",
          "ROLE_CAN_MULTI_ARCHIVE_TRANSPORTS": "Archivado grupal de transportes",
          "ROLE_CAN_PLAN_ADVICE_IN_THE_PAST": "Le permite programar un aviso de envío en el pasado",
          "ROLE_CAN_SCAN_BARCODES": "Escanear códigos de barras",
          "ROLE_CAN_SEE_CURRENT_PLACE_IN_AUCTION": "Visibilidad del lugar que se ocupa actualmente en una subasta en curso",
          "ROLE_CAN_SKIP_CHECKPOINT_CONFIRMATION": "Permite saltear una etapa a pesar del orden de confirmación requerido de cada etapa",
          "ROLE_CAN_UPDATE_VEHICLE_MAIN_DATA_SECTION": "Edición de los datos principales del medio de transporte",
          "ROLE_CARRIERS_TAB": "Activa el acceso a la pestaña “Empresas transportistas”.",
          "ROLE_CHANGE_WAREHOUSE": "La posibilidad de cambiar el depósito en el cronograma.",
          "ROLE_CLASSIFICATION_TAB": "Activa el acceso a la pestaña “Clasificación”",
          "ROLE_COMPANIES_TAB": "Activa el acceso a la pestaña “Empresas”.",
          "ROLE_CREATE_ASSORTMENT_GROUP": "La posibilidad de crear un grupo de surtidos nuevo.",
          "ROLE_CREATE_BLOCKED_WINDOWS": "Activa la posibilidad de crear franjas horarias grises en la vista de cronograma.",
          "ROLE_CREATE_COMPANY": "Con este permiso, puede crear una empresa nueva sin enviar una invitación.",
          "ROLE_CREATE_CUSTOM_FIELD_DEFINITIONS": "Este permiso le brinda acceso a la configuración de los campos personalizados.",
          "ROLE_CREATE_DISCREPANCY_PROTOCOL": "Le permite generar un registro de discrepancias",
          "ROLE_CREATE_DRIVER": "La posibilidad de agregar un conductor nuevo",
          "ROLE_CREATE_GUARD_ROLE_GROUP": "Posibilidad de crear un rol de guardián",
          "ROLE_CREATE_PLACE": "El rol le permite crear una nueva área de carga y descarga",
          "ROLE_CREATE_PRODUCT": "Activa la posibilidad de agregar un producto nuevo. También requiere el nivel de permiso “Acceso a productos”.",
          "ROLE_CREATE_QUEUE": "El rol le permite crear una cola nueva",
          "ROLE_CREATE_RAMP": "La posibilidad de agregar una rampa nueva en un depósito ya existente. Se requiere el rol “Editar un depósito”.",
          "ROLE_CREATE_ROLE_GROUP": "Le permite crear un grupo de roles nuevo",
          "ROLE_CREATE_SIMILAR": "La posibilidad de crear un pedido similar (copia del pedido).",
          "ROLE_CREATE_SIMILAR_MULTIPLE": "Activa la posibilidad de definir la cantidad de pedidos que se crearán",
          "ROLE_CREATE_SQUARE": "El rol le permite crear un patio nuevo",
          "ROLE_CREATE_TRUCK": "La posibilidad de agregar un vehículo nuevo",
          "ROLE_CREATE_USED_FOR_DELIVERIRES_ROLE_GROUP": "Posibilidad de crear el rol de creación de entregas",
          "ROLE_CREATE_USER": "Con este permiso, puede crear un usuario nuevo sin enviar una invitación.",
          "ROLE_CREATE_WAREHOUSE": "La posibilidad de agregar un depósito nuevo. Se requiere el rol “Editar un depósito”. ",
          "ROLE_CREATE_WAREHOUSE_ZONE": "La posibilidad de crear una zona de depósito nueva.",
          "ROLE_CREATE_WORKER": "Crear trabajador nuevo",
          "ROLE_DELETE_COMPANY": "La posibilidad de desactivar una empresa.",
          "ROLE_DELETE_DRIVER": "La posibilidad de eliminar un conductor. ",
          "ROLE_DELETE_INVITATION": "Le permite eliminar una invitación activa",
          "ROLE_DELETE_MULTIPLE_PERIODIC_TRANSPORTS": "Posibilidad de eliminar varios avisos de envío periódicos",
          "ROLE_DELETE_PLACE": "Permite eliminar el lugar",
          "ROLE_DELETE_PRODUCT": "Un usuario con este nivel de permiso puede eliminar un producto ya existente. También requiere el nivel de permiso “Acceso a productos”.",
          "ROLE_DELETE_QUEUE": "Permite eliminar la cola",
          "ROLE_DELETE_RAMP": "La posibilidad de eliminar una rampa. Se requiere el rol “Editar un depósito”.",
          "ROLE_DELETE_SQUARE": "Permite eliminar la cuadra",
          "ROLE_DELETE_TRUCK": "La posibilidad de eliminar un vehículo.",
          "ROLE_DELETE_USER": "Posibilidad de eliminar a un usuario. Una vez que se elimine a un usuario, este no tendrá acceso a la plataforma.",
          "ROLE_DELETE_WAREHOUSE": "La posibilidad de eliminar un depósito. Se requiere el rol “Editar un depósito”.",
          "ROLE_DELETE_WORKER": "Eliminar trabajador",
          "ROLE_DEV_TOOLS": "Herramientas de desarrollo adicionales",
          "ROLE_DISABLE_USER": "Posibilidad de desactivar al usuario. Requiere el permiso “Editar datos maestros del usuario”. En el caso de usuarios externos, además, se requiere el permiso “Editar todos los usuarios”.",
          "ROLE_EDIT_ALL_USERS": "Se pueden editar los usuarios externos. Requiere alguno de los permisos “Editar datos maestros del usuario”, “Editar acceso del usuario a rampas”, “Editar notificaciones del usuario”.",
          "ROLE_EDIT_ASSORTMENT_GROUP": "La posibilidad de editar un grupo de surtidos.",
          "ROLE_EDIT_COMPANY": "La posibilidad de editar'el nombre corto de la empresa del contratista, el número del contratista y los detalles adicionales.",
          "ROLE_EDIT_CONTRACTOR_GROUP": "Posibilidad de editar grupos de contratistas ya existentes",
          "ROLE_EDIT_GUS_DATA": "Posibilidad de editar datos de empresas, verificados por la Oficina Central de Estadística",
          "ROLE_EDIT_INCOMPLETE_LOADING": "La posibilidad de marcar/desmarcar la casilla de verificación “Carga incompleta”.",
          "ROLE_EDIT_MULTIPLE_PERIODIC_TRANSPORTS": "Posibilidad de editar varios avisos de envío periódicos",
          "ROLE_EDIT_MY_NOTIFICATION": "Edición de configuración de notificaciones propias",
          "ROLE_EDIT_MY_QUEUES": "Edición de acceso a colas y cuadras propias",
          "ROLE_EDIT_MY_RAMP": "Edición de acceso a rampas propias",
          "ROLE_EDIT_PLACE": "El rol le permite editar un área de carga y descarga",
          "ROLE_EDIT_PRODUCT": "Le permite editar un producto ya existente. También requiere el nivel de permiso “Acceso a productos”.",
          "ROLE_EDIT_QUEUE": "El rol le permite editar una cola",
          "ROLE_EDIT_RAMP": "Se puede editar la rampa.",
          "ROLE_EDIT_ROLE_GROUP": "Posibilidad de cambiar el rol en los detalles de usuarios.",
          "ROLE_EDIT_SQUARE": "El rol le permite editar un patio",
          "ROLE_EDIT_TRANSPORT_ADDITIONAL_DESCRIPTION": "Activa la posibilidad de editar el campo “Notas adicionales”",
          "ROLE_EDIT_USERS_MAIN_DATA": "Posibilidad de editar el nombre, la dirección de correo electrónico, el número de teléfono y la contraseña",
          "ROLE_EDIT_USERS_NOTIFICATION": "Permite al usuario editar las notificaciones recibidas",
          "ROLE_EDIT_USERS_RAMP": "Posibilidad de editar el acceso del usuario a rampas y depósitos",
          "ROLE_EDIT_WAREHOUSE": "La posibilidad de editar datos del depósito",
          "ROLE_EDIT_WAREHOUSE_ZONE": "La posibilidad de editar una zona de depósito.",
          "ROLE_EDIT_WORKER": "Editar trabajador",
          "ROLE_EDIT_WORKFLOW": "La posibilidad de editar la configuración del estado detallado para cada tipo de transporte.",
          "ROLE_FILTER_ACTIVE_TRANSPORTS_TABLE": "Muestra filtros arriba de las columnas en la tabla de transportes activos",
          "ROLE_FULL_EDIT_COMPANY": "La posibilidad de editar'los datos de la empresa del contratista.",
          "ROLE_INVITATIONS_TAB": "Activa el acceso a la pestaña “Invitaciones”.",
          "ROLE_INVITE_AS_TEMPORARY_USER_WHEN_ACCOUNT_LIMIT_EXCEEDED": "Invitación a usuarios temporales, que excede el límite de la empresa",
          "ROLE_INVOICE_COLUMN": "Mostrar la columna de facturas",
          "ROLE_MANAGE_ADMIN_ANNOUNCEMENTS": "Posibilidad de gestionar anuncios administrativos. Se requieren los permisos “Acceso a la pestaña Anuncios” y “La posibilidad de gestionar anuncios”",
          "ROLE_MANAGE_ADVICE_VERIFICATION_MAIL": "Con el permiso, puede activar/desactivar la notificación por correo electrónico con una solicitud para verificar los datos de avisos de envío y puede cambiar la hora de envío del correo electrónico. Tras activarse el permiso, la opción queda visible en los detalles del contratista.",
          "ROLE_MANAGE_ALLOWED_DOMAINS": "Acceso y gestión de dominios de correo electrónico permitidos en la sección “Dominios permitidos” en los detalles'de contratistas.",
          "ROLE_MANAGE_ANNOUNCEMENTS": "Activa el acceso a la gestión de anuncios. Se requiere el nivel de permiso “Acceso a la pestaña Anuncios”.",
          "ROLE_MANAGE_APPLIES_SETTINGS": "Acceso a gestión de configuración de postulaciones",
          "ROLE_MANAGE_APPLY_SECTIONS": "Gestión de las postulaciones'de las empresas transportistas. La posibilidad de aceptar y rechazar secciones individuales de la postulación'de la empresa transportista.",
          "ROLE_MANAGE_ARTR_SETTINGS": "Gestión de configuración de ANPR",
          "ROLE_MANAGE_AUCTION_SETTINGS": "Acceso a gestión de configuración de subastas",
          "ROLE_MANAGE_AUTHORITIES": "Le permite crear, editar y eliminar permisos para roles (permisos generales y permisos relacionados con los tipos de transporte)",
          "ROLE_MANAGE_CMR_SETTINGS": "Gestión de configuración de archivos CMR",
          "ROLE_MANAGE_COLUMN_VISIBILITY": "Le permite gestionar la visibilidad de las columnas en la vista de permisos según el tipo de transporte",
          "ROLE_MANAGE_COMPANY_PRICING_PLAN": "Con el permiso, puede cambiar la opción de suscripción para la empresa",
          "ROLE_MANAGE_COMPANY_RAMP": "Activa el acceso a la sección “Edición de rampas” en los detalles de contratistas",
          "ROLE_MANAGE_DEDICATED_WINDOWS": "Otorga autoridad para crear y eliminar franjas horarias exclusivas.",
          "ROLE_MANAGE_LOCAL_ADMINS": "Activa la posibilidad de gestionar la administración local en empresas contratistas",
          "ROLE_MANAGE_MAX_COMPANY_ACCOUNT_NUMBER": "Con el permiso, puede cambiar la cantidad máxima de cuentas para empresas",
          "ROLE_MANAGE_MOT_SECTIONS": "Gestión de modos de transporte. Posibilidad de aceptar y rechazar secciones individuales del modo de transporte.",
          "ROLE_MANAGE_NOTIFICATIONS": "Allows to manage notifications",
          "ROLE_MANAGE_PACKAGE_TYPE_TRANSPORT_TYPE": "Gestionar configuración de paquetes",
          "ROLE_MANAGE_PARTNERSHIP": "Permite agregar y eliminar enlaces entre contratistas",
          "ROLE_MANAGE_PRINCIPAL_DEDICATED_WINDOWS": "Posibilidad de crear ventanas exclusivas para la empresa cliente. El usuario de la empresa cliente podrá agregar cualquier aviso de envío a esa ventana exclusiva.",
          "ROLE_MANAGE_QUEUES_ACCESSIBILITY": "Con el permiso, puede activar/desactivar patios y colas.",
          "ROLE_MANAGE_SETTINGS": "Activa la gestión de configuraciones",
          "ROLE_MANAGE_TRANSPORT_TYPE": "Gestionar la configuración de tipo de transporte.",
          "ROLE_MANAGE_USER_GROUPS": "Gestión de grupos dentro de la empresa'del contratista",
          "ROLE_MANAGE_USER_ROLE_INVITATIONS": "Permite asignar una lista de roles para invitar para un rol de usuario específico",
          "ROLE_MODE_OF_TRANSPORTATION_TAB": "Activa el acceso a la pestaña “Modo de transporte”",
          "ROLE_MOVE_BLOCKED_WINDOWS": "Le permite mover franjas horarias grises.",
          "ROLE_MOVE_DEDICATED_WINDOWS": "Le permite mover franjas horarias exclusivas.",
          "ROLE_MOVE_WINDOWS_BETWEEN_WAREHOUSE_ZONES": "Le permite mover las franjas horarias entre las zonas de depósitos",
          "ROLE_OWN_COLUMNS_EXCEL": "Con el permiso, puede generar un informe con el mapa de visibilidad de las columnas de la tabla de transportes.",
          "ROLE_OWN_COMPANY_TAB": "Activa el acceso a la pestaña “Empresa” en la cuenta del'contratista.",
          "ROLE_PERFORM_ACTION_IN_WAREHOUSE": "El rol le permite efectuar acciones en el depósito",
          "ROLE_PLANNING_TAB": "Activa el acceso a la pestaña Planificación",
          "ROLE_PRICE_LIST_TAB": "Activa la posibilidad de aprobar una oferta agregada a la lista de precios",
          "ROLE_PRODUCTS_TAB": "Agrega acceso a la pestaña relacionada con productos.",
          "ROLE_QUEUES_TAB": "Activa el acceso a la pestaña “Colas”",
          "ROLE_QUEUE_STATUS_TAB": "Activa el acceso a la pestaña “Estado de colas”",
          "ROLE_READ_WINDOW_NOTES": "Activa la visibilidad de notas en las franjas grises para los usuarios con este nivel de permiso.",
          "ROLE_REJECT_CHECKPOINT": "Le permite rechazar un punto de control.",
          "ROLE_RESIZE_BLOCKED_WINDOWS": "Le permite cambiar el tamaño de las franjas horarias grises (franjas horarias bloqueadas). También requiere el permiso “Mover franjas bloqueadas” para funcionar correctamente.",
          "ROLE_RESIZE_DEDICATED_WINDOWS": "Le permite cambiar el tamaño de las franjas horarias exclusivas. También requiere el permiso “Mover franjas exclusivas” para funcionar correctamente.",
          "ROLE_RESTORE_ORDER_FROM_ARCHIVE": "Posibilidad de restaurar pedidos del archivo tras el archivado automático por demora",
          "ROLE_REVOKE_CHECKPOINT": "Le permite revocar un punto de control.",
          "ROLE_SEND_SMS_TO_ALL_DRIVERS_IN_QUEUE": "Activa la opción de enviar notificaciones por mensaje de texto a todos los conductores de una cola determinada (la pestaña Estado de colas)",
          "ROLE_SETTINGS_TAB": "Activa el acceso a la pestaña “Configuración”.",
          "ROLE_SHOW_API_INTEGRATION_LOGS": "Mostrar la sección “Integración” en el formulario de transporte.",
          "ROLE_SHOW_ARTR_API_LOGS": "Mostrar la sección “Registros de ANPR” en el formulario de transporte.",
          "ROLE_SHOW_AWAITING_INVITATIONS_TABLE": "Con este permiso, puede ver la lista de invitaciones que aguardan respuesta en los detalles de una empresa determinada.",
          "ROLE_SHOW_CUSTOM_FIELD_DEFINITIONS": "Con este permiso, puede agregar, editar y eliminar campos personalizados. Además se requiere el permiso “Acceso a configuración de campos personalizados”.",
          "ROLE_SHOW_PARTNERSHIP_TABLE": "Con este permiso, puede ver la lista de contratistas relacionados en los detalles de una empresa determinada. Las sociedades se generan mediante invitaciones.",
          "ROLE_SHOW_TRANSPORT_LOGS": "Mostrar la sección “Historial” en el formulario de transporte.",
          "ROLE_SORT_TRANSPORT_TABLE": "El rol le permite filtrar la tabla de transportes por una columna seleccionada",
          "ROLE_STATISTICS_REPORT_TAB": "Acceso a la pestaña “Estadísticas”",
          "ROLE_SUPER_USER": "Privilegios de usuarios especiales.",
          "ROLE_TRANSPORT_TABLE": "Un usuario con este rol tiene acceso a la tabla de transportes.",
          "ROLE_USERS_TAB": "Activa el acceso a la pestaña “Usuarios”. ",
          "ROLE_WAREHOUSE_TAB": "Activa el acceso a la pestaña “Cronograma”.",
          "UNLIMITED_ACCESS_TO_ARCHIVED_TRANSPORTS": "Define el acceso a pedidos por tiempo indefinido"
        },
        "generalPermissions": "Permisos generales",
        "header": "Permisos para un rol ",
        "invitationSection": "El usuario puede invitar a roles",
        "invitationSettings": "Configuración de invitaciones",
        "label": "Configuración de grupos de roles",
        "noAuthorities": "No hay autoridad.",
        "notificationSection": "Configuración de notificaciones",
        "notificationSettings": "Configuración de notificaciones",
        "permissions": "Permisos",
        "pricingPlans": "para empresas con el plan de precios {pricingPlan}",
        "successCreate": "Se ha creado un rol correctamente",
        "successDelete": "La autoridad se ha desactivado correctamente",
        "successUpdate": "Los cambios se guardaron correctamente",
        "sureToDelete": "¿Está seguro de que desea desactivar esta autoridad?",
        "sureToDeleteAll": "¿Está seguro de que desea desactivar todas las autoridades para este grupo?",
        "timeIntervalLabel": "Intervalo",
        "timeReferenceLabel": "Referencia de tiempo",
        "timeReferences": {
          "EARLIEST_TIME_WINDOW_CURRENT_DATE": "comienzo de la primera franja horaria en relación con la fecha actual",
          "EARLIEST_TIME_WINDOW_START_AFTER_CHANGE": "comienzo de la primera franja horaria (después de mover)",
          "EARLIEST_TIME_WINDOW_START_BEFORE_CHANGE": "comienzo de la primera franja horaria (antes de mover)",
          "LATEST_TIME_WINDOW_STOP": "final de la última franja horaria",
          "TRANSPORT_DATE_CREATED": "fecha de creación del transporte"
        },
        "usersList": "Lista de usuarios"
      },
      "transportTypes": {
        "addAuctionSetting": "Agregar configuración de subastas",
        "addTransactionSetting": "Agregar configuración de transacciones",
        "auctionSetting": "Configuración de subastas",
        "create": "Crear tipo de transporte",
        "englishName": "Nombre en inglés",
        "group": "Grupo",
        "invalidCustomFieldId": "No existe un customfield con el ID indicado",
        "label": "Configuración de tipo de transporte",
        "name": "Nombre",
        "noAuctionSetting": "La configuración para subastas aún no se ha agregado",
        "noTransactionSetting": "La configuración para la derivación de pedidos a la empresa transportista aún no se ha agregado",
        "packageTypeSettings": "Configuración de tipo de paquete",
        "permissions": "Permisos",
        "permissionsSaveSuccess": "La configuración de permisos se ha guardado correctamente.",
        "selectRoleGroups": "Seleccionar los roles que podrán crear tipos de transporte",
        "settings": "Configuración de tipo de transporte",
        "settingsDescription": {
          "adviceRequiresPhoneNumber": "Define si el campo “Número de teléfono móvil” será obligatorio al crear un aviso de envío",
          "adviceRestrictionInEveryStep": "Especifica si el campo “Intervalo de tiempo para aviso de envío” está visible en todos los pasos",
          "adviceSavedWithTransport": "Los datos de avisos de envío se guardarán con el transporte; la introducción de los datos de avisos de envío será en el formulario de transporte",
          "adviceSeparatedFromWindow": "Especifica si las tareas de agregar una ventana y agregar los datos de avisos de envío son pasos independientes",
          "allowLoadStepTypeChange": "Especifica si el tipo de paso de carga puede modificarse en el formulario de transporte entre la etapa del depósito y la etapa del contratista",
          "allowUnloadStepTypeChange": "Especifica si el tipo de paso de descarga puede modificarse en el formulario de transporte entre la etapa del depósito y la etapa del contratista",
          "allowsAltIdentifier": "Define la visibilidad de un identificador de pedido adicional (altIdentifier). Este campo aparece en el formulario de transporte con el nombre definido en la opción “Nombre de columna de números de pedidos”.",
          "allowsCarRegistrationNumber": "Especifica la visibilidad del “Número de registro de automóvil”",
          "allowsCarrier": "Especifica si la “Empresa transportista” participa del transporte. Especifica la visibilidad del campo “Empresa transportista” en el formulario de transporte",
          "allowsContractor": "Especifica si la empresa denominada “Proveedor” participa del transporte. Especifica la visibilidad del campo “Proveedor” en el formulario de transporte",
          "allowsDriverDocumentNumber": "Especifica la visibilidad del campo “Número de documento del conductor”",
          "allowsDriverName": "Define si el campo “Nombre y apellido'del conductor” estará visible al crear un aviso de envío",
          "allowsErpIdentifier": "Define la visibilidad de un identificador de pedido (erpIdentifier). Este campo aparece en el formulario de transporte con el nombre definido en la opción “ERP_IDENTIFIER_LABEL”.",
          "allowsPeriodicTransport": "Especifica si la opción de aviso de envío periódico estará disponible. La opción solo estará disponible para los proveedores/destinatarios seleccionados.",
          "allowsPhoneNumber": "Define si el campo “Número de teléfono móvil” estará visible al crear un aviso de envío",
          "allowsPriceList": "Especifica si el tipo de transporte tiene acceso a la “Lista de precios”",
          "allowsRodoCheckbox": "Determina si la condición de la RGPD está visible y es obligatoria en el formulario de avisos de envío",
          "allowsService": "Especifica si la empresa de “Servicio” participa del transporte. Especifica la visibilidad del campo “Servicio” en el formulario de transporte",
          "allowsTrailer": "Define si el campo “Número de registro del tráiler/semitráiler” estará visible al crear un aviso de envío",
          "allowsTransportSupervisor": "Especifica la visibilidad del campo “Persona de contacto” en el formulario de transporte",
          "altIdentifierFormat": "Especifica el formato de número automático de “altIdentifier”. Variables: $sequence: número de secuencia generado automáticamente, DD/MM/AAAA: posibilidad de introducir la fecha. Ejemplo: “IN/$sequence/MM/AA”.",
          "altIdentifierIsCreatedAutomatically": "Especifica si el campo de identificador de transporte (altIdentifier) se creará automáticamente. Con esta opción activada, no podrá editar el identificador en el formulario de transporte.",
          "altIdentifierRegex": "Especifica la expresión regular que se usará para verificar si el valor indicado en el campo “altIdentifier” es válido.",
          "altIdentifierSequenceName": "Especifica una secuencia que apunta a un número secuencial para el campo “altIdentifier” generado automáticamente",
          "alternativeStepsLayout": "Cada paso estará en una línea diferente",
          "calculatingTimeWindowSizeFromAlgorithm": "Especifica si se utilizará el algoritmo especificado en el depósito para calcular el tamaño de la franja horaria",
          "carRegistrationNumberTranslationKey": "Identificador de traducción para el campo “Número de registro de vehículo”",
          "carrierMustBeSelectedBeforeAdvice": "Agregar un aviso de envío al transporte solo será posible una vez que ya se haya asignado la empresa transportista al transporte",
          "checkAmountOfCover": "Validar el monto de cobertura al crear subastas o derivar a empresa transportista",
          "dateFilterProperty": "Especifica el campo tras el cual los pedidos del archivo se filtrarán de manera predeterminada.",
          "defaultAmountOfCover": "Valor predeterminado del campo: “Monto de cobertura”",
          "defaultLoadCompanyId": "Empresa predeterminada en el paso de carga",
          "defaultLoadStepEndHour": "Hora predeterminada para el comienzo del intervalo de tiempo de las etapas con socios",
          "defaultLoadStepStartHour": "Hora predeterminada para el comienzo del intervalo de tiempo de las etapas con socios",
          "defaultServicemanCompany": "La empresa de servicio seleccionada se definirá automáticamente al crear un transporte nuevo",
          "defaultTextForTransportAdditionalDescription": "Texto predeterminado en el campo “Notas adicionales”",
          "defaultUnloadCompanyId": "Empresa predeterminada en el paso de descarga",
          "dimensionsTranslationKey": "Identificador de traducción del campo “Dimensiones” (DIMENSIONS)",
          "dimensionsVisibleInContractorStep": "Especifica la visibilidad del campo “Número de pedido de compra” en el formulario de transporte (paso sin depósito)",
          "dimensionsVisibleInWarehouseStep": "Especifica la visibilidad del campo “Número de pedido de compra” en el formulario de transporte (paso con depósito)",
          "documentIdentifierTranslationKey": "Identificador de traducción del campo “Número de nota de entrega” (DELIVERY_NOTE_NUMBER, CUSTOMERS_ORDER_NUMBER, ISSUE_ORDER_NUMBER, REFERENCE_NUMBER)",
          "duplicateStepCountryFieldToNewSteps": "Determina si el campo “País” debe completarse automáticamente en el escalador recién agregado a partir del valor del primer paso que no es de almacenamiento",
          "generateTransportOrder": "Determina si el pedido de transporte asignado se generará al crear el transporte",
          "generatingWeightReceiptEnabled": "Activa la opción de descarga de un ticket de peso para transporte. El recibo se puede descargar en el formulario de transporte en la sección “Pesaje”. Además, las cuentas con el rol de guardián (GUARD) pueden descargar el ticket directamente desde la tabla de transportes",
          "guardCheckpointConfirmationSingleColumnLayout": "Especifica si generaremos una vista en la que los botones y las fechas de confirmación de estado de los escaladores están visibles para la seguridad en una sola columna para un escalador indicado",
          "hasCmrDocumentEnabled": "Especifica que, en caso de que el tipo de transporte tenga un documento CMR, este se podrá descargar en la sección “Documentos” y en la tabla de transportes en la columna de documentos",
          "hasDiscrepancyReportEnabled": "Especifica la visibilidad de la sección “Registro de discrepancias” en el formulario de transporte",
          "hasEntrancePassDocumentEnabled": "Especifica que, en caso de que el tipo de transporte tenga un documento CMR, este se podrá descargar en la sección “Documentos” y en la tabla de transportes en la columna de documentos",
          "hasProductSpecificationDocumentEnabled": "Especifica que, en caso de que el tipo de transporte tenga un documento de “Especificación del producto”, este se podrá descargar en la sección “Documentos” y en la tabla de transportes en la columna de documentos",
          "hasVisibleSteps": "Especifica si los campos relacionados con los pasos del transporte estarán visibles en el formulario",
          "idx": "Determina el orden en que aparecen los transportes en la lista",
          "isAmountOfCoverVisible": "Visibilidad del campo: “Monto de cobertura”",
          "isInternalReleaseNumberVisibleInContractorStep": "Especifica la visibilidad del campo “Número de nota de entrega” en el formulario de transporte (paso sin depósito)",
          "isInternalReleaseNumberVisibleInWarehouseStep": "Especifica la visibilidad del campo “Número de nota de entrega” en el formulario de creación de transporte (el paso con depósito)",
          "isLoadStepInWarehouseByDefault": "Especifica si el paso de carga será en el depósito de manera predeterminada al crear el pedido (se puede modificar cuando “allowLoadStepTypeChange == true”)",
          "isReadyToPlanObligatory": "Obligatoriedad del campo: “Preparado para planificar” en el formulario de transporte",
          "isReadyToPlanVisible": "Visibilidad del campo: “Preparado para planificar” en el formulario de transporte",
          "isUnloadStepInWarehouseByDefault": "Especifica si el paso de descarga será en el depósito de manera predeterminada al crear el pedido (se puede modificar cuando “allowUnloadStepTypeChange == true”)",
          "isVolumeVisibleInContractorStep": "Especifica la visibilidad del campo “Volumen” en el formulario de transporte (paso sin depósito)",
          "isVolumeVisibleInWarehouseStep": "Especifica la visibilidad del campo “Volumen” en el formulario de transporte (paso con depósito)",
          "isWarehouseLocationObligatory": "Obligatoriedad del campo: “Ubicación” en el formulario de transporte",
          "isWarehouseLocationVisible": "Visibilidad del campo: “Ubicación” en el formulario de transporte",
          "isWarehouseZoneVisible": "Especifica la visibilidad del campo “Zona de depósito/Surtido” en el formulario de transporte",
          "isWeightVisibleInContractorStep": "Especifica la visibilidad del campo “Peso” en el formulario de transporte (paso sin depósito)",
          "isWeightVisibleInWarehouseStep": "Especifica la visibilidad del campo “Peso” en el formulario de transporte (paso con depósito)",
          "maximumPermissableVehicleWeight": "Especifica la visibilidad del mensaje de sobrepeso durante el pesaje",
          "newAddressAsDefaultInLoadStep": "Tras elegir la empresa en el paso de carga, se mostrará la vista para agregar la nueva dirección",
          "newAddressAsDefaultInUnloadStep": "Tras elegir la empresa en el paso de descarga, se mostrará la vista para agregar la nueva dirección",
          "newAddressCityAutocompleteEnabled": "Autocompletar el campo “ciudad” al agregar una nueva dirección en el formulario de pedidos",
          "numberOfDaysAddedToFirstStep": "Especifica la cantidad de días que se agregarán al intervalo planificado de fechas o tiempo en el formulario de transporte para el primer paso:",
          "numberOfDaysAddedToFirstStepEnd": "Especifica la cantidad de días que se agregarán al final del intervalo planificado de fechas o tiempo en el formulario de transporte para el primer paso:",
          "numberOfDaysAddedToNextSteps": "Especifica la cantidad de días que se agregarán al intervalo planificado de fechas o tiempo en el formulario de transporte para el segundo paso y los posteriores:",
          "numberOfDaysAddedToNextStepsEnd": "Especifica la cantidad de días que se agregarán al final del intervalo planificado de fechas o tiempo en el formulario de transporte para el segundo paso y los posteriores:",
          "pastingFromExcelEnabled": "Opción de pegar datos de avisos de envío desde Excel",
          "purchaseOrderNumberTranslationKey": "Identificador de traducción del campo “Número de pedido de compra”(SD_NUMBER)",
          "purchaseOrderNumberVisibleInContractorStep": "Especifica la visibilidad del campo “Número de pedido de compra” en el formulario de transporte (paso sin depósito)",
          "purchaseOrderNumberVisibleInWarehouseStep": "Especifica la visibilidad del campo “Número de pedido de compra” en el formulario de transporte (paso con depósito)",
          "quantitySumCheckValue": "Especifica la cantidad de paletas tras la cual aparece el mensaje de confirmación en el formulario de transporte",
          "randomTransportInspection": "Especifica si se puede tomar el transporte para la inspección aleatoria",
          "requiresAddress": "Especifica si se debe agregar una dirección en el paso de transporte que no es de depósito",
          "requiresAdvice": "Datos de avisos de envío obligatorios; si se desactiva esta opción, el formulario de avisos de envío y los detalles de los avisos de envío quedarán vacíos",
          "requiresAdviceDateRestrictions": "Especifica si el campo “Intervalo de tiempo para aviso de envío” estará visible y será obligatorio al crear un transporte",
          "requiresAltIdentifier": "Especifica si el campo “altIdentifier” estará visible y será obligatorio al crear transporte. Este campo aparece en el formulario de transporte con el nombre definido en la opción “Nombre de columna de números de pedidos”",
          "requiresArrivalAndDepartureDate": "Determina si los campos “Fecha de retiro de pedido”/”Fecha de entrega de pedido” están visibles en el formulario de transporte (paso con contratista)",
          "requiresArrivalAndDepartureDateInWarehouseStep": "Determina si los campos “Fecha de retiro de pedido”/”Fecha de entrega de pedido” están visibles en el formulario de transporte (paso con depósito)",
          "requiresCarRegistrationNumber": "Define si el campo “Número de registro de automóvil” será obligatorio al crear un aviso de envío",
          "requiresCarrier": "Especifica si el campo “Empresa transportista” será obligatorio al crear un transporte",
          "requiresContractor": "Para guardar el transporte, deberá indicar los detalles del “Proveedor/Destinatario”. Si los pasos no relacionados con el depósito estarán visibles en el transporte, en esta sección, se puede seleccionar el contratista. De lo contrario, podrá elegir el contratista en la parte principal del formulario de transporte.",
          "requiresContractorStepCompanyGroup": "Especifica si el campo “Grupo de contratistas” será obligatorio al crear un transporte",
          "requiresDriverDocumentNumber": "Define si el campo “Número de documento'del conductor” será obligatorio al crear un aviso de envío",
          "requiresDriverLanguage": "Especifica si el campo'“Idioma del conductor” está visible y es obligatorio",
          "requiresDriverName": "Define si el campo “Nombre y apellido'del conductor” será obligatorio al crear un aviso de envío",
          "requiresErpIdentifier": "Especifica si el campo “erpIdentifier” está visible y es obligatorio al crear transporte. Este campo aparece en el formulario de transporte con el nombre definido en la opción “ERP_IDENTIFIER_LABEL”",
          "requiresModeOfTransportationVerification": "Especifica si el aviso de envío es para todos los vehículos de la empresa transportista o solo para los vehículos verificados",
          "requiresService": "Especifica si el campo “Servicio” será obligatorio al crear un transporte",
          "requiresStepsWeight": "Especifica si el campo “Peso” será obligatorio al crear un transporte",
          "requiresTimeWindow": "Debe seleccionar una franja horaria para guardar el transporte. Esto significa que, tras guardar el formulario de transporte, el usuario será dirigido directamente al cronograma para seleccionar un espacio libre para la franja horaria.",
          "requiresTrailer": "Define si el campo “Número de registro del tráiler/semitráiler” será obligatorio al crear un aviso de envío",
          "requiresTransportSupervisor": "Obligatoriedad del campo “Persona de contacto”",
          "requiresUniqueErpIdentifier": "Especifica si el campo “erpIdentifier” debe tener valores exclusivos. Los pedidos eliminados no se tienen en cuenta al verificar la exclusividad.",
          "requiresWarehouse": "Debe seleccionar un depósito para guardar el transporte. Al seleccionar la opción “Selección obligatoria de franja horaria al crear transporte”, también deberá especificar el depósito.",
          "requiresWorkers": "Obligatoriedad del campo “Trabajadores”",
          "selectionOfWindowBeforeAdvice": "Especifica si la selección de ventana debe realizarse antes de agregar los datos de avisos de envío",
          "shouldCopyAdviceOnTransportCopy": "Especifica si los datos de avisos de envío se copiarán con la opción “Crear similar”",
          "showAddressFieldsInContractorSteps": "Especifica si los campos de dirección debe estar visibles en los pasos de contratistas",
          "transportSupervisorTranslationKey": "Especifica el nombre que se muestra en el campo “Persona de contacto” en el formulario de creación de transporte",
          "transportTypeName": "Especifica el nombre del tipo de transporte",
          "transportTypeNameEn": "Especifica el nombre en inglés del tipo de transporte",
          "usedForDeliveries": "Opción de crear entregas",
          "windowColorDependsOnWarehouse": "Especifica si el color de la franja horaria depende solo de los estados asociados con una franja determinada",
          "workerFieldTranslationKey": "Especifica el nombre que se muestra en el campo “Trabajador” en el formulario de creación de transporte (WORKERS, GUESTS, SERVICEMAN)"
        },
        "settingsGroup": {
          "advice": "Configuración relacionada con un aviso de envío",
          "adviceForm": "Configuración relacionada con un formulario de avisos de envío",
          "auctionMails": "Configuración relacionada con notificaciones por correo electrónico",
          "auctionOffers": "Configuración relacionada con ofertas' precios",
          "auctionPass": "Configuración relacionada con la derivación del pedido a la empresa transportista",
          "general": "Configuración general",
          "identifier": "Configuración relacionada con un identificador de transporte",
          "index": "Configuración relacionada con índices",
          "offerForm": "Configuración relacionada con el formulario de ofertas",
          "others": "Otros",
          "step": "Configuración relacionada con pasos de un transporte",
          "transaction": "Configuración relacionada con la derivación de un transporte a una empresa transportista",
          "transport": "Configuración de transportes",
          "transportForm": "Configuración relacionada con un formulario de transporte",
          "transportType": "Configuración relacionada con todo el tipo de transporte"
        },
        "settingsLabel": {
          "adviceRequiresPhoneNumber": "La obligatoriedad del campo: Número de teléfono'del conductor",
          "adviceRestrictionInEveryStep": "Visibilidad del campo “Intervalo de tiempo para aviso de envío” en cada paso",
          "adviceSavedWithTransport": "Los datos de avisos de envío se guardarán con el transporte",
          "adviceSeparatedFromWindow": "Especifica si las tareas de agregar una ventana y agregar los datos de avisos de envío son pasos independientes",
          "allowLoadStepTypeChange": "Visibilidad de la casilla de verificación de “Depósito” en el paso de carga",
          "allowUnloadStepTypeChange": "Visibilidad de la casilla de verificación de “Depósito” en el paso de descarga",
          "allowsAltIdentifier": "Visibilidad del pedido de identificador adicional en el formulario: “altIdentifier”",
          "allowsCarRegNumberDuringTransportCreation": "Visibilidad del campo: “Número de registro de vehículo” en el campo de creación del pedido",
          "allowsCarRegistrationNumber": "Especifica la visibilidad del “Número de registro de automóvil”",
          "allowsCarrier": "Incluir empresa transportista en el transporte",
          "allowsContractor": "Incluir empresa proveedora en el transporte",
          "allowsDriverDocumentNumber": "Visibilidad del campo “Número de documento del conductor”",
          "allowsDriverName": "Obligatoriedad del campo: Nombre y apellido'del conductor",
          "allowsErpIdentifier": "Visibilidad del pedido de identificador en el formulario: “erpIdentifier”",
          "allowsPeriodicTransport": "Especifica si es posible agregar avisos de envío periódicos",
          "allowsPhoneNumber": "La visibilidad del “Número'de teléfono del conductor” en un formulario de aviso de envío",
          "allowsPriceList": "Especifica si el tipo de transporte tiene acceso a la “Lista de precios”",
          "allowsRodoCheckbox": "Activación de la condición RGPD",
          "allowsService": "Incluir empresa del servicio en el transporte",
          "allowsTrailer": "Visibilidad de un campo: “Número de registro del tráiler/semitráiler”",
          "allowsTransportSupervisor": "Visibilidad del campo “Persona de contacto” en el formulario de transporte",
          "altIdentifierFormat": "Formato del número “altIdentifier” generado automáticamente",
          "altIdentifierIsCreatedAutomatically": "Generación automática de un número de pedido adicional: “altIdentifier”",
          "altIdentifierRegex": "Expresión regular empleada para validar el campo “altIdentifier”",
          "altIdentifierSequenceName": "Nombre de secuencia para el campo “altIdentifier”",
          "alternativeStepsLayout": "Vista de pasos alternativa en el formulario de pedidos",
          "auctionSetting": {
            "addFileToTransportAfterOfferAcceptation": "Adjuntar automáticamente un archivo al pedido después de que la empresa transportista acepte el pedido",
            "addFileToTransportAfterOfferAcceptationName": "Nombre del archivo que se adjunta al pedido después de que la empresa transportista acepta el pedido",
            "auctionEmailSubjectEn": "El asunto del correo electrónico de notificación sobre la subasta: inglés",
            "auctionEmailSubjectPl": "El asunto del correo electrónico de notificación sobre la subasta",
            "auctionEndedWithoutOffersEmailFileName": "La versión del correo electrónico sobre el final de la subasta sin ofertas",
            "auctionProlongationOffsetInterval": "Tiempo tras el cual la subasta se extenderá en caso de cambio de líder",
            "canAcceptWithMinPrice": "La empresa transportista gana una subasta tras alcanzar el precio de reserva",
            "defaultAuctionFinishDelayInterval": "Duración predeterminada de la subasta",
            "defaultCurrency": "La moneda predeterminada de la subasta",
            "exportAuctionInfoToExcel": "Exportar datos de la subasta a un archivo de Excel con los pedidos",
            "minOfferReductionSize": "El valor mínimo al cual debe reducirse la oferta",
            "newAuctionEmailFileName": "La versión del correo electrónico sobre la subasta nueva",
            "reminderTimeAboutAuction": "El tiempo que determina cuándo enviar un recordatorio sobre una subasta en curso",
            "requireDescriptionSection": "Campo obligatorio para notas en el formulario de envío de oferta",
            "requireMaxPrice": "Exigir una oferta máxima al crear una subasta",
            "requireMinOfferReductionSizeValidation": "Aplicar validación al valor mínimo de la reducción del precio",
            "requireMinPrice": "Exigir un precio de reserva al crear una subasta",
            "requireReasonIfNotBestOfferIsChosen": "Exigir motivos si no se selecciona la mejor oferta",
            "requireTransitTime": "Campo de tiempo de tránsito obligatorio",
            "selectAllCarriersByDefault": "De manera predeterminada, seleccionar todas las empresas transportistas para la subasta",
            "shouldPassToCarrierAfterAuction": "Transferencia automática del pedido al ganador de la subasta",
            "showBlockedCompaniesSection": "Visibilidad de la sección con empresas bloqueadas al crear una subasta",
            "showCompanyGroupsSection": "Visibilidad de la sección con agrupamiento de empresas transportistas al crear una subasta",
            "showDescriptionSection": "Visibilidad del campo para notas en el formulario de presentación de ofertas",
            "showFinishAuctionButton": "Visibilidad del botón “Finalizar subasta” para una subasta en curso",
            "showPassToCarrierButton": "Visibilidad del botón “Derivar”",
            "showPriceRangeFields": "Visibilidad del intervalo de precios al crear una subasta",
            "showQuantityFieldInOfferForm": "Visibilidad de la cantidad de paletas en el formulario de presentación de ofertas",
            "showRevokeOfferButton": "Visibilidad del botón de revocación de oferta",
            "showStepDataInOfferForm": "Visibilidad de lugares de carga/descarga en el formulario de presentación de ofertas",
            "showTransitTime": "Tiempo de tránsito de visibilidad de campo",
            "translationKey": "Identificador de traducción"
          },
          "calculatingTimeWindowSizeFromAlgorithm": "Recuento de tamaño de franja horaria basado en un algoritmo",
          "carRegistrationNumberTranslationKey": "Identificador de traducción para el campo “Número de registro de vehículo” (TRUCK, VEHICLE)",
          "carrierMustBeSelectedBeforeAdvice": "Especifica si se puede agregar un aviso de envío sin seleccionar una empresa transportista",
          "checkAmountOfCover": "Validar el monto de cobertura al crear subastas o derivar a empresa transportista",
          "dateFilterProperty": " El filtro predeterminado del archivo: ",
          "defaultAmountOfCover": "Valor predeterminado del campo: “Monto de cobertura”",
          "defaultLoadCompanyId": "Empresa predeterminada en el paso de carga",
          "defaultLoadStepEndHour": "Hora predeterminada para el comienzo del intervalo de tiempo de las etapas con socios",
          "defaultLoadStepStartHour": "Hora predeterminada para el comienzo del intervalo de tiempo de las etapas con socios",
          "defaultServicemanCompany": "Empresa predeterminada de mantenimiento",
          "defaultTextForTransportAdditionalDescription": "Texto predeterminado en el campo “Notas adicionales”",
          "defaultUnloadCompanyId": "Empresa predeterminada en el paso de descarga",
          "dimensionsTranslationKey": "Identificador de traducción del campo “Dimensiones”",
          "dimensionsVisibleInContractorStep": "Visibilidad del campo “Dimensiones” en el formulario de pedidos (paso sin depósito)",
          "dimensionsVisibleInWarehouseStep": "Visibilidad del campo “Dimensiones” en el formulario de pedidos (paso con depósito)",
          "documentIdentifierTranslationKey": "Identificador de traducción del campo “Número de nota de entrega”",
          "duplicateStepCountryFieldToNewSteps": "Determina si el campo “País” debe completarse automáticamente en el escalador recién agregado a partir del valor del primer paso que no es de almacenamiento",
          "generateTransportOrder": "Determina si el pedido de transporte asignado se generará al crear el transporte",
          "generatingTransportWeightReceiptEnabled": "Determina si debe generarse un recibo de peso en el tipo",
          "generatingWeightReceiptEnabled": "La posibilidad de generar un ticket de peso",
          "guardCheckpointConfirmationSingleColumnLayout": "Especifica si generaremos una vista en la que los botones y las fechas de confirmación de estado de los escaladores están visibles para la seguridad en una sola columna para un escalador indicado",
          "hasCmrDocumentEnabled": "Especifica si el tipo de transporte tiene un documento CMR",
          "hasDiscrepancyReportEnabled": "Visibilidad de secciones: “Registro de discrepancias” en el formulario de transporte",
          "hasEntrancePassDocumentEnabled": "Especifica si el tipo de transporte tiene un documento CMR",
          "hasProductSpecificationDocumentEnabled": "Especifica si el tipo de transporte tiene un documento de “Especificación del producto”",
          "hasVisibleSteps": "Visibilidad de la sección de pasos de un transporte en un formulario",
          "idx": "Idx",
          "isAmountOfCoverVisible": "Visibilidad del campo: “Monto de cobertura”",
          "isContactDetailsFieldsRequired": "Obligatoriedad de los campos: persona de contacto, correo electrónico y número de teléfono de la persona de contacto",
          "isContactDetailsFieldsVisible": "Visibilidad, en el formulario de pedidos, de los campos: persona de contacto, correo electrónico y número de teléfono de la persona de contacto",
          "isInternalReleaseNumberVisibleInContractorStep": "Visibilidad del campo “Número de nota de entrega” en el formulario de pedidos (paso sin depósito)",
          "isInternalReleaseNumberVisibleInWarehouseStep": "Visibilidad del campo “Número de nota de entrega” en el formulario de pedidos (paso con depósito)",
          "isLoadStepInWarehouseByDefault": "Paso de carga en el depósito de manera predeterminada",
          "isReadyToPlanObligatory": "Obligatoriedad del campo: “Preparado para planificar” en el formulario de transporte",
          "isReadyToPlanVisible": "Visibilidad del campo: “Preparado para planificar” en el formulario de transporte",
          "isUnloadStepInWarehouseByDefault": "Paso de descarga en el depósito de manera predeterminada",
          "isVolumeVisibleInContractorStep": "Visibilidad del campo “Volumen” en el formulario de pedidos (paso sin depósito)",
          "isVolumeVisibleInWarehouseStep": "Visibilidad del campo “Volumen” en el formulario de pedidos (paso con depósito)",
          "isWarehouseLocationObligatory": "Obligatoriedad del campo: “Ubicación” en el formulario de transporte",
          "isWarehouseLocationVisible": "Visibilidad del campo: “Ubicación” en el formulario de transporte",
          "isWarehouseZoneVisible": "Visibilidad del campo “Zona de depósito/Surtido” en el formulario de transporte",
          "isWeightVisibleInContractorStep": "Visibilidad del campo “Peso” en el formulario de pedidos (paso sin depósito)",
          "isWeightVisibleInWarehouseStep": "Visibilidad del campo “Peso” en el formulario de pedidos (paso con depósito)",
          "maximumPermissableVehicleWeight": "Mostrar un mensaje sobre exceso del peso permitido para el vehículo",
          "newAddressAsDefaultInLoadStep": "Tras elegir la empresa en el paso de carga, se mostrará la vista para agregar la nueva dirección",
          "newAddressAsDefaultInUnloadStep": "Tras elegir la empresa en el paso de descarga, se mostrará la vista para agregar la nueva dirección",
          "newAddressCityAutocompleteEnabled": "Autocompletar el campo “ciudad” al agregar una nueva dirección en el formulario de pedidos",
          "numberOfDaysAddedToFirstStep": "La cantidad de días que se agregarán al intervalo planificado de fechas o tiempo en el formulario de transporte para el primer paso:",
          "numberOfDaysAddedToFirstStepEnd": "La cantidad de días que se agregarán al final del intervalo planificado de fechas o tiempo en el formulario de transporte para el primer paso:",
          "numberOfDaysAddedToNextSteps": "La cantidad de días que se agregarán al intervalo planificado de fechas o tiempo en el formulario de transporte para el segundo paso y los posteriores:",
          "numberOfDaysAddedToNextStepsEnd": "La cantidad de días que se agregarán al final del intervalo planificado de fechas o tiempo en el formulario de transporte para el segundo paso y los posteriores:",
          "pastingFromExcelEnabled": "Opción de pegar datos de avisos de envío desde Excel",
          "purchaseOrderNumberTranslationKey": "Identificador de traducción del campo “Número de pedido de compra”",
          "purchaseOrderNumberVisibleInContractorStep": "Visibilidad del campo “Número de pedido de compra” en el formulario de pedidos (paso sin depósito)",
          "purchaseOrderNumberVisibleInWarehouseStep": "Visibilidad del campo “Número de pedido de compra” en el formulario de pedidos (paso con depósito)",
          "quantitySumCheckValue": "Cantidad tras la cual se mostrará una advertencia",
          "randomTransportInspection": "Activación de controles aleatorios",
          "requiresAddress": "Datos de dirección obligatorios en caso de un paso sin depósito",
          "requiresAdvice": "La obligatoriedad de los datos de avisos de envío",
          "requiresAdviceDateRestrictions": "Visibilidad y obligatoriedad del campo “Intervalo de tiempo para aviso de envío”",
          "requiresAltIdentifier": "La obligatoriedad del campo: “altIdentifier”",
          "requiresArrivalAndDepartureDate": "Visibilidad y campo obligatorio: “Fecha de recepción de pedido”/”Fecha de entrega de pedido” (paso con contratista)",
          "requiresArrivalAndDepartureDateInWarehouseStep": "Visibilidad y campo obligatorio: “Fecha de recepción de pedido”/”Fecha de entrega de pedido” (paso con depósito)",
          "requiresCarRegistrationNumber": "Obligatoriedad del campo: “Número de registro de vehículo”",
          "requiresCarrier": "La obligatoriedad del campo: “Empresa transportista”",
          "requiresContractor": "Obligatoriedad de los datos: Proveedor/Destinatario",
          "requiresContractorStepCompanyGroup": "La obligatoriedad del campo: “Grupo de contratistas”",
          "requiresDriverDocumentNumber": "Obligatoriedad del campo: “Número de documento'del conductor”",
          "requiresDriverLanguage": "Visibilidad y obligatoriedad del campo'“Idioma del conductor”",
          "requiresDriverName": "Obligatoriedad del campo: “Nombre y apellido del conductor”",
          "requiresErpIdentifier": "La obligatoriedad del campo: “erpIdentifier”",
          "requiresModeOfTransportationVerification": "Verificación del modo de transporte (solo funciona con la opción “derivación a empresa transportista” activada)",
          "requiresService": "La obligatoriedad del campo: “Servicio”",
          "requiresStepsWeight": "Obligatoriedad del campo: “Peso”",
          "requiresTimeWindow": "La obligatoriedad de seleccionar una franja horaria al crear un transporte",
          "requiresTrailer": "Obligatoriedad del campo: “Número de registro del tráiler/semitráiler”",
          "requiresTransportSupervisor": "Obligatoriedad del campo “Persona de contacto”",
          "requiresUniqueErpIdentifier": "Exclusividad del campo “erpIdentifier”",
          "requiresWarehouse": "La obligatoriedad de seleccionar un depósito al crear un transporte",
          "requiresWorkers": "Obligatoriedad del campo “Trabajadores”",
          "selectionOfWindowBeforeAdvice": "Especifica si la selección de ventana debe realizarse antes de agregar los datos de avisos de envío",
          "shouldCopyAdviceOnTransportCopy": "Especifica si los datos de avisos de envío se copiarán con la opción “Crear similar”",
          "showAddressFieldsInContractorSteps": "Visibilidad de los campos de direcciones en los pasos con contratistas",
          "supplierFieldTranslationKey": "Identificador de traducción para el campo “Proveedor/Receptor” (CLIENT, COMPANY, COURIER)",
          "transportSupervisorTranslationKey": "Identificador de traducción para el campo “Persona de contacto” (CONTACT_PERSON, INVITEE, ORDERER, PERSON_TO_NOTIFY, HOST_PERSON)",
          "transportTypeName": "Nombre del tipo de transporte",
          "transportTypeNameEn": "Nombre en inglés del tipo de transporte",
          "usedForDeliveries": "Opción de crear entregas",
          "windowColorDependsOnWarehouse": "Especifica si el color de la franja horaria depende solo de los estados asociados con esa franja",
          "workerFieldTranslationKey": "Identificador de traducción para el campo “Trabajadores” (WORKERS, GUESTS, SERVICEMEN)"
        },
        "settingsSaveSuccess": "La configuración de tipo de transporte se ha guardado correctamente",
        "transactionSetting": "Configuración de transacciones",
        "workflow": "Pasos y estados (flujo de trabajo)"
      },
      "updateSuccessMessage": "Se ha actualizado la configuración.",
      "value": "Valor"
    },
    "square": {
      "SQUARE": {
        "addQueue": "Agregar cola",
        "buffer": "Búfer",
        "carsInFrontOfSmelter": "Vehículos antes de control de calidad",
        "classificationTab": "Clasificación",
        "create": "Crear patio de materias primas",
        "createPlace": "Agregar lugar",
        "label": "Patios de materias primas",
        "placeLabel": "Lugar",
        "queueLabel": "Cola",
        "queueStatusTab": "Estado de cola",
        "queuesStatus": "Estado de cola (tras control de calidad)",
        "queuesStatusTableHeader": "Resumen del estado actual de las colas",
        "queuesTab": "Colas",
        "square": "Patio",
        "unload": "Descarga"
      },
      "createQueueAfterSave": "Las colas se podrán configurar tras guardar el patio.",
      "disableConfirm": "¿Está seguro de que desea desactivar este patio?",
      "updateSuccessMessage": "La configuración del patio {squareName} se modificó correctamente."
    },
    "squareSetting": {
      "bufferSecondTableColumnVisibility": "Visibilidad de la columna (tabla 2) “Búfer”",
      "carRegistrationFirstTableColumnVisibility": "Visibilidad de la columna (tabla 1) “Número de registro de vehículo”",
      "hdsFirstTableColumnVisibility": "Visibilidad de la columna (tabla 1) “Camión pluma”",
      "label": "Configuración de patio y colas",
      "orderNumberFirstColumnVisibility": "Visibilidad de la columna (tabla 1) “Número de pedido”",
      "queueFirstTableColumnVisibility": "Visibilidad de la columna (tabla 1) “Cola”",
      "queueSecondTableColumnVisibility": "Visibilidad de la columna (tabla 2) “Cola”",
      "squareFirstTableColumnVisibility": "Visibilidad de la columna (tabla 1) “Patio”",
      "squareSecondTableColumnVisibility": "Visibilidad de la columna (tabla 2) “Patio”",
      "translationKey": "Identificador de traducción",
      "unloadSecondTableColumnVisibility": "Visibilidad de la columna (tabla 2) “Descarga”"
    },
    "statuses": {
      "deleted": "Eliminado",
      "finished": "Finalizado",
      "initial": "Avisado",
      "movedToArchiveDueDelay": "Demorado",
      "waitingForConfirmation": "Esperando confirmación de"
    },
    "statute": {
      "accept": {
        "disabled.title": "Consulte la declaración de aceptación de los Términos de servicio.",
        "header": "Estimado usuario:",
        "inTouch": "Comprendo y estaremos en contacto.",
        "informed": "Comprendo y me considero informado.",
        "label": "Comprendo y acepto lo anterior",
        "manual": "He leído, comprendido y aceptado el Manual de seguridad.",
        "paragraph_1": "1. LOGINTEGRA Sp. z o. o., ubicada en Gdańsk, Lęborska 3b, KRS 0000426238, NIP 584-272-80-64, REGON 221707056, controla sus datos personales almacenados en el Sistema, como se especifica en la RGPD.  Información de contacto del controlador: teléfono: +48 58 535 78 06, correo electrónico: info@logintegra.com. Sus datos se pueden compartir con otros usuarios del sistema y otras entidades que se describen a continuación.",
        "paragraph_10": "10. Si tiene preguntas, dudas o consultas relativas a sus datos, la manera más sencilla de contactarnos es escribiendo a info@logintegra.com",
        "paragraph_2": "2. Los datos personales de usuarios deben procesarse solo con fines de ejecutar los acuerdos celebrados entre el Controlador y otros Usuarios, para la ejecución de las obligaciones y los derechos relacionados con los acuerdos celebrados, y para la ejecución de las obligaciones en virtud de las disposiciones legales y dentro del alcance acordado por el Usuario para el procesamiento de los datos. ",
        "paragraph_3": "3. Ese Sistema se usa para la cooperación entre empresas y el manejo de un proceso logístico de las empresas de Usuarios. Opera bajo el principio de que los datos personales del Usuario pueden ser visibles para otros Usuarios del Sistema y pueden enviarse a ellos o se pueden transferir de otro modo. Los datos personales de Usuarios también se pueden transferir en otros casos estipulados en las disposiciones legales, en especial: ",
        "paragraph_3_1": "a autoridades tributarias, cuando la transferencia de datos personales sea obligatoria según lo que determinan las disposiciones tributarias;",
        "paragraph_3_2": "a autoridades públicas, entre otras, organismos de seguridad, tribunales, fiscales, autoridades de seguridad social, cuando la transferencia de datos personales sea obligatoria según lo que determinan las disposiciones legales;",
        "paragraph_3_3": "a subcontratistas confiables que brinden soporte técnico o servicios de hosting en nuestro nombre;",
        "paragraph_3_4": "a partes que ofrezcan servicios contables, tributarios y legales.",
        "paragraph_4": "4. Los datos personales de los Usuarios se deben procesar solo durante el período esencial para la ejecución de los fines estipulados en el párr. 2 o hasta que se retire el consentimiento. Algunas categorías de datos se pueden almacenar por más tiempo si dicha obligación se marca en disposiciones legales; por ej., el controlador almacenará los datos suministrados en facturas emitidas hasta que se complete el período legal para el almacenamiento de facturas con fines tributarios.",
        "paragraph_5": "5. El Usuario suministra los datos personales de modo voluntario. Sin embargo, debido al carácter del Sistema, es necesario brindar determinadas categorías de datos para poder utilizarlo. Si el Usuario no brinda todos los datos personales que se requieren en el formulario de registro o si retira su consentimiento para el procesamiento, será imposible usar el Sistema.",
        "paragraph_6": "6. Cada Usuario que sea una persona natural tiene derecho a lo siguiente:",
        "paragraph_6_1": "acceder a sus datos;",
        "paragraph_6_2": "corregir sus datos;",
        "paragraph_6_3": "borrar o restringir el procesamiento de sus datos;",
        "paragraph_6_4": "oponerse al procesamiento de sus datos, y",
        "paragraph_6_5": "transferir sus datos;",
        "paragraph_6_6": "revocar el consentimiento para el procesamiento de los datos en cualquier momento, lo cual no afecta el cumplimiento del derecho al procesamiento realizado en virtud del consentimiento, antes de su revocación.",
        "paragraph_6_bottom": "Además, el Usuario tiene derecho a presentar una demanda ante una autoridad supervisora cuando decida que el procesamiento de sus datos personales viola regulaciones de protección de datos personales.",
        "paragraph_7": "7. Las cuestiones detalladas relacionadas con la protección de datos se han especificado en nuestros documentos",
        "paragraph_7_1": "Política de procesamiento de datos;",
        "paragraph_7_2": "Manual de seguridad;",
        "paragraph_7_3": "Términos de servicio del sistema.",
        "paragraph_7_bottom": "Para acceder a los documentos, haga clic en su nombre.",
        "paragraph_8": "8. Nuestro Sistema emplea cookies.",
        "paragraph_9": "9. Recuerde que los datos personales se procesan en el Sistema. Al introducir datos personales de terceros en el Sistema; por ej., de los conductores, usted debe asegurarse de que se cumplan las obligaciones asumidas por el Controlador de los datos personales, tal como se especifican en la RGPD. Algunas de las obligaciones incluyen notificar al sujeto de los datos sobre lo siguiente:",
        "paragraph_9_1": "la identidad del Controlador y sus datos de contacto;",
        "paragraph_9_2": "la finalidad del procesamiento de los datos personales y los fundamentos legales para su procesamiento;",
        "paragraph_9_3": "los destinatarios o las categorías de destinatarios de los datos personales, si los hubiere;",
        "paragraph_9_4": "el plazo durante el cual se almacenarán los datos personales;",
        "paragraph_9_5": "la existencia del derecho a solicitar al Controlador el acceso a los datos personales del sujeto, como también su rectificación o eliminación, o la restricción del procesamiento, la oposición al procesamiento y la portabilidad de los datos;",
        "paragraph_9_6": "cuando el procesamiento se lleva a cabo en virtud del consentimiento de una persona, la existencia del derecho a retirar el consentimiento en cualquier momento, sin afectar la legalidad del procesamiento con consentimiento antes de su revocación;",
        "paragraph_9_7": "el derecho a presentar una demanda ante una autoridad supervisora;",
        "paragraph_9_8": "si el suministro de datos personales es un requisito legal o contractual, o un requisito para celebrar un contrato y si el sujeto de los datos está obligado a suministrar los datos personales y las posibles consecuencias en caso de no hacerlo.",
        "paragraph_9_bottom": "Recuerde garantizar el cumplimiento de las obligaciones del Controlador. Si toma conocimiento de un posible incumplimiento de las reglas de protección de los datos personales, notifíquenos de inmediato.",
        "policy": "He leído, comprendido y aceptado la Política de procesamiento de datos.",
        "statute": "He leído, comprendido y aceptado los Términos de servicio del sistema.",
        "subHeader": "El 25 de mayo de 2018, entraron en vigencia las disposiciones de la REGULACIÓN (UE) 2016/679 DEL PARLAMENTO EUROPEO Y DEL CONSEJO para la protección de las personas naturales en lo que respecta al procesamiento de datos personales y para el movimiento libre de tales datos, que deroga la Directiva 95/46/EC (Regulación General de Protección de Datos), publicada en el Diario Oficial de la Unión Europea, L 119 de 2016, pág. 1. A fin de lograr claridad en el contenido, de aquí en adelante se hará referencia a esa directiva mediante la sigla RGPD. En conformidad con la obligación que tienen los controladores y procesadores de datos personales en virtud de la RGPD, aquí le informamos sobre varias cuestiones importantes relacionadas con ella. Tómese un tiempo para familiarizarse con los siguientes contenidos:"
      },
      "appliesManualLabel": "Abrir el manual de gestión de postulaciones",
      "label": "Términos de servicio",
      "label.ablative": "Términos de servicio",
      "links": {
        "manual": "https://pl.logintegra.com/static/Manual.pdf",
        "policy": "https://pl.logintegra.com/static/Policy.pdf",
        "statute": "https://pl.logintegra.com/static/Statute-2018-05-24.pdf"
      },
      "openKnowledgeBase": "Abrir base de conocimientos",
      "policyLabel": "Política de procesamiento de datos",
      "reject": {
        "button": "Cancelar (se bloqueará esta cuenta)",
        "buttonForInvitations": "Renuncia",
        "confirmation": "¿Está seguro? Se bloqueará su cuenta."
      },
      "safetyManualLabel": "Manual de seguridad"
    },
    "step": {
      "CUSTOMERS_ORDER_NUMBER.label": "Número de pedido del cliente",
      "add": {
        "title": "Agregar otro paso"
      },
      "address": "Dirección",
      "canMoveLoadStep": "Solo los pasos de carga se pueden mover en su nivel de autorización",
      "canMoveUnloadStep": "Solo los pasos de descarga se pueden mover en su nivel de autorización",
      "companyGroup": "Grupo de contratistas",
      "contactPerson": {
        "email": "Dirección de correo electrónico",
        "label": "Persona de contacto",
        "phoneNumber": "Número de teléfono"
      },
      "delete": {
        "confirmMessage": "¿Está seguro de que desea eliminar este paso de este transporte?",
        "confirmMessageWithIndexes": "¿Está seguro de que desea eliminar este paso de este transporte? Todos los índices asociados se eliminarán junto con este paso.",
        "title": "Eliminar paso de este transporte"
      },
      "dimensions": {
        "DEFAULT.label": "Dimensiones",
        "DIMENSIONS.label": "Dimensiones"
      },
      "documentIdentifier": {
        "CUSTOMERS_ORDER_NUMBER.label": "Número de pedido del cliente",
        "DEFAULT.label": "Número de nota de entrega",
        "DELIVERY_NOTE_NUMBER.label": "Número de nota de entrega",
        "ISSUE_ORDER_NUMBER.label": "Emitir número de pedido",
        "REFERENCE_NUMBER.label": "Número de referencia"
      },
      "errors": {
        "arrivalDate": {
          "isInPast": "La fecha de entrega no puede ser en el pasado."
        },
        "company": {
          "isRequired": "Este tipo de transporte requiere añadir a un contratista. Si no puede ver ninguna empresa, inicie una sociedad."
        },
        "companyGroup": {
          "blank": "Debe seleccionar al menos un grupo de contratistas.",
          "wrongValue": "El grupo de contratistas seleccionado no es uno de los grupos de la empresa proveedora seleccionada."
        },
        "contactPersonEmail": {
          "invalidEmailAddress": "Introduzca una dirección de correo electrónico válida"
        },
        "dimensions": {
          "length": "El texto introducido es demasiado largo. La longitud máxima es de 255 caracteres."
        },
        "maxAdviceDate": {
          "beforeWindowStop": "El intervalo indicado no se puede definir porque una franja horaria de avisos de envío agregada previamente estaría fuera del intervalo. Mueva primero la franja horaria.",
          "earlierThanMinAdviceDate": "El tiempo máximo posible para avisos de envío no se puede definir antes del tiempo mínimo.",
          "inThePast": "El final del intervalo de tiempo para avisos de envío debe ser en el futuro para que se pueda emitir el aviso de envío.",
          "timeHasPassed": "Disculpe, se ha superado el tiempo permitido para avisos de envío. Contacte al administrador."
        },
        "minAdviceDate": {
          "afterWindowStart": "El intervalo indicado no se puede definir porque una ventana de tiempo de avisos de envío agregada previamente estaría fuera del intervalo. Mueva primero la franja horaria."
        },
        "minQuantityValue": "La cantidad mínima permitida de paletas es {minQuantityValue}",
        "outsideOfInterval": "El intervalo permitido es de {windowInterval} minutos, porque ese es el intervalo de tiempo para mover franjas horarias en el depósito seleccionado.",
        "outsideWarehouseWorkHours": "La fecha seleccionada está fuera de las horas de atención del depósito: {workStart}-{workEnd}.",
        "purchaseOrderNumber": {
          "length": "El texto introducido es demasiado largo. La longitud máxima es de 255 caracteres."
        },
        "warehouse": {
          "sameWarehousesInTransfer": "No puede crear una transferencia dentro de un depósito"
        }
      },
      "label": "Paso",
      "load": {
        "place": "Lugar de carga",
        "term": "Fecha de retiro"
      },
      "maxAdviceDate": "Tiempo máximo posible para avisos de envío",
      "minAdviceDate": "Tiempo mínimo posible para avisos de envío",
      "orderInTransport": "Secuencia en un pedido",
      "purchaseOrderNumber": {
        "DEFAULT.label": "Número de SD",
        "SD_NUMBER.label": "Número de SD"
      },
      "quantityLoadAndUnloadSumCheckMessage": "¿Está seguro de que desea emitir un aviso de envío para más de un tráiler de artículos en cargas/descargas?",
      "quantityLoadSum": "Cantidad de paletas en la carga",
      "quantityLoadSumCheckMessage": "¿Está seguro de que desea emitir un aviso de envío para más de un tráiler de artículos en cargas?",
      "quantityUnloadSum": "Cantidad de paletas en la descarga",
      "quantityUnloadSumCheckMessage": "¿Está seguro de que desea emitir un aviso de envío para más de un tráiler de artículos en descargas?",
      "timeRangeForAdvice": "Intervalo de tiempo para un aviso de envío",
      "timeRangeForAdviceDoesNotApply": "Solo con fines informativos: no se aplica a usted",
      "timeRangeForLoad": "Intervalo de tiempo para carga",
      "timeRangeForUnload": "Intervalo de tiempo para descarga",
      "timeWindow": "Franja horaria",
      "unload": {
        "place": "Lugar de descarga",
        "term": "Fecha de entrega"
      },
      "weightLoadSum": "Peso total de pasos de carga",
      "weightUnloadSum": "Peso total de pasos de descarga"
    },
    "table": {
      "loadMore": "Cargar más datos",
      "loading": "Se están cargando los transportes",
      "numberOfTransports": "{n} transportes",
      "scanBarcode": "Escaneo de código de barras",
      "scanBarcodeError": "Se produjo un error al escanear el código de barras. Verifique el dispositivo de escaneo y vuelva a intentar o contacte a su administrador.",
      "transports": {
        "scrollToType": "Desplácese a la sección de transportes de este tipo.",
        "selectType": "Seleccione el tipo",
        "showAll": "Mostrar todos los transportes",
        "showAllActive": "Mostrar todos los pedidos activos",
        "showLimited": "Mostrar pedidos limitados",
        "showMine": "Mostrar solo mis transportes",
        "showWithinGroup": "Mostrar los transportes de mi grupo"
      }
    },
    "tabs": {
      "announcements": "Anuncios",
      "archive": "Archivo",
      "carriers": "Empresas transportistas",
      "companies": "Empresas",
      "invitations": "Invitaciones",
      "modeOfTransportation": "Modo de transporte",
      "planning": "Planificación",
      "priceLists": "Listas de precios",
      "report": "Estadísticas",
      "schedule": "Cronograma",
      "settings": "Configuración",
      "superAdmin": "ADMIN",
      "transports": "Transportes",
      "users": "Usuarios"
    },
    "task": {
      "alias": "Alias del paso",
      "altCheckpointColor": "Color alternativo del estado",
      "altCheckpointName": "Nombre alternativo del paso ES",
      "altCheckpointNameEn": "Nombre alternativo del paso EN",
      "associations": {
        "CONTRACTOR": "Contratista",
        "WAREHOUSE": "Depósito",
        "WAREHOUSE_AND_CONTRACTOR": "Depósito y contratista",
        "label": "Tarea asociada con"
      },
      "beforeConfirmation": {
        "willChangeColorTo": "y su fila de la tabla y la franja horaria serán",
        "willChangeStateTo": "Hasta la confirmación de este paso, el transporte tendrá el siguiente estado "
      },
      "blockade": {
        "customFieldsRestriction": "El estado solo puede confirmarse luego de completar y guardar los campos del formulario de transporte:",
        "documentRestriction": "El estado solo puede confirmarse tras agregar los documentos de transporte"
      },
      "canBeBlocked": "El estado se puede bloquear (no será posible cambiar el estado hasta que se desbloquee). También requiere la autoridad ROLE_CAN_BLOCK_TASK",
      "canBeConfirmAfterAddingDocumentsForTransport": "Se puede confirmar tras agregar los documentos de transporte",
      "canBeConfirmedByGuard": "Puede confirmar un guardián",
      "canBeConfirmedWithSms": "La etapa se puede confirmar con SMS",
      "canBeRejected": "El estado se puede rechazar",
      "canBeRevoked": "El estado se puede revocar",
      "canConfirmPreviousConfirm": "¿Está seguro de que desea confirmar la salida para este pedido? Los estados de descarga no se han aprobado.",
      "checkpointName": "Nombre del paso ES",
      "checkpointNameEn": "Nombre del paso EN",
      "checkpointSortId": "Reihenfolge der Etappe bei der Sortierung",
      "checkpointSortIdSettingLabel": "Orden de etapas al clasificar los estados en la tabla de transportes",
      "color": "Color del ESTADO",
      "confirmCheckpointAfterCarrierSelect": "Confirmación automática tras asignarse la empresa transportista",
      "confirmCheckpointAfterCourierDelivered": "Confirmación automática luego de que el servicio de mensajería confirmó el estado Entregado",
      "confirmCheckpointAfterCourierPickup": "Confirmación automática luego de que el servicio de mensajería confirmó el estado Retirado",
      "customFieldDefinition": "Tarea asociada con campo personalizado",
      "customFieldOption": "Opción de campo personalizado relacionado",
      "customModalComponentName": "Nombre de componente usado como modal emergente si la tarea tiene activada la opción openCustomModal (ver arriba)",
      "defaultStagePrefix": "Confirmada",
      "disableConfirm": "¿Está seguro de que desea desactivar este paso?",
      "editLabel": "Editar paso: {id} - {transportType}",
      "errors": {},
      "expectedDateMode": {
        "reference": "no después de"
      },
      "expectedDateReference": "Referencia",
      "group": {
        "0": "Preparación",
        "1": "Cargas y descargas",
        "2": "Final"
      },
      "isDriverCheckInTask": "indicador que permite la búsqueda de transporte para Infokiosk. El transporte que buscamos no puede tener este estado como confirmado.",
      "label": "Paso",
      "markIfWasDelayed": {
        "disabled": {
          "label": "Marcar una demora",
          "title": "Haga clic en el ícono para activar un borde rojo permanente en una franja horaria si este paso se confirma tarde."
        },
        "enabled": {
          "label": "Marcar una demora",
          "title": "Si un paso se confirma demasiado tarde, la franja horaria se marcará con un borde rojo. Haga clic en el ícono para cambiar la configuración."
        }
      },
      "moveTransportToArchiveIfDelayed": "Asignación automática de pedidos al archivo en caso de demora",
      "newSubTasksCheckpoints": "Se generarán nuevos puntos de control",
      "openCustomModal": "Para confirmar esta tarea, deberá confirmar el modal emergente.",
      "recreateMasterTaskSubTasks": "Tras la confirmación, recrear todas las subtareas de la tarea maestra de esta tarea (tarea maestra: {n}).",
      "reference": {
        "after": {
          "earliestStepStart": "inicio de la primera carga/descarga",
          "latestStepStop": "final de la primera carga/descarga",
          "minAdviceDate": "la fecha de inicio de un intervalo de fechas para mandar un aviso de envío",
          "stepStart": "inicio de",
          "stepStop": "final de",
          "transportCreated": "creación de transporte"
        },
        "before": {
          "earliestStepStart": "inicio de la primera carga/descarga",
          "latestStepStop": "final de la primera carga/descarga",
          "minAdviceDate": "la fecha de inicio de un intervalo de fechas para mandar un aviso de envío",
          "stepStart": "inicio de",
          "stepStop": "final de",
          "transportCreated": "creación de transporte"
        }
      },
      "repeatSendApiMessage": "Volver a enviar el mensaje en caso de error",
      "requiresWarehouseLocation": "Requiere una ubicación de depósito para confirmar el estado",
      "saveSubTasks": "Guardar todas las subtareas",
      "sendApiMessage": "Envío de mensajes de la API",
      "sendDocumentDelayedEmail": "Envío de notificación por correo electrónico sobre la demora para agregar documentos",
      "settingsLabel": "Configuración del paso",
      "shouldBeApprovedWithAdvice": "Confirmación automática del estado tras agregar un aviso de envío",
      "shouldBeApprovedWithCFTypeEmailAdvice": "Confirmación automática del estado tras agregar datos del transporte del aviso de envío por correo electrónico de tipo CF_DATA.",
      "shouldBeApprovedWithTimeWindow": "Confirmación automática de estado tras agregar una ventana de tiempo",
      "shouldBeConfirmedBy": "lo debe confirmar",
      "shouldBeConfirmedByRelatedCheckpoint": "[DELIVERY] Especifica el punto de control que se aprobará cuando se apruebe el punto de control correspondiente con el indicador “shouldConfirmRelatedCheckpoint”",
      "shouldBeSavedAsApproved": "Confirmación automática de estado al crear un pedido",
      "shouldConfirmRelatedCheckpoint": "[DELIVERY] Especifica un punto de control que, cuando se aprueba, también aprueba el punto de control correspondiente con el indicador “shouldBeConfirmedByRelatedCheckpoint”",
      "shouldFindFreeSpotAfterDelay": "Transferencia de la ventana de tiempo en caso de una demora",
      "shouldGenerateDocument": "Generación de nota de entrega automática",
      "shouldSendLpToSapMessages": "[DELIVERY] Define el punto de control tras el cual enviaremos un mensaje al microservicio MSP",
      "showPunctualityAlertAfterConfirmation": "Mostrar información sobre la puntualidad de la confirmación de estado",
      "sign": "Antes/Después",
      "stageName": "El nombre del estado antes de confirmar el paso ES",
      "stageNameEn": "El nombre del estado antes de confirmar el paso EN",
      "stayAtTheFacility": "Etapa asociada con permanecer en el sitio",
      "stepType": {
        "firstLoad": "primera carga",
        "firstUnload": "primera descarga",
        "lastLoad": "última carga",
        "lastUnload": "última descarga",
        "load": "carga",
        "placeholder": "Tipo de acción",
        "unload": "descarga"
      },
      "taskIsNotSavedHint": "Esta tarea no se ha guardado aún. Haga clic en Guardar para editar la tarea agregada",
      "timeWindowCssClassName": "Clase de css adicional para la ventana de tiempo",
      "tracked": {
        "disabled": {
          "label": "",
          "title": "El plazo previsto para la confirmación del paso solo se mostrará con fines ilustrativos: si un paso no se confirma a tiempo, no cambiará el color de la fila o de la franja y no se enviará ninguna notificación sobre la demora. Haga clic en el ícono para cambiar la configuración."
        },
        "enabled": {
          "label": "Monitoreo de puntualidad",
          "title": "si un paso no se confirma a tiempo, el estado del transporte cambiará a “tarde”: el color de la fila en la tabla y la franja horaria cambiará a rojo y se enviarán correos electrónicos para notificar la demora. Haga clic en el ícono para cambiar la configuración."
        }
      },
      "unit": "Unidad",
      "userHasToAddCheckpointConfirmationDate": "Se requiere la fecha de confirmación del estado",
      "visibleInDeliveryDocument": "Visible en la nota de entrega",
      "weighingIncluded": "Paso de pesaje"
    },
    "tasks": {
      "empty": "No hay ninguna tarea aquí."
    },
    "temporaryAccountAlert": {
      "daysLeft": "Días restantes para el bloqueo: {days}.",
      "limitReached": "Su empresa ya alcanzó el límite de usuarios de su plan de precios, por lo cual esta cuenta es temporal.",
      "pricingPlans": "lista de precios.",
      "title": "Se alcanzó el límite de usuarios",
      "upgrade": "Si desea elevar el límite de usuarios, debe adquirir otro plan de precios. Hallará los detalles en nuestra"
    },
    "time-window": {
      "details": {
        "carRegistrationNumber": "Camión",
        "erpIdentifier": "Número de pedido",
        "trailerRegistrationNumber": "Tráiler/Semitráiler"
      },
      "errors": {
        "start": {
          "isInPast": "No puede programar un transporte para el pasado.",
          "outsideOfAdviceRange": "No puede elegir esta fecha porque la franja horaria quedaría fuera del intervalo de tiempo de avisos de envío."
        },
        "stop": {
          "earlierThanStart": "El final de la franja horaria debe ser posterior a su comienzo.",
          "outsideOfAdviceRange": "No puede elegir esta fecha porque la franja horaria quedaría fuera del intervalo de tiempo de avisos de envío."
        }
      },
      "start": "Duración de la franja"
    },
    "timeWindow": {
      "cancelCreation": "Cancelar (ajustar la posición de una franja horaria)",
      "errors": {
        "start": {
          "notConsistentWithWarehouseOffset": "El comienzo de la franja horaria no es compatible con la configuración del depósito. Los comienzos posibles de la franja horaria para el horario seleccionado: {possibleWindowStarts}",
          "outsideWarehouseWorkHours": "La fecha seleccionada está fuera de las horas de atención del depósito: {workStart}-{workEnd}.",
          "startsEarlierThenPreviousWindowStops": "El inicio de la ventana de tiempo no puede ser anterior al final de la ventana de tiempo previa del transporte."
        },
        "stop": {
          "notConsistentWithWarehouseOffset": "El final de la franja horaria no es compatible con la configuración del depósito. El final posible de la franja horaria para el horario seleccionado: {possibleWindowStops}",
          "outsideWarehouseWorkHours": "La fecha seleccionada está fuera de las horas de atención del depósito: {workStart}-{workEnd}."
        },
        "time": {
          "outsideOfAdviceRange": "No puede agregar esta franja horaria aquí porque quedaría fuera del intervalo de tiempo de avisos de envío: {minAdviceDate} - {maxAdviceDate}."
        },
        "warehouseHasNoSpaceThisDay": "No hay franjas horarias disponibles el <date>. Seleccione otra fecha.",
        "wrongWarehouse": "No puede colocar una franja horaria en un depósito no asignado para un transporte"
      },
      "ramp": "Rampa",
      "start": "Inicio de una franja horaria",
      "stop": "Fin de una franja horaria"
    },
    "transaction": {
      "accept": "Aceptar",
      "autoPass": "Derivación aut.",
      "autoPassConfirmation": "¿Está seguro de que desea activar la derivación automática para las posteriores empresas transportistas para este transporte?",
      "carrier": "Empresa transportista",
      "carrierMustAcceptTransport": "La derivación requiere la aceptación'por parte de la empresa transportista",
      "carrierMustAcceptTransportHint": {
        "checked": "Tras la derivación del pedido, la empresa transportista seleccionada puede aceptarlo o rechazarlo. La empresa transportista debe confirmar el pedido para que le quede asignado.",
        "unchecked": "A la empresa transportista seleccionada se le asignará el pedido de inmediato."
      },
      "chooseCarrier": "Seleccionar una empresa transportista",
      "chooseCourier": "Seleccionar un servicio de mensajería",
      "courier": "Servicio de mensajería",
      "currency": "Moneda",
      "dateCreated": "Creado el",
      "errors": {
        "expectedConfirmationDate": {
          "isInPast": "La fecha introducida no puede ser en el pasado"
        },
        "invoicePrice": {
          "wrongNumberFormat": "El precio debe ser un número"
        }
      },
      "expectedConfirmationDate": "El plazo para la confirmación",
      "invoiceCurrency": "Moneda de factura",
      "invoiceNumber": "Número de factura",
      "invoicePrice": "Precio de factura",
      "isRejected": "Rechazado",
      "label": "Transacción",
      "notYetImplemented": "Esta opción aún no se ha implementado.",
      "offer": "Oferta",
      "pass": "Derivar",
      "price": "Precio",
      "rate": "Tarifa",
      "reject": "Rechazar",
      "transport": "Transporte",
      "updateDisabledInfo": {
        "route": "La tarifa es de la ruta {routeId}, así que no puede'editarla."
      },
      "waitingForAcceptanceInfo": "Aguardando aceptación",
      "withInvoice": "con factura",
      "withoutInvoice": "sin factura"
    },
    "transactionSetting": {
      "description": {
        "canChooseCarrierFromTransportTable": "Determina si el botón “Derivar” estará disponible en la tabla de transportes en la fila de transporte (con las opciones de AuthorityRestriction configuradas como corresponde).",
        "defaultCurrency": "Especifica la moneda predeterminada seleccionada en el formulario de transporte.",
        "defaultInvoiceCurrency": "Especifica la moneda predeterminada seleccionada en el formulario de factura.",
        "priceLabel": "Especifica el nombre del precio predeterminado.",
        "requireCarrierConfirmation": "Especifica si se requiere confirmación cuando el pedido se transfiere a la empresa transportista. En caso de que el indicador “La derivación requiere la aceptación'de la empresa transportista” sea TRUE, define el valor predeterminado del campo “La derivación requiere la aceptación'de la empresa transportista”.",
        "requiresReasonField": "Campo “Motivo” obligatorio.",
        "showAndRequireReasonFieldIfPriceIsZero": "Visibilidad y obligatoriedad del campo “Motivo” cuando se introduce el valor 0 en el campo “Flete”",
        "showCarrierConfirmationCheckbox": "Especifica si la casilla de verificación debe estar visible en el formulario de transferencia: Campo “La derivación requiere la aceptación'de la empresa transportista”",
        "showExpectedConfirmationDate": "Especifica si la casilla de verificación debe estar visible en el formulario de transferencia: “El plazo para la confirmación”",
        "showPriceField": "Especifica si la casilla de verificación debe estar visible en el formulario de transferencia: Campo “Precio”",
        "showPriceFieldForDeliveries": "Visibilidad del campo “Flete” para cada entrega",
        "showReasonField": "Visibilidad del campo “Motivo”"
      },
      "label": {
        "canChooseCarrierFromTransportTable": "Mostrar el botón “Derivar” en la tabla de transportes",
        "defaultCurrency": "Moneda predeterminada para las transacciones",
        "defaultInvoiceCurrency": "Moneda predeterminada para las facturas",
        "priceLabel": "Nombre predeterminado del precio",
        "requireCarrierConfirmation": "Requiere confirmación de la transacción de la empresa transportista",
        "requiresReasonField": "Campo “Motivo” obligatorio.",
        "showAndRequireReasonFieldIfPriceIsZero": "Visibilidad y obligatoriedad del campo “Motivo” cuando se introduce el valor 0 en el campo “Flete”",
        "showCarrierConfirmationCheckbox": "Visibilidad del campo “La derivación requiere la aceptación'de la empresa transportista”",
        "showExpectedConfirmationDate": "Visibilidad de “El plazo para la confirmación”",
        "showPriceField": "Visibilidad del campo “Precio”",
        "showPriceFieldForDeliveries": "Visibilidad del campo “Flete” para cada entrega",
        "showReasonField": "Visibilidad del campo “Motivo”"
      },
      "priceLabel": {
        "FREIGHT": "Flete",
        "FREIGHT_FOR_CARRIER": "Flete para la empresa transportista",
        "PRICE": "Precio",
        "RATE": "Tarifa"
      },
      "saveSuccess": "La configuración de transacciones se guardó correctamente"
    },
    "transport": {
      "ARTR": "Estado de comunicación con ANPR",
      "CARTONS": "Cajas",
      "CHEP_PALLETS": "Paletas CHEP",
      "DISPOSABLE_PALLETS": "Paletas descartables",
      "Delivery": "Entrega",
      "EURO_PALLETS": "Paletas EURO",
      "LOADING_PLACES": "Lugares de carga",
      "PALLETS": "Paletas",
      "PALLET_PLACES": "Lugares de paletas",
      "THE_NUMBER_OF_ITEMS": "La cantidad de artículos",
      "accountExpired": "Suspensión de la cuenta debido a inactividad",
      "actionType": "Tipo de acción",
      "addNewCompany": {
        "assignmentCompany": "¿Desea asignar esta empresa como contratista de transporte?",
        "cooperatingCompanies": "Usted ya coopera con una empresa de nombre similar.",
        "emailInfo": "Una invitación para cooperar se enviará a la dirección de correo electrónico suministrada.",
        "emailRequired": "Si desea enviar una invitación para cooperar, debe suministrar una dirección de correo electrónico",
        "existingCompany": "En nuestra base de datos, ya tenemos una empresa con el número de identificación de contribuyente (NIP) seleccionado.",
        "existingCompanyAndPartnership": "La empresa con el número de identificación de contribuyente (NIP) {taxIdNumber} ya está registrada en el sistema y pertenece a sus colegas",
        "label": "Agregue una empresa que aún no se haya introducido en el sistema.",
        "sendInvitation": "Enviar una invitación para cooperar",
        "wrongEmail": "La dirección de correo electrónico introducida no es correcta"
      },
      "additionalDescription": "Notas adicionales",
      "advice": "Aviso de envío",
      "altIdentifier": "Número de pedido alternativo",
      "amountOfCover": "Monto de cobertura",
      "archived": "Archivado",
      "arrivalDate": "Fecha de retiro",
      "assetId": "ID de activo",
      "authorityRange": "Intervalo de autoridad",
      "canNotEditAfterProcessStarts": "Solo puede editar datos de un aviso de envío creado por usted y antes de la llegada de un conductor.",
      "canNotEditBecauseOfOtherContractors": "No puede editar datos de un aviso de envío de varios contratistas.",
      "carrier": {
        "label": "Empresa transportista"
      },
      "carrierCompany": "Empresa transportista",
      "carrierCompanyChooser": "Persona que seleccionó a la empresa transportista",
      "carrierCompanySelectedBy": "Empresa transportista seleccionada por {companyName}",
      "combinedWeight": "Peso total de los transportes: ",
      "company": "Proveedor",
      "company.autoCompletePlaceholder": "Comience a escribir para buscar una empresa",
      "companyNotQualified": "Su empresa no cumple con las condiciones",
      "completed": "Finalizado",
      "courier": {
        "dateInPast": "La fecha de retiro indicada es en el pasado. Para reenviar el cargamento al servicio de mensajería, actualice la fecha de retiro en los detalles del pedido.",
        "dhlMissingDocument": "Algún país seleccionado está fuera de la UE y requiere PLT. Para derivar el transporte a DHL Express, indique la fecha y el número de la factura, y adjunte la factura en formato PDF en la sección del transporte correspondiente a la factura de los artículos.",
        "dhlMissingExportDeclaration": "Algún país seleccionado está fuera de la UE. Para derivar el transporte a DHL Express, indique la fecha y el número de la factura en la sección del transporte correspondiente a la factura de los artículos.",
        "label": "Servicio de mensajería",
        "loadCity.label": "Lugar de carga:",
        "missingContactDetails": "El transporte no puede derivarse a un servicio de mensajería si, en algún paso del transporte, falta el nombre, el número de teléfono o la dirección de correo electrónico de la persona de contacto.",
        "pickUpDate.label": "Fecha de retiro",
        "pickUpHour.label": "Hora de retiro",
        "service.label": "Servicio",
        "unloadCity.label": "Lugar de descarga:"
      },
      "courierInvoiceSection": {
        "button": "Agregar factura",
        "date": "Fecha de factura",
        "file": "Factura adjunta",
        "label": "Factura de artículos",
        "number": "Número de factura"
      },
      "courierSection": {
        "deliveryDate": "Fecha de entrega",
        "goodsValue": "Valor de artículos",
        "goodsValueCurrency": "Moneda",
        "incoterms": "Incoterms",
        "label": "Servicios de mensajería",
        "orderBy": "Pedido hecho por",
        "serviceType": "Tipo de servicio",
        "totalPrice": "Precio total"
      },
      "createSimilarMultiple": "Crear transportes similares",
      "createSimilarMultipleInProgress": "Copia de transporte en curso; la ventana se cerrará cuando se hayan creado todos los transportes",
      "createSimilarMultiplePlaceholder": "Cantidad de copias",
      "createSimilarMultipleValue": "Cantidad de transportes que se van a crear",
      "createTimeWindow": "Crear ventana de tiempo",
      "createTitle": "Programar entrega",
      "createdAsCopy": "como copia de pedido",
      "createdBy": "Creado por",
      "createdMessage": "Creado el {date} por {person} ({company}).",
      "creator": "Creador",
      "dateCreated": "Fecha de creación",
      "dateOfAdvice": "Fecha de aviso de envío",
      "dateOfArchiving": "Fecha de archivado",
      "dateOfDeletion": "Fecha de eliminación",
      "delete": "Eliminar transporte",
      "deleteTransport": "¿Está seguro de que desea eliminar este transporte?",
      "departureDate": "Fecha de entrega pedida",
      "description": "Notas",
      "details": "Detalles de la entrega",
      "documentType": "Tipo de documento",
      "documents": {
        "dragAndDrop": "Arrastre y suelte los documentos o haga clic",
        "empty": "Aún no se han agregado documentos en esta entrega.",
        "header": "Documentos para la entrega {identifier}",
        "label": "Documentos"
      },
      "earliestTimeWindowStart": "Inicio de la franja horaria más temprana",
      "edit": "Editar transporte",
      "email": "Dirección de correo electrónico",
      "erpIdentifier": "Número de pedido",
      "erpIdentifier.placeholder": "por ej., 110011",
      "errors": {
        "altIdentifier": {
          "regex": "Siempre y cuando el valor no coincida con expresiones regulares de la configuración"
        },
        "carrierCompany": {
          "info": "Este tipo de transporte requiere introducir la empresa transportista. Si no ve'ninguna empresa, inicie una sociedad."
        },
        "contractors": {
          "notUnique": "Indique una lista exclusiva de contratistas"
        },
        "dateOfAdvice": {
          "isInPast": "No puede programar entregas para el pasado"
        },
        "erpIdentifier": {
          "minLength": "Los identificadores de ERP deben tener al menos 3 caracteres"
        },
        "firstContractor": {
          "info": "Este tipo de transporte requiere introducir la empresa contratista. Si no ve'ninguna empresa, inicie una sociedad."
        },
        "requiresWorker": {
          "info": "Elija trabajadores."
        }
      },
      "forAcceptOrReject": " para aceptar/rechazar envíos pedidos.",
      "forAuction": " para presentar ofertas en subastas.",
      "forProcess": " para agregar un aviso de envío en este proceso.",
      "generateLOPReport": "Descargar informe con la lista de puntos",
      "getCourierQuotes": "Obtener cotizaciones de servicios de mensajería",
      "goodsValue": "Valor de artículos",
      "goodsValueCurrency": "Moneda",
      "group": "Grupo de entrega",
      "history": "Historial de pedidos",
      "incompleteLoading": "Carga incompleta",
      "incoterms": "Incoterms",
      "indexes": {
        "overwritePreviousIndexes": "Sobrescribir índices anteriores",
        "pasteFromExcel": "Pegar desde Excel"
      },
      "indicesTransportNumbers": {
        "label": "Tipo de pedido"
      },
      "isCombinedTransport": "Transporte compartido con: ",
      "isRequiredFor": "Se necesita para: ",
      "isWaitingFor": "Está esperando a: ",
      "label": "Transporte",
      "lastLoginDate": "Inicio de sesión en el sistema",
      "latestTimeWindowStop": "Final de la última franja horaria",
      "load": "Carga",
      "logs": {
        "apiIntegration": "Integración",
        "artr": "Registros de ANPR",
        "carrierCompany": "Empresa transportista",
        "dateOfArchiving": "Se ha archivado el transporte",
        "dateOfDeletion": "Se ha eliminado el transporte",
        "initialStartInfo": "La franja horaria se alineó de la hora {initialDate} a la hora {startDate}",
        "label": "Historial de cambios",
        "name": "Archivo",
        "ramp": "Rampa",
        "start": "Inicio de franja horaria",
        "stop": "Fin de franja horaria"
      },
      "mobilePhoneNumber": "Número de teléfono",
      "mobilePhonePrefix": "Número de teléfono: prefijo",
      "multiArchive": {
        "alert": "No tiene permisos para archivar los transportes con los siguientes números: ",
        "confirm": "¿Está seguro de que desea archivar los siguientes transportes? ",
        "error": "Error inesperado al intentar archivar lote de transportes. ",
        "label": "Archivado de grupos"
      },
      "noFilesForOldBrowsers": "No puede agregar archivos en esta vista de navegador. Agregue un archivo desde el nivel de la tabla.",
      "option": "Opción seleccionada",
      "otherRequirements": "Otros requisitos",
      "packs": {
        "boxes": "Cajas",
        "label": "Paquete",
        "pallets": "Paletas",
        "pallets.abbr": "p."
      },
      "periodicTransport": "Aviso de envío periódico",
      "periodicTransportInternalIndex": "Número de aviso de envío periódico",
      "periodicTransportMessage": "Pedido conectado con número de aviso de envío periódico {periodicTransportInternalIndex}.",
      "print": "Vista de impresión",
      "quantities": {
        "CARTONS": "Cantidad de cajas",
        "CHEP_PALLETS": "Cantidad de paletas CHEP",
        "CUBIC_METERS": "Cantidad de metros cúbicos",
        "DISPOSABLE_PALLETS": "Cantidad de paletas descartables",
        "EURO_PALLETS": "Cantidad de paletas EURO",
        "LOADING_PLACES": "Cantidad de áreas de carga y descarga",
        "PALLETS": "Cantidad de paletas",
        "PALLETS_WITH_RETURNED_GOODS": "Cantidad de paletas con artículos devueltos",
        "PALLET_PLACES": "Cantidad de lugares de paletas",
        "RETURNABLE_PALLETS": "Cantidad de paletas retornables vacías",
        "SPATIAL_METERS": "Cantidad de metros espaciales",
        "THE_NUMBER_OF_ITEMS": "La cantidad de artículos",
        "shortName": {
          "CARTONS": "Cajas",
          "CHEP_PALLETS": "CHEP",
          "DISPOSABLE_PALLETS": "Descartables",
          "EURO_PALLETS": "EURO",
          "LOADING_PLACES": "Carga",
          "PALLETS": "Paletas",
          "PALLETS_WITH_RETURNED_GOODS": "Devoluciones",
          "PALLET_PLACES": "Lugares",
          "RETURNABLE_PALLETS": "Retornables",
          "THE_NUMBER_OF_ITEMS": "Artículos"
        }
      },
      "quantity": "Cantidad de paletas",
      "quantity.placeholder": "por ej., 33",
      "readyToPlan": "Listo para planificar",
      "recipient": "Destinatario",
      "relations": {
        "bind": "Crear relación",
        "combineClearingAlert": "¿Está seguro de que desea combinar los transportes seleccionados? La confirmación eliminará las ventanas de tiempo y los datos de avisos de envío de los transportes seleccionados.",
        "combinedTransports": "Transportes combinados: ",
        "combining": "“Combinación de transportes” (TRANSPORT_COMBINING)",
        "defaultLabel": "Puede combinar los transportes seleccionados con el tipo de relación: ",
        "deletedSuccessfully": "La relación con transporte se eliminó correctamente",
        "hasToWaitFor": "“El transporte está esperando a” (HAS_TO_WAIT_FOR)",
        "mainTransport": "Transporte principal: ",
        "maxWeight": "El peso máximo es: ",
        "noTypeForSelection": "Disculpe, los transportes seleccionados no permiten relaciones",
        "overweight": "No es posible combinar transportes porque se supera el peso máximo",
        "relatedTransports": "Transportes relacionados: ",
        "removeRelation": "Eliminar relación",
        "title": "Relaciones con transporte"
      },
      "restoredSuccessfully": "El pedido se restauró correctamente",
      "roleGroup": "User's role",
      "routeOffer": "Oferta de ruta",
      "scheduledDateOfAdvice": "Fecha programa de aviso de envío",
      "selectCountry": "País",
      "selectSupplier": "Seleccionar proveedor\\\\destinatario",
      "selectTransportType": "Seleccione el tipo de suministro",
      "selectUnloadStartDate": "Fecha mín. de descarga",
      "selectUnloadStartDateTitle": "Seleccione la fecha mínima de descarga.",
      "selectUnloadStopDate": "Fecha máx. de descarga",
      "selectUnloadStopDateTitle": "Seleccione la fecha máxima de descarga.",
      "selectWarehouse": "Seleccione el depósito",
      "selectedCombinedTransportsWindows": "Las franjas horarias asignadas a transportes ",
      "selectedWindows": "Las franjas horarias asignadas al transporte {transportIdentifier}",
      "serviceCompany": "Empresa del servicio",
      "stepType": "Tipo de paso",
      "sureToSaveMessage": "¿Está seguro de que desea guardar los cambios? Otro usuario modificó algunos campos.",
      "task": "Tarea",
      "title": "Transporte {ident}",
      "tooManyCopies": "No puede crear más de {maxValue} copias del transporte a la vez",
      "transportRelation": "Relación",
      "transportSupervisor": {
        "autoCompletePlaceholder": "Comience a escribir para buscar una persona de contacto",
        "label": "Persona de contacto"
      },
      "transportType": "Tipo de transporte",
      "truck": "Modo de transporte",
      "type": {
        "ALL": "Todos",
        "COLLECTION": "Retiro",
        "DELIVERY": "Entrega",
        "SERVICE": "Mensajería, servicio, etc.",
        "SIMPLIFIED_DELIVERY": "Entrega simplificada",
        "TRANSFER_BETWEEN_WAREHOUSES": "Transferencia entre depósitos",
        "TRANSPORT": "Transporte"
      },
      "typeOfService": "Courier's service",
      "unload": "Descarga",
      "value": "Valor",
      "volume": "Volumen",
      "warehouse": "Depósito",
      "wasMovedAutomatically": "La ventana de tiempo se movió automáticamente",
      "weight": "Peso",
      "weight.placeholder": "por ej., 23",
      "weightSection": {
        "downloadWeightReceipt": "Descargar recibo de peso",
        "entryWeight": "Pesaje 1",
        "errors": {
          "cannotGetWeight": "Disculpe, no es posible descargar el peso. Vuelva a intentar más tarde. Si el problema persiste, contacte al administrador del sistema.",
          "trySaveCheckpointWithoutWeight": "Este punto de control requiere pesaje. Disculpe, no es posible descargar el peso. ¿Desea confirmar el punto de control con un peso de 0?"
        },
        "exitWeight": "Pesaje 2",
        "label": "Peso",
        "netWeight": "Peso neto",
        "notSynchronized": "Peso no sincronizado con el servidor",
        "overweightMessage": "El vehículo, una vez cargado, superará el peso máximo. Este vehículo no debe cargarse",
        "overweightNote": "El vehículo ha superado el límite de peso configurado.",
        "unit": "kg",
        "weighAgain": "Pesar otra vez",
        "weight": "peso"
      },
      "wzDocument": "Número de nota de entrega",
      "wzNumber": "Número de nota de entrega"
    },
    "transport-type": {
      "errors": {
        "defaultLoadStepEndHour": {
          "regex": "El valor suministrado debe ser un horario en formato HH:SS"
        },
        "defaultLoadStepStartHour": {
          "regex": "El valor suministrado debe ser un horario en formato HH:SS"
        }
      }
    },
    "transportPanel": {
      "ADVICE": "Aviso de envío",
      "DISCREPANCY_REPORT": "Registro de discrepancias",
      "DOCUMENTS": "Documentos",
      "INSPECTION": "Inspección",
      "LOGS": "Historial de cambios",
      "WEIGHT": "Peso",
      "selectPlaceholder": "Seleccionar panel"
    },
    "transportSupervisorTranslationKeys": {
      "CONTACT_PERSON": "Persona de contacto",
      "HOST_PERSON": "Anfitrión",
      "INVITEE": "Invitado",
      "ORDERER": "Solicitante",
      "PERSON_TO_NOTIFY": "Persona que se notificará"
    },
    "transportsToExcel": {
      "XLSGenerateMessage": "Tras generar el informe de los pedidos, este se enviará a la dirección de correo electrónico de la persona que lo generó.",
      "activeArchiveType": "Pedidos activos/archivados",
      "advancedExportType": "Avanzada",
      "advancedExportTypeDescription": "La versión avanzada tiene dos hojas. La primera hoja contiene los mismos datos que la versión básica, excepto por la información sobre el estado del transporte. La segunda hoja contiene toda la información relacionada con las confirmaciones de estado.",
      "adviceReportLabel": "Esta versión contiene información sobre los avisos de envío. El informe está disponible para los siguientes tipos: ",
      "adviceReportLabelEmpty": "No hay ningún tipo de transporte disponible",
      "adviceReportType": "Informe de avisos de envío",
      "alsoExportActiveOrders": "Exportar pedidos activos también",
      "basicExportType": "Básica",
      "basicExportTypeDescription": "La versión básica incluye todos los datos en una hoja.",
      "billingExportType": "Informe de facturación",
      "billingExportTypeDescription": "Esta versión contiene los datos de facturación.",
      "errors": {
        "DaysLimitExceededMessage": "Error. No se pudo generar el informe. Se superó el intervalo de fechas permitido para la generación de informes.",
        "XLSGenerateEmailErrorMessage": "El informe de pedidos se envía a la dirección de correo electrónico de la persona que genera el informe; introduzca la dirección de correo electrónico en la pestaña Mi cuenta.",
        "XLSGenerateErrorMessage": "Error. No se pudo generar el informe. Vuelva a intentar más tarde o escriba a info@logintegra.com."
      },
      "export": "Exportar",
      "exportType": "Versión de archivo",
      "noTypeChosen": "Elija al menos un tipo de transporte",
      "ownColumnExportType": "Columnas de la tabla",
      "ownColumnExportTypeDescription": "La versión de columnas de la tabla solo contiene la información visible en la tabla de transportes",
      "title": "Parámetros de archivo de Excel",
      "transportTypes": "Tipos de pedidos"
    },
    "truck": {
      "addTruck": "Agregar camión",
      "description": "Descripción",
      "disableConfirm": "¿Está seguro de que desea eliminar este camión?",
      "errors": {
        "registrationNumber": {
          "regex": "El número de registro debe tener entre 4 y 50 caracteres, y solo puede contener letras, números y los caracteres . , ; / \\\\ & _"
        },
        "trailer": {
          "regex": "El número de registro debe tener entre 4 y 50 caracteres, y solo puede contener letras, números y los caracteres . , ; / \\\\ & _"
        }
      },
      "hds": "Camión pluma",
      "label": "Camiones",
      "lastUpdated": "Última modificación",
      "registrationNumber": "Número de registro",
      "timeWindow": "Franja horaria",
      "trailer": "Tráiler/Semitráiler",
      "truckType": "Tipo"
    },
    "trucks": {
      "label": "Camiones"
    },
    "términos de servicio": {
      "errors": {
        "unchecked": "A fin de enviar una solicitud de verificación, debe confirmar los TÉRMINOS DE SERVICIO"
      },
      "label": {
        "acquiant": "He leído los ",
        "confirmed": "se aceptaron el {confirmationDate} por parte del usuario {user}",
        "documents": "documentos de Términos de servicio:"
      },
      "name": "TÉRMINOS DE SERVICIO",
      "shortName": "TDS"
    },
    "unit": {
      "KG": "kg",
      "LITERS": "litros",
      "PIECES": "piezas",
      "label": "Unidad",
      "placeholder": "Seleccionar una unidad"
    },
    "user": {
      "accountCreatedBy": "Cuenta creada por",
      "accountUpdated": "Se han guardado los cambios en la configuración de la cuenta.",
      "activeNotifications": "Notificaciones activas",
      "availableQueues": "Colas disponibles",
      "availableRamps": "Rampas disponibles",
      "availableWarehouses": "Depósitos disponibles",
      "contractorGroupName": "Grupo",
      "createUser": "Crear usuario",
      "deleteConfirm": "¿Está seguro de que desea eliminar a este usuario?",
      "deletedMessage": "Eliminado el {date} por {person} de la empresa {company}.",
      "deletedUser": "Se ha eliminado al usuario",
      "disableConfirm": "¿Está seguro de que desea desactivar a este usuario?",
      "disabledUser": "Se ha desactivado al usuario",
      "editUser": "Editar usuario",
      "email": "Correo electrónico",
      "errors": {
        "mobilePhoneNumberLengthError": "El número de teléfono debe constar de 9 dígitos.",
        "mobilePhoneNumberRegexError": "El número de teléfono solo puede incluir números."
      },
      "groupName": "División",
      "hasRoleAccessAllRamp": "Los administradores tienen el permiso para acceder a todas las rampas/franjas horarias del cronograma. Más allá de la selección de rampas disponibles en esta vista, el usuario tendrá acceso a todas las rampas.",
      "invitedBy": "Invitado: {date} por {person}.",
      "lastLoginDate": "Fecha de último inicio de sesión",
      "ldapUser": "Usuario para integración de LDAP",
      "limitedDataHint": "La visualización y la edición de algunos detalles del usuario {user} están limitadas. Solo pueden gestionarlos los administradores de la empresa {company}.",
      "list": "Lista de usuarios",
      "localAdmin": "Administrador local",
      "mobilePhoneNumber": "Teléfono",
      "myAccount": "Mi cuenta",
      "name": "Nombre y apellido",
      "noActiveNotifications": "No hay notificaciones activas",
      "noAvailableRamps": "No hay rampas disponibles",
      "registrationDate": "Fecha de registro",
      "roleDescription": "Descripción del rol",
      "selectRole": "Seleccionar rol",
      "status": {
        "ACTIVE": "Activo",
        "DELETED": "Eliminado",
        "EXPIRED": "Inactivo",
        "TEMPORARY": "Temporal",
        "label": "Estado del usuario"
      },
      "temporaryAccountDaysLeft": "Días hasta el bloqueo",
      "userGroup": {
        "allTransports": "Todos los transportes",
        "myGroupsTransports": "Transportes'de mi grupo",
        "myTransports": "Mis transportes"
      },
      "username": "Nombre de usuario"
    },
    "userGroups": {
      "canSeeOtherGroupsTransports": "Acceso a transportes' de otros grupos",
      "identifier": "Identificador",
      "label": "Grupos de usuarios",
      "name": "Nombre"
    },
    "users": {
      "activity": "Actividad",
      "data": "Datos'del usuario",
      "label": "Usuarios",
      "notifications": "Notificaciones",
      "queues": "Acceso a colas",
      "ramps": "Acceso a rampas/franjas horarias"
    },
    "warehouse": {
      "addRamp": "Agregar rampa",
      "adviceHasBeenSavedInfo": "Se ha guardado un aviso de envío para esta hora: ",
      "autoCompletePlaceholder": "Comience a escribir para buscar el depósito, la calle, etc.",
      "backToDeliveries": "Tabla de entregas",
      "configureAlgorithmAfterSave": "El algoritmo para calcular la duración de la franja horaria puede configurarse después de guardar el depósito.",
      "configureAssortmentGroupsAfterSave": "Los grupos de surtidos pueden configurarse después de guardar el depósito.",
      "confirmDeleteDedicateWindow": "¿Está seguro de que desea eliminar la franja horaria exclusiva para la empresa {company}?",
      "create": "Crear depósito",
      "createRampsAfterSave": "Las zonas de almacenamiento, las ubicaciones y las rampas pueden configurarse después de guardar el depósito.",
      "createTransportInfo": "Seleccione un lugar libre del cronograma para crear un tipo de transporte: {transportTypeName}.",
      "disableConfirm": "¿Está seguro de que desea desactivar este depósito?",
      "disabledMessage": "El depósito “{warehouseName}” se eliminó correctamente.",
      "enableConfirm": "¿Está seguro de que desea activar este depósito?",
      "errors": {
        "idx": {
          "invalidIdxNumber": "El número de ID del depósito debe ser entre 0 y {numberOfWarehouses}"
        },
        "snapToClosestWindowInterval": {
          "info": "Introduzca la distancia máxima entre los bordes para alinear automáticamente la franja horaria. Los valores posibles son 0, 15, 30 y 60."
        },
        "timeWindowOffsetInterval": {
          "info": "Introduzca la cantidad de minutos que se pueden mover las franjas."
        },
        "windowConstantTime": {
          "info": "Introduzca un valor constante en minutos para agregar a la duración de la descarga en las franjas horarias."
        },
        "workEndHour": {
          "endBeforeStart": "La hora de cierre del depósito debe ser posterior a la hora de apertura.",
          "invalidNumber": "Seleccione un número del 1 al 24."
        },
        "workStartHour": {
          "invalidNumber": "Seleccione un número del 0 al 23."
        }
      },
      "hdOff": "Desactivar HD",
      "hint": "Para seleccionar el horario de la entrega, haga clic en el espacio vacío del cronograma.",
      "idx": "El número de ID del depósito.",
      "indexesDetailsTitle": "Haga clic para ver los índices",
      "integrationCode": "Código del depósito para la integración de MSP",
      "label": "Depósito",
      "numberOfRamps": "Cantidad de rampas",
      "reserveFreePlace": "Reserve un lugar libre del cronograma para {initialStart}",
      "selectCompany": "Tras hacer clic en un espacio vacío, cree una franja horaria exclusiva para la empresa",
      "selectCountry": "Seleccionar país",
      "selectDepartment": "o seleccionar el departamento",
      "selectNewPlaceForWindow": "Seleccionar nuevo lugar para la franja horaria seleccionada",
      "selectWindowInfo": "Seleccione un lugar libre del cronograma para agregar un aviso de envío al transporte {identifier}.",
      "selectWindowWarning": "Puede programar un aviso de envío para el {date} a más tardar.",
      "selectedWindowsCombinedTransports": "Seleccione un lugar libre del cronograma para agregar un aviso de envío para transportes combinados",
      "shouldFindFreeSpotAfterDelay": "Transferencia de la ventana de tiempo en caso de una demora en la etapa indicada",
      "slider": {
        "title": "Cambiar tamaño de filas en el cronograma"
      },
      "snapToClosestWindowInterval": "Distancia máxima entre los bordes para la alineación automática de la franja horaria. Solo alineamos una franja horaria -> la que colocamos como nueva.",
      "sumOfPallets": "Total de paletas",
      "templates": {
        "copyFrom": "Copiar plantillas del día",
        "datesWithErrorMessage": "Nota: Debido al cambio de hora, no pudimos guardar algunas franjas horarias de estos días: {windows}.",
        "header": "Plantillas de franjas horarias para depósito",
        "label": "Plantillas",
        "saveFor": "guardar plantilla para",
        "savedMessage": "La plantilla se ha guardado correctamente."
      },
      "timeWindowDefaultLengthInMinutes": "El tamaño predeterminado de las franjas horarias en minutos",
      "timeWindowLengthLongerThanWarehouseWorkingHours": "La franja horaria puede ser más amplia que el horario de atención del depósito",
      "timeWindowMustOccupyEntireRamp": "La franja horaria debe ocupar toda la rampa el día seleccionado",
      "timeWindowMustOccupyEntireRampDisclaimer": "La franja horaria de este depósito ocupará toda la rampa",
      "timeWindowOffsetInterval": "Cantidad de minutos para la que se puede definir una franja horaria",
      "timeWindowSummaryInfo": "Select a free spot in the schedule to add",
      "timeWindowSummaryInfoSubtitle": "El transporte requiere agregar {timeWindowsNumber} ventanas de tiempo",
      "transportTypes": "Tipos de transporte para los que habrá disponible un depósito",
      "updateSuccessMessage": "Se ha actualizado la configuración del depósito {warehouseName}.",
      "warehouseLocationAdd": "Agregar ubicación de depósito",
      "warehouseLocationLabel": "Ubicación de depósito",
      "warehouseLocationLabelPlural": "Ubicaciones de depósitos",
      "warehouseLocationNewLocation": "Nueva ubicación de depósito",
      "warehouseLocationNextLocation": "¿Desea trasladarse a otra ubicación de depósito?",
      "weighingIncluded": "El pesaje se realiza en este depósito",
      "windowConstantTime": "Valor constante en minutos agregado a la duración de la descarga en las franjas horarias.",
      "windowIsLoad": "C",
      "windowIsUnload": "D",
      "windowNoteLabel": "Contenido de la nota",
      "windowNoteRemoveWarning": "¿Desea eliminar la franja horaria con la nota: {note}?",
      "windowNoteTitle": "Introduzca su nota",
      "windowRemoveWarning": "¿Está seguro de que desea retirar la ventana de bloqueo?",
      "workEndHour": "Hora de cierre",
      "workStartHour": "Hora de apertura",
      "workTime": "Horario de atención del depósito",
      "youAreEditingThisWindow": "está editando esta franja horaria",
      "youAreInWarehouse": "Se encuentra en un depósito",
      "youWereMovedToWarehouse": "Lo trasladaron a un depósito"
    },
    "warehouse-zone": {
      "errors": {}
    },
    "warehouseZone": {
      "addZone": "Agregar zona de depósito",
      "algorithmSettings": "El algoritmo para calcular una franja horaria",
      "deliveryDefaultZone": "[DELIVERY] Zona predeterminada a la que se dirigen los medios de transporte que no coinciden con ningún patrón",
      "deliveryModeOfTransport": "[DELIVERY] Medios de transporte dirigidos a esta zona de almacenamiento separados con punto y coma; por ej.: Plandeka Wysoka;Plandeka standard;Plandeka standard EXW",
      "disableConfirm": "¿Está seguro de que desea desactivar esta zona?",
      "englishName": "Nombre de la zona en inglés",
      "errors": {},
      "hint": "{warehouseZoneLabel}: puede cambiarla moviendo la franja horaria en el cronograma.",
      "idx": "La secuencia de la zona",
      "label": "Zona de depósito",
      "noTransportTypesAssigned": "No hay zonas de depósito asignadas. Los tipos de transporte se pueden asignar a la zona de depósito en los detalles de las zonas de depósito.",
      "noTransportTypesSelected": "¿Está seguro de que desea dejar la zona de depósito sin ningún tipo de transporte asignado? No se podrá agregar avisos de envío en esta zona.",
      "selectWarehouse": "{warehouseZoneLabel}: seleccione un {warehouseLabel} primero",
      "settings": "Configuración de la zona",
      "transportTypes": "Tipos de transporte para los que debe estar disponible la zona de depósito",
      "updateSuccessMessage": "La configuración de la zona se cambió correctamente"
    },
    "warehouses": {
      "label": "Depósitos"
    },
    "warehousesToExcel": {
      "label": "Depósitos",
      "placeholder": "Elegir depósito para exportar",
      "title": "Parámetros de archivo de Excel"
    },
    "weightLog": {
      "ENTRY_WEIGHT": "Pesaje en la entrada",
      "ENTRY_WEIGHT_AGAIN": "Pesaje en la entrada (segundo pesaje)",
      "EXIT_WEIGHT": "Pesaje en la salida",
      "EXIT_WEIGHT_AGAIN": "Pesaje en la salida (segundo pesaje)"
    },
    "worker": {
      "GUESTS": "Invitados",
      "SERVICEMEN": "Personas de mantenimiento",
      "WORKERS": "Trabajadores",
      "addGuest": "Agregar invitado nuevo",
      "addServiceman": "Agregar nuevo encargado de mantenimiento",
      "addWorker": "Agregar trabajador nuevo",
      "autoCompletePlaceholder": "Comience a escribir para buscar a un empleado",
      "chooseWorkers": "Elegir trabajadores",
      "deleteWorkerMsg": "¿Está seguro de que desea eliminar este trabajador?",
      "editGuest": "Editar invitado",
      "editServiceman": "Editar encargado de mantenimiento",
      "editWorker": "Editar trabajador",
      "errors": {
        "phoneNumber": {
          "regex": "El número debe contener entre 9 y 12 dígitos"
        }
      },
      "name": "Nombre y apellido",
      "phoneNumber": {
        "GUESTS": "Número de teléfono del invitado",
        "SERVICEMEN": "Número de teléfono del personal de mantenimiento",
        "WORKERS": "Número de teléfono del trabajador"
      },
      "trained": "Capacitado",
      "workersList": "Lista de trabajadores"
    },
    "workflows": {
      "addSubTask": "Agregar una subtarea",
      "addTask": "Agregar una tarea",
      "finalStageNamesLabel": "Tras confirmar todas las etapas, el estado del transporte debe cambiar a",
      "label": "Flujo de trabajo (configuración del paso y estado)",
      "noTasks": "No se ha asignado ninguna tarea a esta parte del proceso",
      "saveSuccessMessage": "La configuración del paso y del estado (flujo de trabajo) se guardó correctamente.",
      "updateDisclaimer": "Nota: Los cambios relacionados con el tiempo solo se aplicarán a los transportes NUEVOS."
    },
    "wrongUrl": "No hay acceso o la página que solicitó no existe"
  };
  _exports.default = _default;
});