define("apollo/pods/transport-packaging/model", ["exports", "ember-data", "apollo/pods/packaging/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo
  } = _emberData.default;

  var _default = _model.default.extend({
    stackingFactor: attr('number'),
    transport: belongsTo('transport'),
    validations: {
      stackingFactor: {
        custom: [{
          validation: function (key, value) {
            return !!value;
          },
          message: 'blankSelect'
        }]
      },
      packageType: {
        custom: [{
          validation: function (key, value) {
            return value.get('id');
          },
          message: 'blankSelect'
        }]
      }
    }
  });

  _exports.default = _default;
});